import { FC, CSSProperties } from "react";
import { Skeleton, Flex, Space } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    margin: "0 auto",
    paddingTop: 56,
  },
  content: {
    maxWidth: 480,
    textAlign: "center",
    padding: 16,
    borderRadius: 8,
    boxShadow: `inset 0 0 0 1px rgba(100, 100, 100, .2)`,
  },
  footer: {
    width: 480,
    textAlign: "center",
    padding: 16,
  },
});

// TODO replace skeleton styles with className
const SkeletonImage: FC<{ style?: CSSProperties }> = ({ style }) => (
  <Space style={style}>
    <Skeleton.Image
      active
      style={{ borderRadius: "50%", width: 76, height: 76 }}
    />
    <Space direction="vertical">
      <Skeleton.Input active size="small" style={{ height: 15 }} />
      <Skeleton.Input active size="large" style={{ height: 15 }} />
    </Space>
  </Space>
);

const PreLoader: FC = () => {
  const classes = useStyles();

  return (
    <Flex justify="center" align="center" vertical className={classes.wrapper}>
      <div className={classes.content}>
        <div style={{ marginBottom: 44 }}>
          <Skeleton.Input active style={{ width: 400, marginBottom: 14 }} />
          <Skeleton.Input active style={{ height: 20, width: 300 }} />
        </div>
        <div style={{ textAlign: "start" }}>
          <SkeletonImage />
          <SkeletonImage style={{ marginTop: 24 }} />
        </div>
      </div>
      <div className={classes.footer}>
        <Skeleton.Button active block style={{ borderRadius: 8 }} />
        <Skeleton.Button
          active
          block
          style={{ marginTop: 14, borderRadius: 8 }}
        />
      </div>
    </Flex>
  );
};

export default PreLoader;
