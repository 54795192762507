import { useEffect } from "react";
import {
  Col,
  Row,
  Skeleton,
  Empty,
  FormattedMessage,
  Spin,
  LoadingOutlined,
} from "components";
import { useNotification, useSelector, useNavigate } from "hooks";
import { routes } from "configs";
import { ROW_GUTTER } from "consts";
import { Form, Info } from "./components";
import { createUseStyles } from "react-jss";

const COL_LEFT = {
  xs: 24,
  sm: 24,
  md: 14,
  lg: 14,
};

const COL_RIGHT = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 10,
};

const useStyles = createUseStyles({
  row: {
    paddingTop: 14,
  },
});

const AccountClosure = () => {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const classes = useStyles();

  const { account, loading } = useSelector((state) => ({
    account: state.auth.account,
    loading: state.auth.loading,
  }));

  useEffect(() => {
    if (account && !account.enableAccountClose) {
      showNotification({
        type: "warning",
        message: "messages.featureDisabled",
      });

      navigate(routes.login);
    }
  }, [showNotification, account, navigate]);

  if (!account && loading) {
    return (
      <Spin
        indicator={<LoadingOutlined />}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size="large"
        spinning
      />
    );
  }

  if (!account) {
    return (
      <Empty
        description={<FormattedMessage id="messages.account.notFound" />}
      />
    );
  }

  return (
    <Skeleton active loading={loading}>
      <Row gutter={ROW_GUTTER} className={classes.row}>
        <Col {...COL_LEFT}>
          <Info account={account} />
        </Col>
        <Col {...COL_RIGHT}>
          <Form account={account} />
        </Col>
      </Row>
    </Skeleton>
  );
};

export default AccountClosure;
