import { FC } from "react";
import { Typography, Row, Col } from "antd";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import InputText from "../input-text";
import { TranslationSelect, CountriesSelect } from "../input-select";
import InputDatePicker from "../input-date-picker";
import { FormattedMessage } from "react-intl";

interface PersonalProps {
  showTitle?: boolean;
}

const Personal: FC<PersonalProps> = ({ showTitle = true }) => {
  return (
    <>
      {showTitle && (
        <Typography.Title level={4}>
          <FormattedMessage id="common.personal" />
        </Typography.Title>
      )}
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputDatePicker
            label="common.dateOfBirth"
            name="dateOfBirth"
            isAfterToday
            required
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputText
            label="common.placeOfBirthCity"
            name="placeOfBirthCity"
            required
          />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <CountriesSelect
            label="common.placeOfBirthCountry"
            name="placeOfBirthCountryId"
            required
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <TranslationSelect
            name="genderID"
            label="gender.title"
            optionName="genders"
            required
          />
        </Col>
      </Row>
    </>
  );
};

export default Personal;
