import { FC } from "react";
import InputSelect from "../basic";
import { useSelector } from "hooks";
import { TranslatedRecords, TranslatedRecord } from "types";
import * as Types from "../types";

interface TranslationSelectProps
  extends Omit<
    Types.InputSelectProps,
    "options" | "loading" | "placeholder" | "getOptionProps"
  > {
  optionName: keyof TranslatedRecords;
  getOptionProps?: Types.InnerSelectProps["getOptionProps"];
  filterArr?: (option: any) => boolean;
}

const TranslationSelect: FC<TranslationSelectProps> = ({
  optionName,
  filterArr,
  ...props
}) => {
  const { loading, options } = useSelector((state) => ({
    loading: state.appState.languageSwitching,
    options: filterArr
      ? state.appState[optionName].filter(filterArr)
      : state.appState[optionName],
  }));

  const defaultOptionProps = (option: TranslatedRecord) => ({
    children: option.label,
    value: option.id,
  });

  const getOptionProps = props.getOptionProps || defaultOptionProps;

  return (
    <InputSelect
      {...props}
      loading={loading}
      options={options}
      placeholder="placeholders.select"
      getOptionProps={getOptionProps}
    />
  );
};

export default TranslationSelect;
