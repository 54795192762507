import { FC, useEffect, useState } from "react";
import { PreLoader, NewAccount, Login } from "components";
import { StepsForm } from "./components";
import { useSelector, useDispatch } from "hooks";
import { viewHOC } from "hocs";
import { getQueryKey } from "utils";
import { authAsyncActions } from "configs";
import { ViewHOCChildProps } from "types";

const View: FC<
  ViewHOCChildProps<{ email: string; clientExists: boolean; accountID: number }>
> = ({ record }) => {
  const [clientExists, setClientExists] = useState(record?.clientExists);
  const authenticated = useSelector(
    (rootState) => !!rootState.auth.currentClientRecord
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (record?.accountID) {
      dispatch(authAsyncActions.getAccountById(record.accountID));
    }
  }, [record?.accountID, dispatch]);

  if (authenticated) {
    return <StepsForm />;
  }

  if (!clientExists) {
    return (
      <NewAccount
        email={record?.email}
        onAccountExists={() => {
          setClientExists(true);
        }}
      />
    );
  }

  return <Login />;
};

export default viewHOC({
  Component: View,
  preLoader: <PreLoader />,
  apiName: "verifyExternal",
  queryKeyFn: ({ params }) => getQueryKey("verifyExternalCheck", params.uuid),
  pathFn: ({ params }) => `/${params.uuid}/check`,
});
