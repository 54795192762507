import { useState, FC } from "react";
import { Button, List, Form, Typography, Row } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { EditableCell } from "./components";
import ExtendedTable from "components/extended-table";
import StepsButtons from "components/steps-buttons";
import { createUseStyles, getQueryKey } from "utils";
import {
  useSelector,
  useQuery,
  useMutation,
  useForm,
  useNotification,
} from "hooks";
import { FormattedMessage } from "react-intl";
import { selectors, Types, constants } from "./duck";
import { Taxation, TranslatedRecord } from "types";

const useStyles = createUseStyles({
  createButton: {
    marginBottom: 14,
  },
});

const TaxationComponent: FC<Types.TaxationProps> = ({
  parentID,
  parentRoute,
  onNextClick,
  ...btnProps
}) => {
  const [form] = useForm();
  const classes = useStyles();
  const showNotification = useNotification();
  const [state, setState] = useState<Types.State>({
    editingRecord: null,
    createMode: false,
  });

  const { language, noTinReasons, languageSwitching, countries } = useSelector(
    ({ appState }) => ({
      language: appState.language,
      countries: appState.countries,
      languageSwitching: appState.languageSwitching,
      noTinReasons: appState.noTinReasons,
    })
  );
  const queryKey = getQueryKey("taxation", { parentID, parentRoute, language });

  const { data = [], isLoading } = useQuery<Taxation[]>({
    apiName: parentRoute,
    path: `/${parentID}/taxation`,
    queryKey,
  });

  const { mutate: createRecord, isPending: creating } = useMutation({
    apiName: parentRoute,
    path: `/${parentID}/taxation`,
    method: "post" as const,
    invalidateQueries: [queryKey],
    onSuccess: () => {
      setState((prevState) => ({
        ...prevState,
        createMode: false,
      }));
    },
  });

  const {
    mutate: updateRecord,
    isPending: updating,
    queryClient,
  } = useMutation<Taxation>({
    apiName: parentRoute,
    path: `/${parentID}/taxation/${state.editingRecord?.id}`,
    method: "patch",
    onSuccess: (updatedRecord) => {
      queryClient.setQueryData<Taxation[]>(queryKey, (oldData = []) => {
        return oldData.map((item) =>
          item.id === updatedRecord.id ? updatedRecord : item
        );
      });

      setState((prevState) => ({
        ...prevState,
        editingRecord: null,
      }));
    },
  });

  const columns = selectors.getTableColumns({
    countries,
    language,
    noTinReasons,
    queryKey,
    parentRoute,
    parentID,
    createMode: state.createMode,
    submitting: updating || creating,
    isEditing: (id) => id === state.editingRecord?.id,
    onCancel: () => {
      form.resetFields();

      setState((prev) => ({
        ...prev,
        editingRecord: null,
        createMode: false,
      }));
    },
    onEditClick: (record) => {
      form.setFieldsValue(record);

      setState((prev) => ({
        ...prev,
        editingRecord: record,
        createMode: false,
      }));
    },
  });

  return (
    <>
      <List
        size="small"
        header={<FormattedMessage id="taxation.list.title" />}
        dataSource={["taxation.list.opt1", "taxation.list.opt2"]}
        renderItem={(item, index) => (
          <List.Item>
            {index + 1}: <FormattedMessage id={item} />
          </List.Item>
        )}
      />
      <Typography.Paragraph>
        <FormattedMessage id="taxation.subTitle" />
      </Typography.Paragraph>
      <Form
        form={form}
        layout="vertical"
        initialValues={state.editingRecord || {}}
        onFinish={async (values) => {
          if (!form.isFieldsTouched()) {
            setState((prev) => ({
              ...prev,
              editingRecord: null,
              createMode: false,
            }));

            return;
          }

          if (state.editingRecord) {
            updateRecord(values);
          } else {
            createRecord(values);
          }
        }}
        onValuesChange={(values) => {
          const [name] = Object.keys(values);

          if (name === "tin" || name === "noTinReasonID") {
            form.validateFields();
          }
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            className={classes.createButton}
            icon={state.createMode ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => {
              form.setFieldsValue(constants.EMPTY_ROW);

              setState((prev) => ({
                ...prev,
                editingRecord: null,
                createMode: !prev.createMode,
              }));
            }}
          />
        </Row>
        <ExtendedTable<Taxation | typeof constants.EMPTY_ROW>
          loading={isLoading}
          dataSource={state.createMode ? [constants.EMPTY_ROW] : data}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
        />
      </Form>
      <List
        size="small"
        loading={languageSwitching}
        header={null}
        dataSource={noTinReasons}
        renderItem={(item: TranslatedRecord) => {
          const suffix = selectors.getReasonSuffix(item.id);

          return (
            <List.Item>
              <strong>
                <FormattedMessage id="common.reason" /> {suffix}
              </strong>
              : {item.label}
            </List.Item>
          );
        }}
      />
      <StepsButtons
        {...btnProps}
        loading={isLoading}
        onNextClick={() => {
          if (!data.length) {
            showNotification({
              type: "error",
              message: "taxation.error",
            });

            return;
          }

          onNextClick?.();
        }}
      />
    </>
  );
};

export default TaxationComponent;
