import { configureStore } from "@reduxjs/toolkit";
import { authReducer, appStateReducer } from "./slices";
import { createLogger } from "redux-logger";
import middleware from "./middlewares";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appState: appStateReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development") {
      const logger = createLogger({
        collapsed: true,
      });
      return getDefaultMiddleware().prepend(middleware).concat(logger);
    }

    return getDefaultMiddleware().prepend(middleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
