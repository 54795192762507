export const ENDPOINTS = {
  accounts: "accounts",
  addresses: "addresses",
  applications: "applications",
  countries: "countries",
  email: "email",
  financial: "financial",
  poa: "poa",
  streetAddress: "street-address",
  clients: "clients",
  informationRequests: "information-requests",
  verification: "verification",
  verifyExternal: "verify-external",
  phone: "phone",
  personal: "personal",
  officeholders: "officeholders",
  system: "system",
  sumsub: "sumsub",
} as const;
