import { useEffect, useLayoutEffect } from "react";
import flatten from "flat";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, Grid, PreLoader } from "components";
import {
  authActions,
  appStateActions,
  appStateAsyncActions,
  configureAmplify,
} from "configs";
import { useDispatch, useSelector } from "hooks";
import Layout from "layout";
import messages from "translations";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});

const theme = {
  label_font_weight: 500,
  input_disabled_color: "#333 !important",
  input_disbaled_background_color: "#fafafa !important",
};

const App = () => {
  const dispatch = useDispatch();
  const breakpoint = Grid.useBreakpoint();

  const { language, authenticating, accountSlugsLoading } = useSelector(
    (state) => ({
      language: state.appState.language,
      accountSlugsLoading: state.appState.accountSlugsLoading,
      authenticating: state.auth.authenticating,
    })
  );

  useLayoutEffect(() => {
    configureAmplify(language);
  }, [language]);

  useEffect(() => {
    dispatch(appStateAsyncActions.getUserGeo());

    dispatch(appStateAsyncActions.getAccountSlugs())
      .unwrap()
      .then(() => {
        dispatch(authActions.authenticateStart());
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(appStateAsyncActions.getTranslatedData({ language }));
  }, [language, dispatch]);

  useEffect(() => {
    dispatch(appStateActions.setBreakpoints(breakpoint));
  }, [breakpoint, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={flatten(messages[language])}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools position="left" initialIsOpen />
            {authenticating || accountSlugsLoading ? <PreLoader /> : <Layout />}
          </QueryClientProvider>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
