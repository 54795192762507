import { FC } from "react";
import {
  Verification,
  Taxation,
  AccountTransactions,
  Edd,
  Compliance,
  Financial,
} from "components";
import { useSelector } from "hooks";
import * as LC from "./components";
import * as SC from "../shared";
import { hocs, Types } from "../../duck";
import { Account, User, CompanyApplication } from "types";
import { showItem } from "utils";

const CompanyApp: FC<Types.ChildAppWrapperProps<CompanyApplication>> = ({
  app,
  appQueryKey,
  steps,
  currentStep,
  onNextClick,
  onPrevClick,
}) => {
  const { streetTypes, countries, account, currentClientRecord } = useSelector(
    (state) => ({
      streetTypes: state.appState.streetTypes,
      countries: state.appState.countries,
      account: state.auth.account as Account,
      currentClientRecord: state.auth.currentClientRecord as User,
    })
  );

  const COMMON_PROPS = {
    onPrevClick,
    onNextClick,
    currentStep,
    steps,
    appQueryKey,
  };

  const holdersProps = {
    appId: app.id,
    accountName: account.file_by,
    countries,
    streetTypes,
    ...COMMON_PROPS,
  };

  const stepComponents = [
    <Verification
      {...COMMON_PROPS}
      parentRoute="applications"
      parentID={app.id}
      first_name={currentClientRecord.first_name}
      last_name={currentClientRecord.last_name}
      showDescription="company"
    />,
    <LC.Company
      {...COMMON_PROPS}
      app={app}
      countries={countries}
      streetTypes={streetTypes}
    />,
    <SC.AppHolders kind="officeholders" {...holdersProps} />,
    <SC.AppHolders kind="shareholders" {...holdersProps} />,
    ...showItem({
      flag: account.applicationSettings.financial,
      item: [
        <Financial
          {...COMMON_PROPS}
          accountName={account.account_name}
          accountTypeID={account.typeId}
          parentID={app.id}
          parentRoute="applications"
          onNextClick={onNextClick}
          companyOrTrust
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.taxation,
      item: [
        <Taxation
          parentRoute="applications"
          parentID={app.id}
          {...COMMON_PROPS}
        />,
      ],
    }),
    ...showItem({
      item: [<SC.Knowledge {...COMMON_PROPS} app={app} />],
      flag: account.applicationSettings.knowledge,
      defaultValue: [
        <AccountTransactions
          {...COMMON_PROPS}
          accountID={account.id}
          parentID={app.id}
          parentRoute="applications"
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.compliance,
      item: [
        <Compliance
          {...COMMON_PROPS}
          parentID={app.id}
          parentRoute="applications"
          onNextClick={() => onNextClick()}
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.edd,
      item: [
        <Edd {...COMMON_PROPS} parentID={app.id} parentRoute="applications" />,
      ],
    }),
    <SC.Confirm
      {...COMMON_PROPS}
      app={app}
      account={account}
      currentClientRecord={currentClientRecord}
      columns={[
        {
          label: "common.companyName",
          value: app.companyName,
        },
        {
          label: "common.companyNumber",
          value: app.companyNumber,
        },
      ]}
    />,
  ];

  return stepComponents[currentStep];
};

export default hocs.wrapper<CompanyApplication>(CompanyApp, 2);
