import { FC, useState } from "react";
import {
  Modal as AntdModal,
  Checkbox,
  FormattedMessage,
  FormInstance,
} from "components";
import { useMutation } from "hooks";
import { selectors } from "./duck";
import { Types } from "../../duck";
import { PERSONAL_KEYS, STREET_ADDRESS_KEYS } from "consts";

const defaultState = {
  checked: false,
  error: false,
};

export interface ModalProps {
  accountName: string;
  appId: number;
  kind: Types.Kind;
  form: FormInstance;
  visible: boolean;
  onCancel: () => void;
  queryKey: string[];
}

const Modal: FC<ModalProps> = ({
  appId,
  form,
  visible,
  onCancel,
  accountName,
  kind,
  queryKey,
}) => {
  const [{ checked, error }, setState] = useState(defaultState);
  const { mutateAsync, isPending } = useMutation({
    invalidateQueries: [queryKey],
    apiName: "applications",
    method: "post",
    path: `/${appId}/${kind}`,
    onSuccess: () => {
      onCancel();
      form.resetFields([
        "telephone",
        "typeId",
        "firstName",
        "lastName",
        "companyName",
        "companyNumber",
        "companyContactFirstName",
        "companyContactLastName",
        "dateOfBirth",
        "email",
        ...STREET_ADDRESS_KEYS,
        ...PERSONAL_KEYS,
      ]);
    },
  });

  return (
    <AntdModal
      open={visible}
      confirmLoading={isPending}
      title={<FormattedMessage id="applications.holdersModalTitle" />}
      okText={<FormattedMessage id="button.agree" />}
      cancelText={<FormattedMessage id="button.cancel" />}
      onCancel={onCancel}
      onOk={async () => {
        if (!checked) {
          setState({
            checked: false,
            error: true,
          });

          return;
        }

        const values = form.getFieldsValue();
        await mutateAsync(values);

        setState((prev) => ({
          ...prev,
          loading: true,
        }));

        setState(defaultState);
      }}
    >
      <Checkbox
        checked={checked}
        onChange={({ target }) =>
          setState((prev) => ({
            ...prev,
            checked: target.checked,
            error: !target.checked,
          }))
        }
      >
        <span style={{ color: error ? "red" : "inherit" }}>
          <FormattedMessage
            id="appHolders.confirm"
            values={{
              account: accountName,
              holder: <FormattedMessage id={selectors.getTranslations(kind)} />,
            }}
          />
        </span>
      </Checkbox>
    </AntdModal>
  );
};

export default Modal;
