import { FC } from "react";
import { Form, Skeleton } from "antd";
import StepsButtons from "components/steps-buttons";
import { Markup } from "./components";
import { useQuery, useMutation } from "hooks";
import { getQueryKey } from "utils";
import { AccountTransactionsProps } from "./types";
import { AccountTransaction } from "types";

const AccountTransactions: FC<AccountTransactionsProps> = ({
  parentID,
  onNextClick,
  loading,
  parentRoute,
  accountID,
  ...btnProps
}) => {
  const [form] = Form.useForm();
  const apiProps = {
    apiName: parentRoute,
    path: `/${parentID}/account-transactions`,
    queryKey: getQueryKey("accountTransaction", parentID),
  };

  const { data, isLoading } = useQuery<AccountTransaction>(apiProps);
  const { mutate, isPending } = useMutation<AccountTransaction>({
    ...apiProps,
    method: data ? "patch" : "post",
    onSuccess: () => {
      onNextClick?.();
    },
  });

  if (isLoading) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      form={form}
      name="accountTransactions"
      preserve={false}
      layout="vertical"
      initialValues={
        data || {
          sourceOfFunds: [],
          sourceOfWealth: [],
          purposeOfAccount: [],
        }
      }
      onFinish={(values) => {
        if (!form.isFieldsTouched()) {
          onNextClick?.();
        } else {
          mutate(values);
        }
      }}
    >
      <Markup accountID={accountID} />
      <StepsButtons {...btnProps} loading={isPending} />
    </Form>
  );
};

export default AccountTransactions;
