import { useQuery } from "./tanstack";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";
import {
  Subscription,
  AccountModeEnum,
  AccountStatusEnum,
  BillingPlanEnum,
} from "types";

const useAccountSubscription = () => {
  const account = useSelector((state) => state.auth.account);
  const queryKey = getQueryKey("accountSubscription", account?.id);

  const { data: subscription, isLoading: subscriptionLoading } =
    useQuery<Subscription>({
      apiName: "accounts",
      path: `/${account?.id}/subscription`,
      enabled: !!account,
      queryKey,
    });

  const paymentDetailsProvided =
    subscription?.billingPlanID === BillingPlanEnum.PayG
      ? subscription?.setupIntentStatus === "succeeded"
      : true;

  const subscriptionActive =
    subscription &&
    subscription.status === "active" &&
    account?.statusID === AccountStatusEnum.Active &&
    paymentDetailsProvided;

  const testAccount = account?.modeID === AccountModeEnum.test;

  return {
    queryKey,
    subscriptionActive: subscriptionActive || testAccount,
    subscriptionLoading,
    subscription,
  };
};

export default useAccountSubscription;
