import { useState, FC } from "react";
import {
  InputText,
  InputEmail,
  FormattedMessage,
  Row,
  Col,
  Button,
  Form,
  ApiOutlined,
  SubmitButton,
} from "components";
import { useSelector } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { ChangePasswordModal } from "./components";
import { CommonProps } from "../../types";

const UserSummary: FC<CommonProps> = ({ user, submitting }) => {
  const mobile = useSelector(({ appState }) => appState.breakpoint.mobile);
  const [state, setState] = useState<{
    loading: boolean;
    modalVisible: boolean;
  }>({
    modalVisible: false,
    loading: false,
  });

  return (
    <>
      <ChangePasswordModal
        open={state.modalVisible}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            modalVisible: false,
          }));
        }}
      />
      <Form name="summary" layout="vertical" initialValues={user}>
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={12}>
                <InputText label="common.first_name" name="first_name" />
              </Col>
              <Col xs={24} sm={12}>
                <InputText label="common.middle_name" name="middle_name" />
              </Col>
            </Row>
            <InputText label="common.last_name" name="last_name" />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <InputEmail label="common.emailAddress" disabled />
            <Row justify={mobile ? "center" : "start"}>
              <Form.Item
                label={<FormattedMessage id="button.changePassword" />}
              >
                <Row justify={mobile ? "center" : "start"}>
                  <Button
                    icon={<ApiOutlined />}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        modalVisible: true,
                      }));
                    }}
                  />
                </Row>
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <SubmitButton loading={submitting} />
      </Form>
    </>
  );
};

export default UserSummary;
