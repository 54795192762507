import { ExtendedTag, ExtendedTable, NavLink } from "components";
import { useSelector, useQuery } from "hooks";
import { TableRow } from "./components";
import { routes } from "configs";
import { getQueryKey } from "utils";
import { DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import { VerifyExternal, ColumnFilterInputTypes } from "types";
import _ from "lodash";

const List = () => {
  const clientID = useSelector((state) => state.auth.currentClientRecord?.id);
  const { data, isLoading } = useQuery<VerifyExternal[]>({
    apiName: "verifyExternal",
    path: "/list",
    queryKey: getQueryKey("verifyExternalList", clientID),
  });

  return (
    <ExtendedTable<VerifyExternal>
      loading={isLoading}
      dataSource={data}
      components={{
        body: {
          row: TableRow,
        },
      }}
      // eslint-disable-next-line
      // @ts-ignore
      onRow={(record) => ({
        subscriptionActive: record.subscriptionActive,
      })}
      columns={[
        {
          title: "common.id",
          ellipsis: true,
          dataIndex: "uuid",
          render: ({ value, record, highlighter }) =>
            record.subscriptionActive ? (
              <NavLink to={routes.verifyExternal.view(value)}>
                {highlighter}
              </NavLink>
            ) : (
              highlighter
            ),
        },
        {
          title: "common.account",
          dataIndex: "accountID",
          onFilter: (value, record) => record.accountID.toString() === value,
          filters: _.uniqBy(data, "accountID").map((item) => ({
            value: item.accountID.toString(),
            text: (
              <ExtendedTag backgroundColor={item.accountColor}>
                {item.account_name}
              </ExtendedTag>
            ),
          })),
          render: ({ record }) => (
            <ExtendedTag backgroundColor={record.accountColor}>
              {record.account_name}
            </ExtendedTag>
          ),
        },
        {
          title: "common.createdDate",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "common.submitted",
          dataIndex: "submittedAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => value && dayjs(value).format(DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default List;
