import { FC } from "react";
import { Button, DeleteOutlined } from "components";
import { useDeleteMutation } from "hooks";
import { Types } from "../../duck";

interface RemoveButtonProps
  extends Pick<Types.AppHoldersProps, "appId" | "kind"> {
  recordID: number;
  queryKey: string[];
}

const RemoveButton: FC<RemoveButtonProps> = ({
  appId,
  kind,
  recordID,
  queryKey,
}) => {
  const { mutate, isPending } = useDeleteMutation({
    apiName: "applications",
    path: `/${appId}/${kind}/${recordID}`,
    invalidateQueries: [queryKey],
  });

  return (
    <Button
      danger
      type="text"
      loading={isPending}
      icon={<DeleteOutlined />}
      onClick={() => {
        mutate();
      }}
    />
  );
};

export default RemoveButton;
