import { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useDeleteMutation } from "hooks";
import { Button } from "antd";
import { getQueryKey } from "utils";
import { ParentRoute } from "../../types";

interface ActionsColumnProps {
  parentID: number;
  parentRoute: ParentRoute;
  recordID: number;
}

const ActionsColumn: FC<ActionsColumnProps> = ({
  parentID,
  parentRoute,
  recordID,
}) => {
  const { mutate, isPending } = useDeleteMutation({
    apiName: parentRoute,
    path: `/${parentID}/edd/${recordID}`,
    invalidateQueries: [
      getQueryKey("edd", {
        // from useChildAPI
        parentRoute,
        parentID,
      }),
    ],
  });

  return (
    <Button
      danger
      type="text"
      icon={<DeleteOutlined />}
      loading={isPending}
      onClick={() => {
        mutate();
      }}
    />
  );
};

export default ActionsColumn;
