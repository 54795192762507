import * as React from "react";
import {
  Form,
  InputNumber as AInputNumber,
  FormItemProps,
  InputNumberProps as AInputNumberProps,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { classNames, createUseStyles } from "utils";

interface InputNumberProps
  extends Omit<FormItemProps, "children" | "onReset" | "tooltip">,
    Omit<AInputNumberProps, "name" | "status"> {
  placeholder?: string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  disabled?: boolean;
  positiveOnly?: boolean;
}

const useStyles = createUseStyles({
  inputNumber: { width: '100%' }
});

const InputNumber: React.FC<InputNumberProps> = ({
  className,
  name,
  placeholder = "",
  rules = [],
  required,
  disabled,
  label,
  step,
  positiveOnly,
  preserve,
  tooltip,
}) => {
  const intl = useIntl();
  // const isInteger = (values: string) => {
  //   // console.log(values)
  //   const re = /^[0-9\b]+$/;
  //   if (values === "" || re.test(values)) {
  //     return false;
  //   }
  //   return true;
  // };
  const classes = useStyles();

  return (
    <Form.Item
      name={name}
      preserve={preserve}
      className={classNames(classes.inputNumber, className)}
      tooltip={tooltip && <FormattedMessage id={tooltip} />}
      label={
        label ? intl.formatMessage({ id: label, defaultMessage: "" }) : null
      }
      rules={[
        {
          required,
          message: 'Can"t be blank',
        },
        {
          validator: async (_, value) => {
            // if a user types in not a number, then value can be as a string
            if (positiveOnly && value !== "" && value <= 0) {
              return Promise.reject(
                <FormattedMessage
                  id="validation.greaterThan"
                  values={{ n: 0 }}
                />
              );
            }

            return Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <AInputNumber placeholder={placeholder} disabled={disabled} step={step} className={classNames(classes.inputNumber, className)} />
    </Form.Item>
  );
};
export default InputNumber;
