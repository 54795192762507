import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { API, Auth, showNotification } from "utils";
import * as Types from "./types";

export const loginStart = createAction(
  "loginStart",
  (payload: { email: string; password: string }) => ({
    payload,
  })
);
export const loginError = createAction<{ confirmSignup: boolean } | undefined>(
  "loginError"
);

export const getAccountById = createAsyncThunk<Types.Account, number>(
  "GET_ACCOUNT_BY_ID",
  (accountId, { getState }) =>
    API.get({
      apiName: "accounts",
      path: `/${accountId}`,
    }).catch(() => {
      const state = getState() as any;

      showNotification({
        language: state.appState.language,
        type: "error",
        message: "messages.account.fetchFailed",
      });
      return Promise.reject();
    })
);

export const getAccountBySlug = createAsyncThunk<Types.Account, string>(
  "GET_ACCOUNT_BY_SLUG",
  (slug, { getState }) => {
    return API.get({ apiName: "accounts", path: `/slug/${slug}` }).catch(() => {
      const state = getState() as any;

      showNotification({
        language: state.appState.language,
        type: "error",
        message: "messages.account.fetchFailed",
      });
      return Promise.reject();
    });
  }
);

export const updateUser = createAsyncThunk<
  Types.User,
  { id: number } & Types.User
>("UPDATE_USER", ({ id, ...values }, { getState }) => {
  const state = getState() as any;

  return API.patch({
    apiName: "clients",
    path: `/${id}`,
    options: {
      body: values,
    },
  })
    .then((updatedUser) => {
      showNotification({
        language: state.appState.language,
        type: "success",
        message: "messages.success",
      });

      return updatedUser;
    })
    .catch(() => {
      showNotification({
        language: state.appState.language,
        type: "error",
        message: "messages.requestFailed",
      });
    });
});

export const logOut = createAsyncThunk("LOGOUT", () => Auth.signOut());
