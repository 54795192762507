import { FC } from "react";
import {
  Form,
  Slider,
  SliderSingleProps,
  FormItemProps,
  Space,
  FormInstance,
} from "antd";
import { FormattedMessage } from "react-intl";
import { selectors, Types } from "./duck";
import { useSelector } from "../../hooks";

interface InputSliderProps
  extends Omit<SliderSingleProps, "marks">,
    Omit<FormItemProps, "label" | "name" | "tooltip"> {
  label: string;
  name: string;
  labelValues?: Record<string, string | number>;
  requiredAccordingTo?: (
    args: Omit<FormInstance, "scrollToField" | "getFieldInstance">
  ) => boolean;
  marks: Types.SliderMark;
}

const InputSlider: FC<InputSliderProps> = ({
  name,
  label,
  labelValues,
  rules = [],
  required,
  min,
  max,
  step = null,
  marks,
  dependencies = [],
  requiredAccordingTo,
  ...props
}) => {
  const { mobile } = useSelector((state) => state.appState.breakpoint);

  return (
    <Form.Item noStyle dependencies={[name, ...dependencies]}>
      {(formInstance) => {
        const value = formInstance.getFieldValue(name);

        return (
          <Form.Item
            name={name}
            label={
              <Space>
                <FormattedMessage id={label} values={labelValues} />
                {mobile ? (
                  <strong>{selectors.getPrecisionLetter(value)}</strong>
                ) : undefined}
              </Space>
            }
            rules={[
              {
                required: required || requiredAccordingTo?.(formInstance),
                message: <FormattedMessage id="validation.required" />,
              },
              ...rules,
            ]}
          >
            <Slider
              {...props}
              min={min}
              max={max}
              step={step}
              // eslint-disable-next-line
              // @ts-ignore
              marks={selectors.getMarks(marks, mobile)}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default InputSlider;
