import moment from "moment";

const CatchAllPage = () => (
  <div
    style={{
      padding: 24,
      background: "#fff",
      minHeight: 360,
    }}
  >
    <div
      style={{
        paddingLeft: 0,
        borderBottom: "1px solid rgb(235, 237, 240)",
        marginBottom: 16,
      }}
      title="eApplication Form"
    />
    <strong>Page not found</strong>
    <br />
    eApplication Form ©{moment().format("yyyy")}
  </div>
);

export default CatchAllPage;
