import { FC } from "react";
import { AccountStatusEnum, Subscription } from "types";
import { Alert, Tag, Typography } from "components";

interface AccountInfoMessageProps {
  accountStatusID?: number;
  subscriptionActive: boolean;
  subscriptionLoading: boolean;
  subscription?: Subscription;
}

const AccountInfoMessage: FC<AccountInfoMessageProps> = ({
  accountStatusID,
  subscriptionActive,
  subscriptionLoading,
  subscription,
}) => {
  if (subscriptionLoading) {
    return null;
  }

  if (accountStatusID === AccountStatusEnum.Incomplete) {
    return (
      <Alert
        showIcon
        type="error"
        message="Account incomplete."
        description="Some features are disabled"
      />
    );
  }

  if (accountStatusID === AccountStatusEnum.Pending) {
    return (
      <Alert
        showIcon
        type="info"
        message="Account Pending Activation"
        description={
          <>
            Your account is being activated. Please check back shortly or you
            can email us at{" "}
            <Typography.Paragraph copyable>
              admin@eapplicationform.com
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  if (!subscriptionActive) {
    const status = subscription?.setupIntentStatus || subscription?.status;
    return (
      <Alert
        showIcon
        type="error"
        message="No active subscription"
        description={
          <>
            Some features are temporarily disabled.&nbsp;
            {status && <Tag>Status: {status}</Tag>}
          </>
        }
      />
    );
  }

  return null;
};

export default AccountInfoMessage;
