import * as React from "react";
import * as C from "components";
import { AlertInProgress } from "./components";
import * as H from "hooks";
import { Account } from "types";
import { Types, operations } from "./duck";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const FormComponent: React.FC<{ account: Account }> = ({ account }) => {
  const [form] = C.Form.useForm();
  const showNotification = H.useNotification();
  const { items } = H.useAgreements(account.id, 1);

  const [state, setState] = React.useState<Types.State>({
    checkboxErrors: [],
    loading: false,
    demoInProgress: false,
  });

  const language = H.useSelector(({ appState }) => appState.language);

  const validateForm = (values: any) => {
    operations.createDemoAccount(
      values,
      setState,
      account,
      language,
      showNotification
    );
  };

  if (state.demoInProgress) {
    return <AlertInProgress />;
  }

  return (
    <C.Card title={<C.FormattedMessage id="open_demo_account.form.title" />}>
      <p>
        <C.FormattedMessage
          id="open_demo_account.form.description"
          values={{
            product_name: <strong>{account.product_name}</strong>,
          }}
        />
      </p>
      <C.Divider />
      <C.Form {...formItemLayout} onFinish={validateForm} form={form}>
        <C.InputText label="common.first_name" name="first_name" required />
        <C.InputText label="common.last_name" name="last_name" required />
        <C.PhoneInput label="phone.title" name="telephone" required />
        <C.CountriesSelect
          required
          restricted
          name="streetCountryId"
          disabledProp="disabledDemo"
        />
        <C.InputEmail label="common.emailAddress" name="email" required />
        <C.InputPassword label="common.password" name="password" required />

        <C.Divider />

        {items}
        <C.Button htmlType="submit" type="primary" loading={state.loading}>
          <C.FormattedMessage id="button.create" />
        </C.Button>
      </C.Form>
    </C.Card>
  );
};

export default FormComponent;
