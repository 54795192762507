import * as React from "react";
import * as C from "components";
import {
  CompanyApplication,
  Country,
  TranslatedRecord,
  Phone,
  StreetAddress,
} from "types";
import { Types as WrapperTypes } from "../../../duck";
import { useChildAPI, useForm, useGetAccountSlug, useMutation } from "hooks";
import { COMMON_COL_PROPS } from "consts";

interface CompanyStepProps
  extends Omit<
    WrapperTypes.ChildAppWrapperProps<CompanyApplication>,
    "setCompanyTrustee" | "nextStep" | "companyTrustee" | "loading"
  > {
  countries: Country[];
  streetTypes: TranslatedRecord[];
}

const Company: React.FC<CompanyStepProps> = ({
  countries,
  streetTypes,
  onNextClick,
  app,
  appQueryKey,
  ...btnProps
}) => {
  const [form] = useForm();
  const accountSlug = useGetAccountSlug();
  const { mutateAsync: updateApp, isPending } = useMutation<
    CompanyApplication,
    {
      companyName: string;
      companyNumber: string;
      incorporationCountryID?: number;
      incorporationDate?: string;
    }
  >({
    apiName: "applications",
    path: `/${app.id}`,
    options: {
      queryParams: {
        accountSlug,
      },
    },
    invalidateQueries: [appQueryKey],
    method: "patch",
  });

  const {
    data: phone,
    fetching: fetchingPhone,
    saving: savingPhone,
    save: savePhone,
  } = useChildAPI<Phone>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "phone",
  });
  const {
    data: streetAddress,
    fetching: fetchingStreetAddress,
    saving: savingStreetAddress,
    save: saveStreetAddress,
  } = useChildAPI<StreetAddress>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "street-address",
  });

  if (fetchingStreetAddress || fetchingPhone) {
    return <C.Skeleton active loading />;
  }

  return (
    <C.Form
      form={form}
      layout="vertical"
      initialValues={{
        ...(streetAddress || {}),
        ...(phone || {}),
        ...app,
      }}
      onFinish={async (values) => {
        if (!form.isFieldsTouched()) {
          onNextClick?.();
        } else {
          await Promise.all([
            saveStreetAddress(values),
            savePhone(values),
            updateApp({
              companyName: values.companyName,
              companyNumber: values.companyNumber,
              incorporationCountryID: values.incorporationCountryID,
              incorporationDate: values.incorporationDate,
            }),
          ]);

          onNextClick?.();
        }
      }}
    >
      <C.Row gutter={[14, 14]}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id="common.company" />
          </C.Typography.Title>
          <C.InputText label="common.companyName" name="companyName" required />
          <C.InputText label="common.companyNumber" name="companyNumber" />
          <C.CountriesSelect
            restricted
            label="applications.company.incorporationCountry"
            name="incorporationCountryID"
            disabledProp="disabledLive"
          />
          <C.InputDatePicker
            allowClear
            name="incorporationDate"
            label="applications.company.incorporationDate"
          />
          <C.Phone />
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.StreetAddress countries={countries} streetTypes={streetTypes} />
        </C.Col>
      </C.Row>
      <C.StepsButtons
        {...btnProps}
        loading={savingPhone || savingStreetAddress || isPending}
      />
    </C.Form>
  );
};
export default Company;
