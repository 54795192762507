import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { asyncActions, Types } from "./duck";
import { InitialData } from "types";

const initialState: Types.State = {
  account: null,
  currentClientRecord: null,
  loading: false,
  accountLoading: false,
  loggingOut: false,
  confirmSignup: false,
  authenticating: true,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Types.Account>) => {
      state.account = action.payload;
    },
    setUser: (state, action: PayloadAction<Types.User>) => {
      state.currentClientRecord = action.payload;
    },

    authenticateStart: (state) => {
      state.authenticating = true;
    },
    authenticateError: (state) => {
      state.authenticating = false;
    },
    authenticateSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.authenticating = false;
      state.account = payload.account;
      state.currentClientRecord = payload.client;
    },
    loginSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.loading = false;
      state.confirmSignup = false;
      state.account = payload.account;
      state.currentClientRecord = payload.client;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncActions.logOut.fulfilled, () => ({
      ...initialState,
      authenticating: false,
    }));

    builder.addCase(asyncActions.logOut.pending, (state) => {
      state.loggingOut = true;
    });
    builder.addCase(asyncActions.logOut.rejected, (state) => {
      state.loggingOut = false;
    });
    builder.addCase(asyncActions.loginError, (state, { payload }) => {
      state.loading = false;
      state.confirmSignup = !!payload?.confirmSignup;
    });

    builder.addCase(asyncActions.updateUser.fulfilled, (state, { payload }) => {
      state.currentClientRecord = payload;
      state.loading = false;
    });
    builder.addCase(asyncActions.getAccountById.pending, (state) => {
      state.accountLoading = true;
    });
    builder.addCase(asyncActions.getAccountById.rejected, (state) => {
      state.accountLoading = false;
    });

    const pendingMatcher = isAnyOf(
      asyncActions.loginStart,
      asyncActions.getAccountBySlug.pending,
      asyncActions.updateUser.pending
    );

    const rejectedMatcher = isAnyOf(
      asyncActions.getAccountBySlug.rejected,
      asyncActions.updateUser.rejected
    );

    builder.addMatcher(
      isAnyOf(
        asyncActions.getAccountById.fulfilled,
        asyncActions.getAccountBySlug.fulfilled
      ),
      (state, { payload }) => {
        state.account = payload;
        state.loading = false;
        state.accountLoading = false;
      }
    );

    builder
      .addMatcher(pendingMatcher, (state) => {
        state.loading = true;
        state.confirmSignup = false;
      })
      .addMatcher(rejectedMatcher, (state) => {
        state.loading = false;
      });
  },
});

export const { reducer, actions } = auth;
