import * as React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Form, Upload, Typography, UploadProps, UploadFile } from "antd";
import { UploadRef } from "antd/lib/upload/Upload";
import { FormattedMessage } from "react-intl";
import { useNotification } from "hooks";
import { ACCEPTED_FILES } from "consts";
import { RcFile } from "types";

const { Dragger } = Upload;
const { Paragraph } = Typography;

interface FileUploadProps extends UploadProps {
  onRemove?: UploadProps["onRemove"];
  onUploadSuccess?: (file: RcFile) => void;
  required?: boolean;
  multiple?: boolean;
  name?: string;
  label?: string | React.ReactNode;
  getValueProps?: (v: any) => { value: any };
  maxCount?: number;
  innerRef?: React.Ref<UploadRef>;
  formItemClassName?: string;
}

const addThumbURL = (file: UploadFile) => {
  // new Chrome update prevents opening files via base64
  file.thumbUrl = URL.createObjectURL(file.originFileObj as File);
  return file;
};

const FileUpload: React.FC<FileUploadProps> = ({
  onRemove,
  required = false,
  multiple = false,
  name,
  label,
  getValueProps,
  maxCount,
  children,
  innerRef,
  formItemClassName,
  onUploadSuccess,
  ...rest
}) => {
  const showNotification = useNotification();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.map(addThumbURL);
    }
    return e && e.fileList.map(addThumbURL);
  };

  const dragger = (
    <Dragger
      {...rest}
      ref={innerRef}
      multiple={multiple}
      accept={ACCEPTED_FILES}
      onRemove={onRemove}
      maxCount={maxCount}
      customRequest={({ onSuccess, file }) => {
        onSuccess?.(file);

        onUploadSuccess?.(file as RcFile);
      }}
      isImageUrl={(file) =>
        ["jpg", "jpeg", "png"].some(
          (format) => file.type?.indexOf(format) !== -1
        )
      }
      beforeUpload={(file) => {
        const format = file.type.split("/")[1];

        if (file.size / 1024 / 1024 > 5) {
          showNotification({
            type: "error",
            message: "messages.bigFile",
          });

          return Upload.LIST_IGNORE;
        }

        if (!/(pdf|doc|docx|jpg|jpeg|png|msword)$/g.test(format)) {
          showNotification({
            type: "error",
            message: "messages.invalidFileFormat",
            messageValues: {
              format: {
                value: format,
              },
              allowedFormats: {
                value: ACCEPTED_FILES,
              },
            },
          });

          return Upload.LIST_IGNORE;
        }
      }}
    >
      {children || (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <Paragraph>
            <FormattedMessage id="fileUpload.description" />
          </Paragraph>
          {multiple && (
            <Paragraph>
              <FormattedMessage id="fileUpload.multi" />
            </Paragraph>
          )}
        </>
      )}
    </Dragger>
  );

  if (!name) {
    return dragger;
  }

  return (
    <Form.Item
      name={name}
      label={label}
      className={formItemClassName}
      getValueProps={getValueProps}
      getValueFromEvent={normFile}
      valuePropName="fileList"
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
      ]}
    >
      {dragger}
    </Form.Item>
  );
};

export default FileUpload;
