import * as React from "react";
import { Card, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "utils";

interface Topics {
  title?: string;
  extra?: any;
  descriptionValues?: Record<string, any>;
}

export interface ChildProps {
  getTopics(args: Topics): void;
}

interface DashboardHOC<P> {
  Component: React.FC<P>;
  title: string;
  description?: string;
}

const useStyles = createUseStyles({
  card: {
    overflowY: "auto",
    height: "100%",
    marginTop: 14,
  },
});

export const dashboardHOC =
  <P,>({ Component, title, description }: DashboardHOC<P>) =>
  (props: any) => {
    const classes = useStyles();
    const [state, setState] = React.useState<Topics>({
      extra: [],
    });

    const getTopics = React.useCallback((args: Partial<Topics>) => {
      setState((prev) => ({
        ...prev,
        ...args,
      }));
    }, []);

    return (
      <Card
        extra={state.extra}
        className={classes.card}
        title={<FormattedMessage id={title} />}
      >
        {description && (
          <Typography.Paragraph>
            <FormattedMessage
              id={description}
              values={state.descriptionValues}
            />
          </Typography.Paragraph>
        )}
        <Component {...props} getTopics={getTopics} />
      </Card>
    );
  };
