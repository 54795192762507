import InputSelect from "../basic";
import { InputSelectProps } from "../types";
import { useQuery } from "hooks";
import { QueryKey, ApiArgs } from "types";

interface AsyncSelectProps<TOption = any>
  extends Omit<InputSelectProps<TOption>, "options" | "loading">,
    ApiArgs {
  queryKey: QueryKey;
  enabled?: boolean;
}

const AsyncSelect = <TOption,>({
  apiName,
  path,
  queryKey,
  options,
  enabled,
  ...props
}: AsyncSelectProps<TOption>) => {
  const { data = [], isLoading } = useQuery<TOption[]>({
    queryKey,
    apiName,
    path,
    options,
    enabled,
  });

  return <InputSelect {...props} loading={isLoading} options={data} />;
};

export default AsyncSelect;
