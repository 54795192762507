import { FC } from "react";
import { Button, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";

const ManualUploadButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Row justify="space-between" align="middle">
      <Typography.Paragraph>
        <FormattedMessage id="verifications.manualUpload">
          {(text) => (
            <>
              {text}
              &nbsp;
              <Button onClick={onClick}>
                <FormattedMessage id="common.clickHere" />
              </Button>
            </>
          )}
        </FormattedMessage>
      </Typography.Paragraph>
    </Row>
  );
};

export default ManualUploadButton;
