import * as React from "react";
import { Divider, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import android_web_badge from "assets/img/android_web_badge.png";
import ios_web_badge from "assets/img/ios_web_badge.svg";
import { Account } from "types";

const { Title } = Typography;

const useStyles = createUseStyles({
  root: {
    paddingLeft: 14,
    background: "none",
    minHeight: 360,
  },
  title: { "&.ant-typography": { fontStyle: "bold" } },
  sub_title: {
    "&.ant-typography": {
      fontSize: "1.6em",
      fontWeight: 600,
    },
  },
  info_text: { fontSize: "1.2em" },
  main_card: {
    width: "100%",
    "@media (min-width: 800px)": { width: "80%" },
  },
});

const DemoAccountPageInfoComponent: React.FC<{ account: Account }> = ({
  account,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title className={classes.title}>
        <FormattedMessage
          id="open_demo_account.info.title"
          values={{ account_name: account.account_name }}
        />
      </Title>
      <Divider />
      <Title level={4} className={classes.sub_title}>
        <FormattedMessage
          id="open_demo_account.info.sub_title"
          values={{ product_name: account.product_name }}
        />
      </Title>
      <Divider />
      <p className={classes.info_text}>
        <FormattedMessage
          id="open_demo_account.info.description"
          values={{
            account_name: <strong>{account.account_name}</strong>,
            product_name: <strong>{account.product_name}</strong>,
          }}
        />
      </p>
      <p className={classes.info_text}>
        <FormattedMessage id="open_demo_account.info.mobile_apps" />
      </p>
      <Divider />
      {account.ios_app_link && (
        <a
          href={account.ios_app_link}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img style={{ height: 40 }} alt="..." src={ios_web_badge} />
        </a>
      )}
      {account.android_app_link && (
        <a
          href={account.android_app_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img style={{ height: 40 }} alt="..." src={android_web_badge} />
        </a>
      )}
    </div>
  );
};

export default DemoAccountPageInfoComponent;
