import { FC, useEffect, useState } from "react";
import * as LC from "./components";
import {
  useSelector,
  useNotification,
  usePrevious,
  useQuery,
  useMutation,
} from "hooks";
import { Types } from "./duck";
import SumsubWebSdk from "@sumsub/websdk-react";
import StepsButtons from "components/steps-buttons";
import { VerificationDocV, SumSubApplicantStatus } from "types";
import { getQueryKey, API } from "utils";
import { COLUMN_MAP } from "./duck/constants";

const Verification: FC<Types.VerificationProps> = ({
  parentID,
  parentRoute,
  onNextClick,
  showDescription,
  first_name,
  last_name,
  ...props
}) => {
  const showNotification = useNotification();
  const [state, setState] = useState<Types.State>({
    saving: false,
    loading: true,
    manualUpload: false,
    sumSubMounted: true,
  });
  const { languageSwitching, sumSubActive, countries, language } = useSelector(
    ({ appState }) => ({
      languageSwitching: appState.languageSwitching,
      language: appState.language,
      sumSubActive: appState.sumSubActive,
      countries: appState.countries,
    })
  );

  const prevLanguage = usePrevious(language);

  // Changing the config prop doesn't update the interface inside of the iframe
  // so if a user during his app experience decides to change the language, we need to remount the component
  useEffect(() => {
    if (language !== prevLanguage) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          sumSubMounted: false,
        }));

        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            sumSubMounted: true,
          }));
        });
      });
    }
  }, [language, prevLanguage]);

  const queryKey = getQueryKey("verification", { parentID, parentRoute });
  const { data: record, isLoading } = useQuery<VerificationDocV>({
    apiName: parentRoute,
    path: `/${parentID}/verification`,
    enabled: !!parentID,
    queryKey,
  });
  const {
    queryClient,
    mutateAsync: updateVerification,
    isPending: updatingVerification,
  } = useMutation<VerificationDocV, Types.UpdateVerificationArgs>({
    apiName: parentRoute,
    path: `/${parentID}/verification`,
    method: "patch",
    invalidateQueries: [queryKey],
  });

  const {
    data: accessToken,
    isPending: tokenLoading,
    mutateAsync: getToken,
  } = useMutation<
    string,
    {
      parentID: number | string;
      parentColumn:
        | "applicationID"
        | "informationRequestID"
        | "verifyExternalUUID";
    }
  >({
    apiName: "sumsub",
    path: "/token",
    method: "post",
  });

  useEffect(() => {
    if (sumSubActive && record && parentID) {
      getToken({
        parentColumn: COLUMN_MAP[parentRoute],
        parentID,
      });
    }
    // eslint-disable-next-line
  }, [sumSubActive, record, parentID]);

  const loader = (
    <>
      <LC.Skeleton />
      <StepsButtons
        onNextClick={onNextClick}
        {...props}
        nextDisabled={state.loading}
      />
    </>
  );

  if (!record) {
    return loader;
  }

  if (!sumSubActive) {
    return (
      <LC.RawForm
        {...props}
        updatingVerification={updatingVerification}
        updateVerification={updateVerification}
        first_name={first_name}
        last_name={last_name}
        record={record}
        parentID={parentID}
        countries={countries}
        parentRoute={parentRoute}
        onNextClick={onNextClick}
        countriesLoading={languageSwitching}
      />
    );
  }

  if (!accessToken) {
    return loader;
  }

  return !state.manualUpload ? (
    <>
      <LC.ManualUploadButton
        onClick={() => {
          setState((prev) => ({
            ...prev,
            manualUpload: true,
          }));
        }}
      />
      {state.sumSubMounted && parentID && (
        <SumsubWebSdk
          accessToken={accessToken}
          expirationHandler={() =>
            API.post({
              apiName: "sumsub",
              path: "/token",
              options: {
                body: {
                  parentColumn: COLUMN_MAP[parentRoute],
                  parentID,
                },
              },
            })
          }
          config={{
            lang: language,
            theme: "light",
          }}
          options={{
            addViewportTag: false,
            adaptIframeHeight: true,
          }}
          onMessage={(type, payload) => {
            if (type === "idCheck.onApplicantStatusChanged") {
              queryClient.setQueryData<VerificationDocV>(queryKey, {
                ...record,
                sumsubApplicantStatus: (
                  payload as { reviewStatus: SumSubApplicantStatus }
                ).reviewStatus,
              });
            }
          }}
          onError={({ error }) => {
            showNotification({
              type: "error",
              message: error,
            });
          }}
        />
      )}
      <StepsButtons
        {...props}
        onNextClick={onNextClick}
        nextDisabled={
          !record.sumsubApplicantStatus ||
          record.sumsubApplicantStatus === "init" ||
          isLoading ||
          tokenLoading ||
          !accessToken
        }
      />
    </>
  ) : (
    <LC.ManualUpload
      {...props}
      onNextClick={onNextClick}
      parentRoute={parentRoute}
      parentID={parentID}
      record={record}
      updatingVerification={updatingVerification}
      updateVerification={updateVerification}
    />
  );
};

export default Verification;
