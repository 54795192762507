import { FC, useState, useEffect } from "react";
import { Form, Row, Col, Skeleton } from "antd";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { useNotification } from "hooks";
import FileUpload from "components/file-upload";
import { Storage } from "utils";
import { FormattedMessage } from "react-intl";
import { Types } from "../../duck";
import { operations } from "./duck";
import { VerificationDocV, StepButtonsProps } from "types";
import StepsButtons from "components/steps-buttons";

interface ManualUploadProps
  extends Omit<Types.CommonProps, "parentID">,
    Omit<StepButtonsProps, "onNextClick">,
    Types.UpdateMutationProps {
  record: VerificationDocV;
  parentID?: Types.CommonProps["parentID"];
}

const ManualUpload: FC<ManualUploadProps> = ({
  onNextClick,
  parentRoute,
  parentID,
  record,
  updatingVerification,
  updateVerification,
  ...props
}) => {
  const [form] = Form.useForm();
  const showNotification = useNotification();
  const [loading, toggleLoading] = useState(false);

  const { frontImgID, backImgID } = record;

  useEffect(() => {
    const fn = async () => {
      const filesArr = [
        {
          name: "frontImgFile",
          id: frontImgID,
        },
        {
          name: "backImgFile",
          id: backImgID,
        },
      ] as const;

      filesArr.forEach(({ name, id }) => {
        if (id) {
          form.setFieldValue(name, [
            {
              status: "uploading",
            },
          ]);

          Storage.getURL({
            key: id,
            bucket: "identificationDocuments",
          })
            .then(async ({ url }) => {
              const response = await fetch(url.toString());
              const blob = await response.blob();

              const file = new File([blob], id, {
                type: blob.type,
              });
              // add thumbUrl for displaying a picture
              // eslint-disable-next-line
              // @ts-ignore
              file.thumbUrl = url.toString();

              form.setFieldValue(name, [file]);
            })
            .catch(() => {
              form.setFieldValue(name, [
                {
                  status: "error",
                },
              ]);
            });
        }
      });
    };

    fn();
  }, [frontImgID, backImgID, form]);

  if (!parentID) {
    return <Skeleton loading active />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        frontImgFile: [],
        backImgFile: [],
      }}
      onFinish={async ({ frontImgFile, backImgFile }) => {
        if (!form.isFieldsTouched()) {
          onNextClick();
          return;
        }

        toggleLoading(true);

        try {
          // antd file.originalObj || nativeFile downloaded via Storage.get
          const front =
            frontImgFile[0]?.originFileObj || frontImgFile[0] || null;
          const back = backImgFile[0]?.originFileObj || backImgFile[0] || null;

          await operations.checkRemovedFields({
            record,
            frontImgFile: front?.name,
            backImgFile: back?.name,
          });

          const [newFrontImgID, newBackImgID] = await Promise.all([
            operations.uploadFile({
              file: front,
              name: "frontImg",
              showNotification,
            }),
            operations.uploadFile({
              file: back,
              name: "backImg",
              showNotification,
            }),
          ]);

          await updateVerification({
            frontImgID: newFrontImgID,
            backImgID: newBackImgID,
          });

          toggleLoading(false);

          onNextClick();
        } catch (e) {
          if (e !== "uploadError") {
            showNotification({
              type: "error",
              message: "messages.requestFailed",
            });
          }

          toggleLoading(false);
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <FileUpload
            name="frontImgFile"
            maxCount={1}
            listType="picture"
            label={<FormattedMessage id="verifications.frontImg" />}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <FileUpload
            name="backImgFile"
            maxCount={1}
            listType="picture"
            label={<FormattedMessage id="verifications.backImg" />}
          />
        </Col>
      </Row>
      <StepsButtons loading={loading} {...props} />
    </Form>
  );
};

export default ManualUpload;
