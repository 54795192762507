import {
  ExtendedDescriptions,
  Row,
  Col,
  FormattedMessage,
  Tag,
} from "components";
import { COMMON_COL_PROPS, DATE_TIME_FORMAT } from "consts";
import { Application } from "types";
import dayjs from "dayjs";

const Summary: React.FC<{ app: Application }> = ({ app }) => {
  const TYPE = {
    1: "common.individual",
    2: "common.company",
    3: "common.trust",
  };

  return (
    <Row gutter={[14, 14]}>
      <Col {...COMMON_COL_PROPS}>
        <ExtendedDescriptions
          items={[
            {
              label: "common.type",
              value: <FormattedMessage id={TYPE[app.typeId]} />,
            },
          ]}
        />
      </Col>
      <Col {...COMMON_COL_PROPS}>
        <ExtendedDescriptions
          items={[
            {
              label: "common.createdDate",
              value: dayjs(app.createdAt).format(DATE_TIME_FORMAT),
            },
            {
              label: (
                <Tag color="green">
                  <FormattedMessage id="common.submitted" />
                </Tag>
              ),
              value:
                app.submittedAt &&
                dayjs(app.submittedAt).format(DATE_TIME_FORMAT),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default Summary;
