import { FC } from "react";
import { ExtendedDescriptions, Row, Col } from "components";
import { COMMON_COL_PROPS } from "consts";

const Summary: FC = () => {
  return (
    <Row gutter={[14, 14]}>
      <Col {...COMMON_COL_PROPS}>
        <ExtendedDescriptions
          items={[
            {
              label: "common.first_name",
              value: "",
            },
            {
              label: "common.last_name",
              value: "",
            },
            {
              label: "common.email",
              value: "",
            },
          ]}
        />
      </Col>
      <Col {...COMMON_COL_PROPS}>
        <ExtendedDescriptions
          items={[
            {
              label: "common.mode",
              value: "",
            },
            {
              label: "common.type",
              value: "",
            },
            {
              label: "common.status",
              value: "",
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default Summary;
