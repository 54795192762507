import { Dispatch, SetStateAction } from "react";
import { sendInteractionEmail } from "requests";
import { Account, ShowNotification } from "types";
import { API } from "utils";
import { State } from "./types";

export const createDemoAccount = async (
  values: any,
  setState: Dispatch<SetStateAction<State>>,
  account: Account,
  language: string,
  showNotification: ShowNotification
) => {
  setState((prev) => ({
    ...prev,
    loading: true,
  }));

  const email = values.email.toLowerCase().trim();

  try {
    sendInteractionEmail({
      account: account?.file_by,
      interaction: "New Demo Account",
      data: {
        email,
        first_name: values.first_name,
        last_name: values.last_name,
        telephone: values.telephone,
        agree_terms: values.termsOfUse,
        agree_privacy: values.privacyPolicy,
        password: values.password,
      },
    });

    await API.post({
      apiName: "clients",
      path: "/create-client-demo",
      options: {
        body: {
          first_name: values.first_name,
          last_name: values.last_name,
          telephone: values.telephone,
          streetCountryId: values.streetCountryId,
          account_id: account.id,
          email,
          password: values.password,
        },
      },
    });

    setState((prev) => ({
      ...prev,
      loading: false,
      demoInProgress: true,
    }));

    showNotification({
      type: "success",
      message: "messages.account.demo.created",
    });

    sendInteractionEmail({
      account: account.file_by,
      interaction: "New Demo Account",
      data: {
        email,
        first_name: values.first_name,
        last_name: values.last_name,
        telephone: values.telephone,
        agree_terms: values.termsOfUse,
        agree_privacy: values.privacyPolicy,
        password: values.password,
        preferred_language: language,
      },
    });
  } catch (e) {
    showNotification({
      type: "error",
      message: "messages.requestFailed",
    });

    sendInteractionEmail({
      account: account.file_by,
      interaction: "New Demo Account",
      data: {
        email,
        first_name: values.first_name,
        last_name: values.last_name,
        telephone: values.telephone,
        agree_terms: values.termsOfUse,
        agree_privacy: values.privacyPolicy,
        password: values.password,
      },
    });

    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  }
};
