import * as React from "react";
import * as C from "components";

interface ModalProps {
  onOk(): void;
  onCancel(): void;
  open: boolean;
  loading: boolean;
  signerFullName: string;
  submissionDate: {
    utc: string;
    local: string;
  };
}

const Modal: React.FC<ModalProps> = ({
  loading,
  onOk,
  onCancel,
  open,
  submissionDate,
  signerFullName,
}) => {
  const [state, setState] = React.useState<{
    errorColor: "red" | "inherit";
    checked: boolean;
  }>({
    errorColor: "inherit",
    checked: false,
  });

  return (
    <C.Modal
      open={open}
      confirmLoading={loading}
      title="W-8BEN Form Certification"
      onCancel={onCancel}
      onOk={() => {
        if (!state.checked) {
          setState((prev) => ({ ...prev, errorColor: "red" }));
        } else {
          onOk();
          setState((prev) => ({ ...prev, errorColor: "inherit" }));
        }
      }}
    >
      <C.Typography.Paragraph>
        Under penalties of perjury, I declare that I have examined the
        information on this form and to the best of my knowledge and belief it
        is true, correct, and complete. I further certify under penalties of
        perjury that:
      </C.Typography.Paragraph>

      <C.Typography.Paragraph>
        I am the individual that is the beneficial owner (or am authorized to
        sign for the individual that is the beneficial owner) of all the income
        to which this form relates or am using this form to document myself for
        chapter 4 purposes, The person named on line 1 of this form is not a
        U.S. person, The income to which this form relates is:
      </C.Typography.Paragraph>

      <ul>
        <li>
          not effectively connected with the conduct of a trade or business in
          the United States,
        </li>
        <li>
          effectively connected but is not subject to tax under an applicable
          income tax treaty, or
        </li>
        <li>
          the partner&apos;s share of a partnership&apos;s effectively connected
          income,
        </li>
      </ul>

      <C.Typography.Paragraph>
        The person named on line 1 of this form is a resident of the treaty
        country listed on line 9 of the form (if any) within the meaning of the
        income tax treaty between the United States and that country, and
      </C.Typography.Paragraph>
      <C.Typography.Paragraph>
        For broker transactions or barter exchanges, the beneficial owner is an
        exempt foreign person as defined in the instructions.
      </C.Typography.Paragraph>
      <C.Typography.Paragraph>
        Furthermore, I authorize this form to be provided to any withholding
        agent that has control, receipt, or custody of the income of which I am
        the beneficial owner or any withholding agent that can disburse or make
        payments of the income of which I am the beneficial owner. I agree that
        I will submit a new form within 30 days if any certification made on
        this form becomes incorrect.
      </C.Typography.Paragraph>
      <C.Typography.Paragraph>
        <C.Checkbox
          checked={state.checked}
          onChange={({ target }) => {
            setState({
              errorColor: target.checked ? "inherit" : "red",
              checked: target.checked,
            });
          }}
        >
          <span style={{ color: state.errorColor }}>
            By ticking this box I electronically sign this W8-BEN form.
          </span>
        </C.Checkbox>
      </C.Typography.Paragraph>
      <C.Typography.Paragraph>
        -- <C.Typography.Text strong>Print name of signer:</C.Typography.Text>{" "}
        {signerFullName}
      </C.Typography.Paragraph>
      <C.Typography.Paragraph>
        -- <C.Typography.Text strong>Date:</C.Typography.Text>{" "}
        {submissionDate.local}
      </C.Typography.Paragraph>
    </C.Modal>
  );
};

export default Modal;
