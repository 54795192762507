import { transformAppID } from "utils";

export default {
  // dashboard
  dashboard: "/dashboard",

  // User Profile
  userProfile: "/user-profile",

  // Verifications
  verifications: "/verifications",

  // Login
  login: "/login",

  // Applications
  applications: "/applications",
  openApplication: ({
    accountSlug,
    typeId,
    id,
    tab,
  }: {
    accountSlug: string;
    typeId: 1 | 2 | 3;
    id?: number;
    tab?: "summary" | "messaging";
  }) => {
    const appTypes = {
      1: "individual",
      2: "company",
      3: "trust",
    };

    const appId = id
      ? `?appId=${transformAppID(id)}${tab ? `&tab=${tab}` : ""}`
      : "";

    return `/open-${appTypes[typeId]}-account/${accountSlug}${appId}`;
  },
  openDemoAccount: (accountSlug: string) => `/open-demo-account/${accountSlug}`,
  openAccountClosure: (accountSlug: string) => `/close-account/${accountSlug}`,
  createApplication: "/create-account",
  chooseApplication: (accountSlug: string) => `/open-account/${accountSlug}`,
  whiteLabel: (accountSlug: string) => `/white-label/${accountSlug}`,

  verifyExternal: {
    main: "/verify-external",
    view(uuid?: string) {
      return `${this.main}/${uuid || ":uuid"}`;
    },
    list() {
      return this.main;
    },
  },

  // Information Requests
  informationRequest: {
    main: "/information-request",
    view(uuid?: string) {
      return `${this.main}/${uuid || ":uuid"}`;
    },
  },
};
