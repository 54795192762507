import { FC, useState } from "react";
import { Modal, FileRenderer } from "components";
import { createUseStyles } from "react-jss";
import { Storage } from "utils";
import { useNotification } from "hooks";

interface PreviewModalProps {
  currentFile: File | null;
  onCancel: () => void;
  appID: number;
  onOk: (awsFileID: string) => void;
}

const useStyles = createUseStyles({
  modal: {
    maxHeight: 500,
    width: "auto",
    overflowY: "auto",

    "& .ant-modal-body": {
      textAlign: "center",
    },
  },
});

const PreviewModal: FC<PreviewModalProps> = ({
  currentFile,
  onCancel,
  onOk,
  appID,
}) => {
  const classes = useStyles();
  const showNotification = useNotification();
  const [loading, toggleLoading] = useState(false);

  return (
    <Modal
      centered
      onCancel={onCancel}
      open={!!currentFile}
      confirmLoading={loading}
      className={classes.modal}
      onOk={async () => {
        if (!currentFile) {
          return;
        }

        try {
          toggleLoading(true);

          const resp = await Storage.uploadData({
            key: `${Date.now()}-${appID}-${currentFile.name}`,
            data: currentFile,

            bucket: "applicationDocuments",
          }).result;

          toggleLoading(false);

          onOk(resp.key);
        } catch {
          toggleLoading(false);
          showNotification({
            type: "error",
            message: "messages.requestFailed",
          });
        }
      }}
    >
      {currentFile && (
        <FileRenderer file={currentFile} bucket="applicationDocuments" />
      )}
    </Modal>
  );
};

export default PreviewModal;
