import { FC, ReactNode } from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import InputText from "components/input-text";
import { InputSelect, CountriesSelect } from "components/input-select";
import { getReasonSuffix } from "../../duck/selectors";

import {
  Taxation,
  Country,
  TranslatedRecord,
  ExtendedTableCellProps,
} from "types";

interface EditableCellProps extends ExtendedTableCellProps {
  record: Taxation;
  editing: boolean;
  createMode: boolean;
  children: ReactNode;
  countries: Country[];
  noTinReasons: TranslatedRecord[];
  dataIndex: "countryJurisdiction" | "tin" | "noTinReasonID" | "furtherDetails";
}

const EditableCell: FC<EditableCellProps> = ({
  record,
  editing,
  createMode,
  children,
  dataIndex,
  countries,
  noTinReasons,
  inputClassName,
  ...props
}) => {
  if (!editing && !createMode) {
    return <td {...props}>{children}</td>;
  }

  switch (dataIndex) {
    case "countryJurisdiction":
      return (
        <td {...props}>
          <CountriesSelect
            label={null}
            required
            restricted
            name={dataIndex}
            className={inputClassName}
          />
        </td>
      );
    case "noTinReasonID":
      return (
        <td {...props}>
          <Form.Item dependencies={["tin"]} noStyle>
            {({ getFieldValue }) => {
              const tin = getFieldValue("tin");

              return (
                <InputSelect
                  required={!tin}
                  allowClear
                  name={dataIndex}
                  className={inputClassName}
                  options={noTinReasons}
                  getOptionProps={(option: TranslatedRecord) => {
                    const suffix = getReasonSuffix(option.id);

                    return {
                      value: option.id,
                      children: (
                        <>
                          <FormattedMessage id="common.reason" /> {suffix}
                        </>
                      ),
                    };
                  }}
                />
              );
            }}
          </Form.Item>
        </td>
      );
    case "furtherDetails":
      return (
        <td {...props}>
          <Form.Item dependencies={["noTinReasonID"]} noStyle>
            {({ getFieldValue }) => {
              const noTinReasonID = getFieldValue("noTinReasonID");

              return (
                <InputText
                  name={dataIndex}
                  className={inputClassName}
                  required={noTinReasonID === 2}
                />
              );
            }}
          </Form.Item>
        </td>
      );
    default:
      return (
        <td {...props}>
          <InputText name={dataIndex} className={inputClassName} />
        </td>
      );
  }
};

export default EditableCell;
