const {
  REACT_APP_STAGE: STAGE,
  REACT_APP_APPLICATIONS_SECRET_KEY,
  REACT_APP_UPLOADS_BUCKET: UPLOADS_BUCKET,
  REACT_APP_IDENTIFICATION_DOCS_BUCKET: IDENTIFICATION_DOCS_BUCKET,
  REACT_APP_APPLICATIONS_DOCS_BUCKET: APPLICATIONS_DOCS_BUCKET,
  REACT_APP_SOCKET_URL: SOCKET_URL,
} = process.env as Record<string, string>;

export {
  REACT_APP_APPLICATIONS_SECRET_KEY,
  STAGE,
  SOCKET_URL,
  UPLOADS_BUCKET,
  IDENTIFICATION_DOCS_BUCKET,
  APPLICATIONS_DOCS_BUCKET,
};
