import { FC } from "react";
import { Form, Col, Row } from "antd";
import InputNumber from "components/input-number";
import InputText from "components/input-text";
import { TranslationSelect, AsyncSelect } from "components/input-select";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { useSelector } from "hooks";
import { getQueryKey } from "utils";
import { TranslatedRecord } from "types";

const getColProps = (selectedOther: boolean) => {
  if (selectedOther) {
    return {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
    };
  }

  return {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
  };
};

const AccountTransactions: FC<{ required?: boolean; accountID: number }> = ({
  required = true,
  accountID,
}) => {
  const language = useSelector((state) => state.appState.language);
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["sourceOfWealth"]}>
            {({ getFieldValue }) => {
              const selectedOther =
                getFieldValue("sourceOfWealth").includes(14);

              return (
                <Row gutter={ROW_GUTTER}>
                  <Col {...getColProps(selectedOther)}>
                    <AsyncSelect<TranslatedRecord>
                      required={required}
                      mode="multiple"
                      name="sourceOfWealth"
                      label="accountTransactions.sourceOfWealth"
                      apiName="accounts"
                      path={`/${accountID}/source-of-wealth`}
                      queryKey={getQueryKey("sourceOfWealth", {
                        accountID,
                        language,
                      })}
                      getOptionProps={(opt) => ({
                        value: opt.id,
                        children: opt.label,
                      })}
                    />
                  </Col>
                  {selectedOther && (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <InputText
                        required={required}
                        label="common.other"
                        name="sourceOfWealthOther"
                      />
                    </Col>
                  )}
                </Row>
              );
            }}
          </Form.Item>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item dependencies={["sourceOfWealth"]} noStyle>
            {({ getFieldValue }) => {
              const sourceOfWealth = getFieldValue("sourceOfWealth");
              // Business Years
              if (sourceOfWealth.includes(2)) {
                return (
                  <InputNumber
                    style={{ width: "100%" }}
                    required={required}
                    positiveOnly
                    name="businessOperatedYears"
                    label="accountTransactions.businessOperatedYears.label"
                    tooltip="accountTransactions.businessOperatedYears.tooltip"
                  />
                );
              }

              return null;
            }}
          </Form.Item>

          <Form.Item dependencies={["sourceOfFunds"]} noStyle>
            {({ getFieldValue }) => {
              const sourceOfFunds = getFieldValue("sourceOfFunds");
              // Business Income
              if (sourceOfFunds.includes(6)) {
                return (
                  <InputText
                    required={required}
                    name="counterpartiesGoods"
                    label="accountTransactions.counterpartiesGoods.label"
                    tooltip="accountTransactions.counterpartiesGoods.tooltip"
                  />
                );
              }

              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["sourceOfFunds"]}>
            {({ getFieldValue }) => {
              const selectedOther = getFieldValue("sourceOfFunds").includes(12);

              return (
                <Row gutter={ROW_GUTTER}>
                  <Col {...getColProps(selectedOther)}>
                    <AsyncSelect<TranslatedRecord>
                      required={required}
                      name="sourceOfFunds"
                      mode="multiple"
                      label="accountTransactions.sourceOfFunds"
                      apiName="accounts"
                      path={`/${accountID}/source-of-funds`}
                      queryKey={getQueryKey("sourceOfFunds", {
                        accountID,
                        language,
                      })}
                      getOptionProps={(opt) => ({
                        value: opt.id,
                        children: opt.label,
                      })}
                    />
                  </Col>
                  {selectedOther && (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <InputText
                        required={required}
                        label="common.other"
                        name="sourceOfFundsOther"
                      />
                    </Col>
                  )}
                </Row>
              );
            }}
          </Form.Item>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["purposeOfAccount"]}>
            {({ getFieldValue }) => {
              const selectedOther =
                getFieldValue("purposeOfAccount").includes(11);

              return (
                <Row gutter={ROW_GUTTER}>
                  <Col {...getColProps(selectedOther)}>
                    <AsyncSelect<TranslatedRecord>
                      required={required}
                      name="purposeOfAccount"
                      mode="multiple"
                      label="accountTransactions.purposeOfAccount"
                      apiName="accounts"
                      path={`/${accountID}/purpose-of-account`}
                      queryKey={getQueryKey("purposeOfAccount", {
                        accountID,
                        language,
                      })}
                      getOptionProps={(opt) => ({
                        value: opt.id,
                        children: opt.label,
                      })}
                    />
                  </Col>
                  {selectedOther && (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <InputText
                        required={required}
                        label="common.other"
                        name="purposeOfAccountOther"
                      />
                    </Col>
                  )}
                </Row>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <TranslationSelect
            required={required}
            label="accountTransactions.expectedAccountActivity"
            name="expectedTransactionCountId"
            optionName="expectedTransactionsCount"
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <TranslationSelect
            required={required}
            label="accountTransactions.natureOfAccountTransactions"
            name="natureOfTransactionsId"
            optionName="natureTransactions"
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountTransactions;
