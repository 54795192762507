import { useState, FC } from "react";
import { useChildAPI, useForm } from "hooks";
import {
  Form,
  Button,
  Divider,
  Typography,
  notification,
  Modal,
  Card,
  Alert,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InputText from "components/input-text";
import { TranslationSelect, CountriesSelect } from "components/input-select";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { Table } from "./components";
import { sendInteractionEmail } from "requests";
import { POAProps, FormValues } from "./types";
import FileUpload from "../file-upload";
import { Storage } from "utils";
import * as Types from "../../types";

const useStyles = createUseStyles({
  card: {
    "& .ant-card-body": {
      padding: "24px 24px 0",
    },
  },
  createButton: {
    marginTop: 14,
    whiteSpace: "break-spaces",
    height: "auto",
  },
});

const POA: FC<POAProps> = ({ accountName, parentID, parentRoute }) => {
  const classes = useStyles();
  const [state, setState] = useState<{
    savingFile: boolean;
    modalOpen: boolean;
  }>({
    savingFile: false,
    modalOpen: false,
  });
  const [form] = useForm<FormValues>();

  const {
    data = [],
    fetching: fetchingPoa,
    saving: savingPoa,
    save: savePoa,
  } = useChildAPI<
    Types.ProofOfAddress[],
    Omit<FormValues, "file"> & { awsFileID: string }
  >({
    parentID,
    parentRoute,
    childRoute: "poa",
  });

  return (
    <>
      <Modal
        open={state.modalOpen}
        title={<FormattedMessage id="common.poa" />}
        footer={null}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            modalOpen: false,
          }));
        }}
      >
        <Form
          layout="vertical"
          name="poa"
          form={form}
          onValuesChange={(values) => {
            const [name] = Object.keys(values);

            if (name === "categoryId") {
              form.resetFields(["other"]);
            }
          }}
          onFinish={async ({ file, ...values }) => {
            try {
              setState((prevState) => ({
                ...prevState,
                savingFile: true,
              }));

              const [rcFile] = file;

              const { key } = await Storage.uploadData({
                key: `${parentRoute}-${parentID}-${Date.now()}`,
                data: (rcFile?.originFileObj || rcFile) as File,
                bucket: "uploads",
              }).result;

              await savePoa({
                ...values,
                awsFileID: key,
              });
            } catch (e) {
              notification.error({ message: e?.response?.data || e.message });
            }

            sendInteractionEmail({
              account: accountName || "-",
              interaction: "Add identification record",
              data: values,
            });

            setState((prevState) => ({
              ...prevState,
              modalOpen: false,
              savingFile: false,
            }));
            form.resetFields();
          }}
        >
          <Typography.Paragraph>
            <FormattedMessage id="applications.individual.warning" />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <FormattedMessage id="applications.individual.warning2" />
          </Typography.Paragraph>
          <Typography.Title level={5}>
            <FormattedMessage id="applications.individual.selectDocument" />
          </Typography.Title>
          <CountriesSelect
            allowClear
            onClear={() => {
              form.resetFields();
            }}
            restricted
            name="countryId"
            label="applications.individual.issueCountry"
          />
          <Form.Item dependencies={["countryId"]}>
            {({ getFieldValue }) => {
              const countryId = getFieldValue("countryId");

              if (!countryId) {
                return null;
              }

              return (
                <TranslationSelect
                  required
                  preserve={false}
                  label="common.document"
                  name="categoryId"
                  optionName="poaCategories"
                />
              );
            }}
          </Form.Item>
          <Form.Item dependencies={["categoryId"]}>
            {({ getFieldValue }) => {
              const categoryId = getFieldValue("categoryId");

              if (!categoryId) {
                return null;
              }

              const category = data.find(({ id }) => id === categoryId);
              const categoryEntries = Object.entries(category || {});

              return (
                <>
                  <Divider />
                  <Typography.Title level={5}>
                    <FormattedMessage id="applications.individual.completeDetails" />
                  </Typography.Title>
                  {categoryEntries.map((categoryEntry) => {
                    const [key, value] = categoryEntry;
                    const { show, label } = {
                      show: value === 4,
                      label: "common.other",
                    };

                    if (show) {
                      return <InputText key={key} label={label} name="other" />;
                    }

                    return null;
                  })}
                  <FileUpload
                    required
                    name="file"
                    multiple={false}
                    maxCount={1}
                    listType="picture"
                    label={<FormattedMessage id="common.poa" />}
                  />
                  <Button
                    htmlType="submit"
                    loading={savingPoa || state.savingFile}
                    type="primary"
                    className={classes.createButton}
                  >
                    <FormattedMessage id="button.create" />
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
      <Card
        className={classes.card}
        title={<FormattedMessage id="common.poa" />}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                modalOpen: true,
              }));
            }}
          />
        }
      >
        <Table
          loading={fetchingPoa}
          dataSource={data}
          parentID={parentID}
          parentRoute={parentRoute}
        />
        {!fetchingPoa && !data.length && (
          <Alert
            type="warning"
            message={
              <FormattedMessage
                id="proofOfAddress.warning"
                values={{
                  link: (
                    <a href="mailto: info@gat-money.com.">
                      info@gat-money.com.
                    </a>
                  ),
                }}
              />
            }
          />
        )}
      </Card>
    </>
  );
};

export default POA;
