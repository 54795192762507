import { FC } from "react";
import { Alert, FormattedMessage } from "components";
import { Types } from "../../duck";

const WarningMessage: FC<Types.MessageAlertProps> = ({
  account,
  accountType,
  optionalAccountType,
  optionalAccount,
}) => {
  return (
    <Alert
      message={
        <FormattedMessage
          id="applications.common.confirmMessage"
          values={{ accountType }}
        />
      }
      description={
        <FormattedMessage
          id="applications.common.confirmDescription"
          values={{ accountType, accountName: account.account_name }}
        >
          {(text) => (
            <>
              {text}
              {optionalAccount && (
                <>
                  &nbsp;
                  <FormattedMessage
                    id="applications.common.confirmOptionalText"
                    values={{
                      accountType: optionalAccountType,
                      accountName: optionalAccount.account_name,
                    }}
                  />
                </>
              )}
            </>
          )}
        </FormattedMessage>
      }
      type="warning"
      showIcon
      style={{ marginBottom: 24 }}
    />
  );
};

export default WarningMessage;
