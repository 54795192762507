import { InputText } from "components";

const Company = () => {
  return (
    <>
      <InputText required label="common.companyName" name="companyName" />
      <InputText required label="common.companyNumber" name="companyNumber" />
      <InputText
        required
        label="applications.common.contactFirstName"
        name="companyContactFirstName"
      />
      <InputText
        required
        label="applications.common.contactLastName"
        name="companyContactLastName"
      />
    </>
  );
};

export default Company;
