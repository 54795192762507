import { FC, useEffect } from "react";
import { CreateAppButtons, ApplicationsTable } from "components";
import { ChildProps } from "hocs";

const ApplicationList: FC<ChildProps> = ({ getTopics }) => {
  useEffect(() => {
    getTopics({ extra: <CreateAppButtons /> });
  }, [getTopics]);

  return <ApplicationsTable />;
};

export default ApplicationList;
