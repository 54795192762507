import { Types as ExtendedTableTypes } from "components/extended-table";
import ActionsColumn from "../components/actions-column";
import { Taxation } from "types";
import { GetTableColumns } from "./types";

export const getReasonSuffix = (id?: number) => {
  const REASON_SUFFIX = {
    1: "A",
    2: "B",
    3: "C",
  };

  return id && REASON_SUFFIX[id as 1 | 2 | 3];
};

export const getTableColumns: GetTableColumns = ({
  onEditClick,
  countries,
  language,
  noTinReasons,
  isEditing,
  onCancel,
  createMode,
  submitting,
  queryKey,
  parentID,
  parentRoute,
}) => {
  const columns: Array<
    Omit<ExtendedTableTypes.Column<Taxation>, "title"> & {
      editable?: boolean;
      title: string;
    }
  > = [
    {
      ellipsis: true,
      editable: true,
      dataIndex: "countryJurisdiction",
      title: "taxation.table.countryJurisdiction",
      sorter: (a, b) => {
        const aName = countries.find(({ id }) => id === a.id)?.label || "";
        const bName = countries.find(({ id }) => id === b.id)?.label || "";

        if (aName.localeCompare(bName, language)) {
          return -1;
        }

        return 1;
      },
      render: ({ value }) => {
        return countries.find(({ id }) => id === value)?.label;
      },
    },
    {
      ellipsis: true,
      editable: true,
      dataIndex: "tin",
      title: "taxation.table.tin",
      sorter: (a, b) => ((a.tin || "") > (b.tin || "") ? -1 : 1),
    },
    {
      ellipsis: true,
      editable: true,
      dataIndex: "noTinReasonID",
      title: "taxation.table.noTinReason",
      render: ({ value }) => getReasonSuffix(value),
    },
    {
      ellipsis: true,
      editable: true,
      dataIndex: "furtherDetails",
      title: "taxation.table.furtherDetails",
    },
    {
      dataIndex: "id",
      title: "common.actions",
      render: ({ value, record }) => {
        const showEdit = isEditing(value) || createMode;

        return (
          <ActionsColumn
            id={record.id}
            queryKey={queryKey}
            submitting={submitting}
            onCancel={onCancel}
            editMode={showEdit}
            parentID={parentID}
            parentRoute={parentRoute}
            onEdit={() => {
              onEditClick(record);
            }}
          />
        );
      },
    },
  ];

  return columns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        style: { whiteSpace: "no-wrap" },
      };
    }

    return {
      ...col,
      onCell: (record: Taxation) => ({
        editing: isEditing(record.id),
        style: { minWidth: 150 },
        record,
        createMode,
        noTinReasons,
        countries,
        dataIndex: col.dataIndex,
      }),
    };
  });
};
