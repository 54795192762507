import { FC, Dispatch, SetStateAction, ReactNode } from "react";
import { ENDPOINTS } from "consts";
import { Breakpoint, TableColumnType } from "antd";
import { HighlighterProps } from "react-highlight-words";
import { QueryKey } from "@tanstack/react-query";

export type { RouteProps, Params } from "react-router-dom";
export type { FormInstance, TableColumnType } from "antd";
export type { IntlShape } from "react-intl";
export type { QueryKey };
export type BreakpointMap = Record<Breakpoint, boolean>;

export interface InitialData {
  client: User;
  account: Account;
  accounts: Account[];
}

export enum AccountModeEnum {
  live = 1,
  test = 2,
}

export interface VerifyExternal {
  subscriptionActive: boolean;
  accountColor: string;
  accountID: number;
  account_name: string;
  createdAt: Date;
  submittedAt?: Date;
  openedAt?: Date;
  email: string;
  id: number;
  uuid: string;
}

export interface ViewHOCChildProps<TRecord> {
  record?: TRecord;
  queryKey: QueryKey;
  loading: boolean;
}

export type ApiNames = keyof typeof ENDPOINTS;

export type Bucket =
  | "identificationDocuments"
  | "uploads"
  | "applicationDocuments";

export interface AccountSlug {
  id: number;
  slug: string;
}

export interface ApplicationMessage {
  id: number;
  content: string;
  applicationID: number;
  createdBy: "EAFP" | "EAFA";
  createdAt: string;
  awsFileID?: string;
  readAt?: string;
  queueID: string;
  emailNotified: boolean;
}

export interface ApplicationOptional {
  id: number;
  intendedDeposit: number;
  applicationID: number;
}

export interface RcFile extends File {
  uid: string;
}

export interface SelectOption {
  children?: ReactNode;
  value: any;
  removable?: boolean;
  disabled?: boolean;
}
interface CommonArgs<TRecord>
  extends Omit<TableColumnType<TRecord>, "render" | "dataIndex" | "title"> {
  dataIndex: keyof TRecord;
  title: string;
  placeholder?: string;
  render?: (highlighter: FC<HighlighterProps>, record: TRecord) => any;
}

export interface ListHOCInputTypes {
  DATE_RANGE: "DATE_RANGE";
  INPUT: "INPUT";
  SELECT: "SELECT";
}

interface GetColumnSearchArgsCommon<TRecord> extends CommonArgs<TRecord> {
  filterInputType?: keyof Omit<ListHOCInputTypes, "SELECT">;
}

interface GetColumnSearchArgsSelect<TRecord, TOption = any>
  extends CommonArgs<TRecord> {
  filterInputType?: ListHOCInputTypes["SELECT"];
  selectFilterOptions: any[];
  getOptionProps: (option: TOption) => SelectOption;
  filterOption?: (input: string, option: TOption) => boolean;
}

export interface ExtendedTableColumn<TRecord = any>
  extends Omit<TableColumnType<TRecord>, "title" | "dataIndex"> {
  title: string;
  dataIndex?: string;
  editable?: boolean;
  editing?: boolean;
}

export type GetColumnSearchProps<TRecord, TOption = any> = (
  args: XOR<
    GetColumnSearchArgsCommon<TRecord>,
    GetColumnSearchArgsSelect<TRecord, TOption>
  >
) => Omit<TableColumnType<TRecord>, "dataIndex" | "title"> & {
  dataIndex: CommonArgs<TRecord>["dataIndex"];
  title: string;
};

export interface ListHOCChildProps<TRecord> {
  getColumnSearchProps: GetColumnSearchProps<TRecord>;
  entities: TRecord extends Array<any> ? TRecord : TRecord[];
  updateState: (args: { entities: TRecord[]; loading?: boolean }) => void;
  loading: boolean;
}

export interface TranslatedRecord {
  id: number;
  label: string;
}

export interface TranslatedRecords {
  streetTypes: TranslatedRecord[];
  expectedTransactionsCount: TranslatedRecord[];
  natureTransactions: TranslatedRecord[];
  poaCategories: TranslatedRecord[];
  employmentStatuses: Array<TranslatedRecord & { employed: boolean }>;
  noTinReasons: TranslatedRecord[];
  sicDivisions: TranslatedRecord[];
  genders: TranslatedRecord[];
}

export interface TranslatedAction {
  meta: keyof TranslatedRecords;
  data: TranslatedRecord;
}

export type Language = "en" | "zh";

export interface StepButtonsProps<TValues = any> {
  loading?: boolean;
  nextDisabled?: boolean;
  nextVisible?: boolean;
  prevDisabled?: boolean;
  currentStep: number;
  steps: string[];
  wrapperClassName?: string;
  onPrevClick?: () => void;
  onNextClick?: (values?: Partial<TValues>) => void;
}

export type SumSubApplicantStatus = "init" | "pending" | "completed";

export interface SetupIntent {
  id: string;
  status:
    | "requires_payment_method"
    | "requires_confirmation"
    | "requires_action"
    | "processing"
    | "canceled"
    | "succeeded";
  subscriptionID: number;
}

export enum BillingPlanEnum {
  PayG = 1,
  Bronze = 2,
  Silver = 3,
  Gold = 4,
}

export interface Subscription {
  accountID: number;
  billingPlanID: number;
  billingPlan: string;
  ceasedAt: Date | null;
  createdAt: Date;
  id: number;
  paymentIntentID: string;
  stripeID: string;
  clientSecret: string;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  setupIntentStatus?: SetupIntent["status"];
  status:
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid"
    | "paused";
}

export interface VerificationDocV {
  id: number;
  uuid: string;
  frontImgID?: string;
  backImgID?: string;
  applicationID?: number;
  informationRequestID?: number;
  countryId: number;
  createdAt: string;
  submittedAt: string;
  truliooResultJson: string;
  truliooSessionID?: string;
  sumsubApplicantStatus?: SumSubApplicantStatus;
}

export interface System {
  id: number;
  sumSubActive: boolean;
  appVersion: string;
}

export interface Taxation extends WidgetIDs {
  id: number;
  countryJurisdiction: number | null;
  tin: string | null;
  noTinReasonID: number | null;
  noTinReason: string;
  furtherDetails: string | null;
}

export interface Country extends TranslatedRecord {
  id: number;
  label: string;
  full_name: string;
  iso_alpha_2: string;
  iso_alpha_3: string;
  un_code: string;
  deleted: boolean;
  priority: number;
  restrictedCountry: true;
  accountId: number;
  disabledLive: number;
  disabledDemo: number;
}

export interface NotRestrictedCountry
  extends Omit<Country, "restrictedCountry"> {
  restrictedCountry: false;
}

export interface User {
  id: number;
  clientTypeId: number;
  aws_cognito_id: string;
  email: string;
  file_by: string;
  account_id: number;
  lastUsedAccountId: number;
  status_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  tel_work: string;
  tel_home: string;
  tel_mobile: string;
  email_secondary: string;
  street_address_line_1: string;
  street_address_line_2: string;
  street_address_suburb: string;
  street_address_state: string;
  street_address_postcode: string;
  street_address_country: number;
  postal_address_line_1: string;
  postal_address_line_2: string;
  postal_address_suburb: string;
  postal_address_state: string;
  postal_address_postcode: string;
  postal_address_country: string;
  date_of_birth: string;
  place_of_birth: string;
  streetStreetNumber: string;
  streetUnitNumber: string;
  streetStreetName: string;
  streetStreetTypeId: number;
  streetSuburb: string;
  streetState: string;
  streetCountryId: number;
  placeOfBirthCity: string;
  placeOfBirthCountryId: number;
  companyName: string;
  preferredLanguage: Language;
}

export interface AccountAgreement {
  id: number;
  title: string;
  link: string;
  name:
    | "termsOfUse"
    | "financialServicesGuide"
    | "productDisclosureStatement"
    | "privacyPolicy"
    | "termsOfWebsiteUse"
    | "disclaimer"
    | "cookiePolicy";
  language: string;
  accountId: number;
}

export interface AccountSettings {
  knowledge: boolean;
  edd: boolean;
  compliance: boolean;
  taxation: boolean;
  financial: boolean;
}

export interface Account {
  id: number;
  shortName: string;
  typeId: 1 | 2;
  modeID: 1 | 2;
  disabled: boolean;
  disabledLive: boolean;
  disabledDemo: boolean;
  statusID: number;
  enableAccountClose: boolean;
  file_by: string;
  admin_user_id: number;
  slug: string;
  account_name: string;
  favicon: string;
  page_header_logo: string;
  product_name: string;
  website: string;
  desktop_app_name: string;
  mobile_app_name: string;
  desktop_windows_app_name: string;
  desktop_windows_app_link: string;
  desktop_mac_app_name: string;
  desktop_mac_app_link: string;
  ios_app_name: string;
  ios_app_link: string;
  android_app_name: string;
  android_app_link: string;
  web_app_name: string;
  web_app_link: string;
  landing_page_demo_link: string;
  landing_page_live_link: string;
  sender_email: string;
  customer_service_email: string;
  telephone: string;
  street_address_line_1: string;
  street_address_line_2: string;
  street_address_suburb: string;
  street_address_state: string;
  street_address_postcode: string;
  street_address_country: number;
  afsl: string;
  badge_color: string;
  optionalAccountID?: number;
  applicationSettings: AccountSettings;
  informationRequestSettings: Omit<AccountSettings, "knowledge">;
}

export interface Application {
  id: number;
  clientId: number;
  accountID: number;
  account: string;
  modeId: number;
  statusId: number;
  typeId: 1 | 2 | 3;
  createdAt: string;
  submittedAt?: string;
  optionalID: number | null;
  accountSlug: string;
}

export enum ColumnFilterInputTypes {
  DATE_RANGE = "DATE_RANGE",
  INPUT = "INPUT",
}

export interface ApplicationsList extends Application {
  client: string;
  account: string;
  mode: string;
  type: string;
  status: string;
  badgeColor: string;
  subscriptionActive: boolean;
  accountColor: string;
}

export interface IndividualApplication extends Application {
  id: number;
  cfd: boolean;
  forex: boolean;
  stocks: boolean;
  futures: boolean;
  options: boolean;
  quizPassed: boolean;
}

export interface CompanyApplication extends Application {
  id: number;
  companyName: string;
  companyNumber: string;
  incorporationCountryID?: number;
  incorporationDate?: string;
}

export interface TrustApplication extends Application {
  id: number;
  trusteeTypeId: number;
  trustName: string;
  trusteeFirstName: string;
  trusteeLastName: string;
  trusteeCompanyName: string;
  trusteeCompanyNumber: string;
}

export interface Officeholder extends Personal {
  id: number;
  telephone: string;
  createdAt: string;
  applicationID: number;
  typeId: number;
  type: string;
}

export interface Shareholder extends Officeholder {
  beneficiallyHeld: boolean;
  contactFirstName: string;
  companyNumber: string;
  companyName: string;
}

export interface Beneficiary extends Officeholder {
  companyContactFirstName: string;
  companyContactLastName: string;
  telephone: string;
  companyName: string;
  companyNumber: string;
}

export type SetHoldersState = Dispatch<
  SetStateAction<{
    officeholders: Officeholder[];
    shareholders: Shareholder[];
    beneficiaries: Beneficiary[];
  }>
>;

export interface UserGeo {
  IPv4: string;
  city: string;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
  postal: string;
  state: string;
}

export type ShowNotification = (args: {
  type: "success" | "info" | "error" | "warning";
  message: string;
  messageValues?: Record<string, { value: string; translate?: boolean }>;
  description?: string;
  descriptionValues?: Record<string, string>;
}) => void;

export interface ExtendedTableCellProps {
  className: string;
  inputClassName: string;
}

export interface EnhancedDueDiligence extends WidgetIDs {
  id: number;
  description: string;
  awsFileID: string;
  contentType: string;
  url: string;
  createdAt: string;
}

export interface InformationRequest {
  id: number;
  accountId: number;
  uuid: string;
  identificationDocumentTypeId?: number;
  language: Language;
  active: boolean;
  pincode: string;
  email: string;
  submitted: string;
  firstName: string;
  lastName: string;
  activateFinancial: boolean;
  activate_edd_documents: boolean;
  streetAddressId?: number;
  dateOfBirth: string;
  placeOfBirthCity: string;
  placeOfBirthCountryId: number;
  publicOfficeOrPep: boolean;
  familyPublicOfficeOrPep: boolean;
  amlOrCft: boolean;
  adverseInformation: boolean;
  refusedBanking: boolean;
}

interface WidgetIDs {
  applicationID?: number;
  informationRequestID?: number;
  verifyExternalID?: number;
}

export interface Phone extends WidgetIDs {
  id: number;
  home?: string;
  work?: string;
  mobile: string;
}

export interface Personal extends WidgetIDs {
  id: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  placeOfBirthCity?: string;
  placeOfBirthCountryId?: string;
  dateOfBirth?: string;
}

export interface AccountTransaction extends WidgetIDs {
  id: number;
  sourceOfFunds: number[];
  purposeOfAccount: number[];
  sourceOfWealth: number[];
  expectedTransactionCountId: string;
  natureOfTransactionsId: string;
  optionalID?: number;
}

export interface ProofOfAddress extends WidgetIDs {
  id: number;
  clientId: number;
  categoryId: number;
  country: string;
  poaCategory: string;
  countryId: number;
  documentNumber: string;
  awsFileID: string;
  other: string;
  createdAt: string;
  url?: string;
  contentType?: string;
}

export interface Financial extends WidgetIDs {
  id: number;
  employmentStatusID: number;
  occupation?: string;
  employer?: string;
  employmentIndustry?: string;
  annualIncome: number;
  netWorth: number;
  intendedDeposit: number;
  usCitizen: boolean | number;
  usTaxResident: boolean | number;
  sicDivisionID?: number;
  sicGroupID?: number;
}

export enum AccountStatusEnum {
  Incomplete = 1,
  Pending = 2,
  Active = 3,
  Inactive = 4,
}

export interface Knowledge extends WidgetIDs {
  id: number;
  tradedLeveraged: number;
  tradedOptions: number;
  qualification: number;
}

export interface StreetAddress extends WidgetIDs {
  id: number;
  streetTypeId: number;
  countryId: number;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
}

export type CheckboxState = Record<
  AccountAgreement["name"],
  { touched: boolean; checked: boolean }
>;

type Headers = Record<string, string>;

export interface QueryKeys {
  accountSubscription: "accountSubscription";
  application: "application";
  countries: "countries";
  accountAgreements: "accountAgreements";
  applicationOptional: "applicationOptional";
  applicationsList: "applicationsList";
  taxation: "taxation";
  accountTransaction: "accountTransaction";
  sourceOfFunds: "sourceOfFunds";
  sourceOfWealth: "sourceOfWealth";
  purposeOfAccount: "purposeOfAccount";
  officeholders: "officeholders";
  shareholders: "shareholders";
  beneficiaries: "beneficiaries";
  verification: "verification";
  sumSubToken: "sumSubToken";
  verifyExternalList: "verifyExternalList";
  verifyExternalCheck: "verifyExternalCheck";
  verifyExternal: "verifyExternal";
  personal: "personal";
  phone: "phone";
  poa: "poa";
  edd: "edd";
  financial: "financial";
  sicGroups: "sicGroups";
  knowledge: "knowledge";
  compliance: "compliance";
  "street-address": "street-address";
}

export interface Compliance {
  adverseInformation: number;
  amlOrCft: number;
  applicationID: number | null;
  familyPublicOfficeOrPep: number;
  id: number;
  informationRequestID: number | null;
  publicOfficeOrPep: number;
  refusedBanking: number;
}

export interface ApiArgs {
  apiName: ApiNames;
  path?: string;
  options?: {
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;
    body?: Record<string, any>;
  };
}

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
  response: Promise<Response>;
  cancel(abortMessage?: string): void;
}

interface ResponsePayload {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType>;
  text(): Promise<string>;
}

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
  body: ResponsePayload;
  statusCode: number;
  headers: Headers;
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
