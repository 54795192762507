import { FC } from "react";
import { Alert, FormattedMessage } from "components";
import { Types } from "../../duck";

const RegistrationSuccess: FC<Types.MessageAlertProps> = ({
  account,
  optionalAccount,
  optionalAccountType,
}) => {
  return (
    <Alert
      type="success"
      showIcon
      message={<FormattedMessage id="applications.registrationSubmitted" />}
      description={
        <FormattedMessage
          id="applications.registrationSubmittedText"
          values={{
            account: account.account_name,
            link: (
              <a href={`mailto: ${account.customer_service_email}`}>
                {account.customer_service_email}
              </a>
            ),
            optional: optionalAccount && (
              <>
                &nbsp;
                <FormattedMessage
                  id="applications.common.confirmOptionalText"
                  values={{
                    accountType: optionalAccountType,
                    accountName: optionalAccount.account_name,
                  }}
                />
              </>
            ),
          }}
        />
      }
    />
  );
};

export default RegistrationSuccess;
