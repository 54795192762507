import * as React from "react";
import { Form, Radio, RadioGroupProps, FormItemProps } from "antd";
import { FormattedMessage } from "react-intl";
import { INPUT_GROUP_OPTIONS } from "consts";
import { XOR } from "types";

interface InputGroupProps
  extends Omit<RadioGroupProps, "children" | "name" | "options">,
    Omit<FormItemProps, "label"> {
  options?: { label: string; value: boolean | string | number }[];
}

interface WithFormProps extends InputGroupProps {
  label?: string;
  name: string | string[];
}

const InputGroup: React.FC<
  XOR<WithFormProps, InputGroupProps & { withoutWrapper: boolean }>
> = ({
  name,
  label,
  withoutWrapper,
  options = INPUT_GROUP_OPTIONS,
  optionType = "button",
  buttonStyle = "solid",
  className,
  onChange,
  value,
}) => {
  const commonProps = {
    className,
    optionType,
    buttonStyle,
    options: options.map((opt) => ({
      ...opt,
      label: <FormattedMessage id={opt.label} />,
    })),
  };

  if (withoutWrapper) {
    return <Radio.Group {...commonProps} onChange={onChange} value={!!value} />;
  }

  return (
    <Form.Item
      label={label && <FormattedMessage id={label} />}
      name={name}
      getValueProps={(propsValue) => ({
        value:
          typeof propsValue === "number" && propsValue !== 0
            ? propsValue
            : !!propsValue,
      })}
    >
      <Radio.Group {...commonProps} />
    </Form.Item>
  );
};

export default InputGroup;
