import { FC } from "react";
import { RowProps, Tooltip } from "antd";

const TableRow: FC<RowProps & { subscriptionActive: 1 | 0 }> = ({
  subscriptionActive,
  ...props
}) => {
  if (subscriptionActive) {
    return <tr {...props} />;
  }

  return (
    <Tooltip title="No active subscription for the current account">
      <tr {...props} />
    </Tooltip>
  );
};

export default TableRow;
