import * as React from "react";
import { createUseStyles } from "react-jss";
import * as C from "components";
import { useNotification, useDispatch, useSelector, useParams } from "hooks";
import { API } from "utils";
import { Language } from "types";
import { appStateAsyncActions, authAsyncActions } from "configs";
import InformationRequests from "./InformationRequests";

const useStyles = createUseStyles({
  card: { maxWidth: 600, textAlign: "center" },
  form: { height: "100%" },
  row: {
    height: "100%",
    flexDirection: "column",
  },
  spin: {
    height: "100%",
    " .ant-spin-container": "100%",
  },
  description: {
    marginBottom: 14,
  },
});

const PinCode = () => {
  const [state, setState] = React.useState<{
    loading: boolean;
    passed: boolean;
    language: Language | null;
  }>({
    loading: false,
    passed: false,
    language: null,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const showNotification = useNotification();
  const { language, account } = useSelector(({ appState, auth }) => ({
    account: auth.account,
    language: appState.language,
  }));
  const { uuid } = useParams<"uuid">();

  React.useEffect(() => {
    API.get({ apiName: "informationRequests", path: `/${uuid}/language` })
      .then((record: { language: Language; accountId: number }) => {
        dispatch(
          appStateAsyncActions.updateLanguage({
            language: record.language,
          })
        );
        dispatch(authAsyncActions.getAccountById(record.accountId));

        setState((prev) => ({
          ...prev,
          language: record.language,
        }));
      })
      .catch(() => {
        showNotification({
          type: "error",
          message: "messages.fetchFailed",
        });
      });
    // eslint-disable-next-line
  }, []);

  const { language: recordLanguage, passed } = state;

  React.useEffect(() => {
    if (recordLanguage && recordLanguage !== language && !passed) {
      API.patch({
        apiName: "informationRequests",
        path: `/${uuid}`,
        options: {
          body: { language },
        },
      }).then(() => {
        setState((prev) => ({
          ...prev,
          language,
        }));
      });
    }
  }, [language, recordLanguage, uuid, passed]);

  if (state.passed) {
    return <InformationRequests />;
  }

  return (
    <C.Form
      className={classes.form}
      layout="vertical"
      onFinish={async ({ pin }: { pin: string }) => {
        setState((prev) => ({
          ...prev,
          loading: true,
        }));

        try {
          await API.get({
            apiName: "informationRequests",
            path: `/${uuid}/pin`,
            options: {
              headers: { pin },
            },
          });

          setState((prev) => ({
            ...prev,
            passed: true,
          }));
        } catch (e) {
          showNotification({
            type: "error",
            message: "messages.fetchFailed",
          });
        }

        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }}
    >
      <C.Row justify="center" align="middle" className={classes.row}>
        {account && (
          <>
            <C.Typography.Title level={5}>
              {account.account_name}{" "}
              <C.FormattedMessage id="informationRequests.pinTitle" />
            </C.Typography.Title>
            <C.Typography.Text className={classes.description}>
              <C.FormattedMessage id="informationRequests.pinDescription" />
            </C.Typography.Text>
          </>
        )}
        <C.Card
          className={classes.card}
          title={<C.FormattedMessage id="informationRequests.pinPlaceholder" />}
        >
          <C.InputText required name="pin" label="informationRequests.pin" />
          <C.Button type="primary" htmlType="submit" loading={state.loading}>
            <C.FormattedMessage id="button.submit" />
          </C.Button>
        </C.Card>
      </C.Row>
    </C.Form>
  );
};

export default PinCode;
