import * as React from "react";
import * as LC from "./components";
import * as C from "components";
import { authAsyncActions } from "configs";
import { createUseStyles, API, showItem } from "utils";
import { sendInteractionEmail } from "requests";
import { Types, operations } from "./duck";
import { useParams, useDispatch, useNotification, useSelector } from "hooks";

const useStyles = createUseStyles({
  wrapper: {
    padding: "0 24px",
  },
});

const InformationRequests = () => {
  const { uuid } = useParams<"uuid">();
  const classes = useStyles();
  const dispatch = useDispatch();
  const showNotification = useNotification();

  const [state, setState] = React.useState<Types.InformationRequestsState>({
    currentStep: 0,
    record: null,
    loading: false,
    saving: false,
  });

  const { streetTypes, countries, language, account } = useSelector(
    ({ appState, auth }) => ({
      language: appState.language,
      countries: appState.countries,
      streetTypes: appState.streetTypes,
      account: auth.account,
    })
  );

  const { record: informationRequest, currentStep } = state;
  const recordLanguage = informationRequest?.language;

  React.useEffect(() => {
    if (recordLanguage && recordLanguage !== language && uuid) {
      operations.updateLanguage({
        uuid,
        language,
        setState,
        showNotification,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, language, recordLanguage, uuid]);

  React.useEffect(() => {
    const initialize = async () => {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));

      try {
        const record = await API.get({
          apiName: "informationRequests",
          path: `/${uuid}`,
        });

        dispatch(authAsyncActions.getAccountById(record.accountId));

        sendInteractionEmail({
          account: record?.account || "-",
          interaction: "Open information requests page",
          data: { uuid },
        });

        setState((prev) => ({
          ...prev,
          record,
          loading: false,
        }));
      } catch (e) {
        showNotification({
          type: "error",
          message: e.response?.data?.error || e.message,
        });

        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    initialize();
    // eslint-disable-next-line
  }, []);

  const onPrevClick = () => {
    setState((prev) => ({ ...prev, currentStep: prev.currentStep - 1 }));
  };

  if (!informationRequest || !account) {
    return <C.Skeleton loading active />;
  }

  const steps = operations.getSteps(
    informationRequest,
    account.informationRequestSettings
  );

  const stepsCount = steps.length;

  const onNextClick = operations.onNextClick({
    record: informationRequest,
    currentStep,
    setState,
    showNotification,
  });

  // TODO refactor
  const setLogs = operations.setLogs({
    uuid: informationRequest.uuid,
    setState,
    currentStep,
    showNotification,
  });

  const btnProps = {
    steps,
    currentStep: state.currentStep,
    onPrevClick,
    onNextClick,
  };
  const commonProps = {
    setLogs,
    stepsCount,
    loading: state.saving,
    record: informationRequest,
  };

  const confirmComponent = (
    <LC.Confirm
      {...commonProps}
      currentStep={state.currentStep}
      onPrevClick={onPrevClick}
      steps={steps}
      submitRecord={(submittedRecord) => {
        setState((prevState) => ({
          ...prevState,
          currentStep: 0,
          record: submittedRecord,
        }));
      }}
    />
  );

  const stepComponents = informationRequest.submitted
    ? [confirmComponent]
    : [
        <LC.UserDetailsForm
          language={language}
          {...commonProps}
          {...btnProps}
        />,
        <C.Verification
          {...btnProps}
          parentRoute="informationRequests"
          parentID={informationRequest.id}
          first_name={informationRequest.firstName}
          last_name={informationRequest.lastName}
        />,
        <LC.AddressForm
          {...commonProps}
          {...btnProps}
          accountName={informationRequest.account}
          streetTypes={streetTypes}
          countries={countries}
        />,
        ...showItem({
          flag: account.informationRequestSettings.financial,
          item: [
            <C.Financial
              {...commonProps}
              {...btnProps}
              parentID={informationRequest.id}
              parentRoute="informationRequests"
              accountName={account.account_name}
              accountTypeID={account.typeId}
            />,
          ],
        }),
        ...showItem({
          flag: account.informationRequestSettings.taxation,
          item: [
            <C.Taxation
              parentID={informationRequest.id}
              parentRoute="informationRequests"
              {...btnProps}
            />,
          ],
        }),
        <C.AccountTransactions
          {...commonProps}
          {...btnProps}
          accountID={informationRequest.accountId}
          parentID={informationRequest.id}
          parentRoute="informationRequests"
          onNextClick={async (values) => {
            await setLogs(values);

            setState((prev) => ({
              ...prev,
              currentStep: prev.currentStep + 1,
            }));
          }}
        />,
        ...showItem({
          flag: account.informationRequestSettings.compliance,
          item: [
            <C.Compliance
              {...commonProps}
              {...btnProps}
              parentID={informationRequest.id}
              parentRoute="informationRequests"
              onNextClick={async (values) => {
                await setLogs(values);
                onNextClick();
              }}
            />,
          ],
        }),
        ...showItem({
          flag: account.informationRequestSettings.edd,
          item: [
            <C.Edd
              {...commonProps}
              {...btnProps}
              parentID={informationRequest.id}
              parentRoute="informationRequests"
            />,
          ],
        }),
        confirmComponent,
      ];

  return (
    <>
      <C.ExtendedSteps currentStep={currentStep} steps={steps} />
      <div className={classes.wrapper}>{stepComponents[state.currentStep]}</div>
    </>
  );
};

export default InformationRequests;
