import { FC } from "react";
import { COMMON_COL_PROPS, INPUT_GROUP_OPTIONS, ROW_GUTTER } from "consts";
import { Row, Col, Typography, Divider, Form, FormInstance } from "antd";
import { Skeleton } from "./components";
import { FormattedMessage } from "react-intl";
import { TranslationSelect, AsyncSelect } from "../input-select";
import InputSlider from "../input-slider";
import InputGroup from "../input-group";
import InputText from "../input-text";
import StepsButtons from "../steps-buttons";
import { useChildAPI, useForm, useSelector } from "hooks";
import { TranslatedRecord, Financial } from "types";
import { Types, selectors } from "./duck";
import { getQueryKey } from "utils";

const FinancialComponent: FC<Types.FinancialProps> = ({
  onNextClick,
  loading,
  accountName,
  accountTypeID,
  companyOrTrust,
  parentID,
  parentRoute,
  ...btnProps
}) => {
  const [form] = useForm();
  const { employmentStatuses, xxl } = useSelector(({ appState }) => ({
    employmentStatuses: appState.employmentStatuses,
    xxl: appState.breakpoint.xxl,
  }));

  const {
    data: financial,
    fetching: fetchingFinancial,
    saving: savingFinancial,
    save: saveFinancial,
  } = useChildAPI<Financial>({
    parentID,
    parentRoute,
    childRoute: "financial",
  });

  const requiredIfEmployed = ({
    getFieldValue,
  }: Omit<FormInstance, "scrollToField" | "getFieldInstance">) => {
    const employmentStatusID = getFieldValue("employmentStatusID");
    return !!employmentStatuses.find((item) => item.id === employmentStatusID)
      ?.employed;
  };

  if (fetchingFinancial) {
    return <Skeleton />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values) => {
        if (!form.isFieldsTouched()) {
          onNextClick?.();
        } else {
          await saveFinancial(values);

          onNextClick?.();
        }
      }}
      initialValues={
        financial || {
          annualIncome: 0,
          netWorth: 0,
          intendedDeposit: 0,
          usCitizen: false,
          usTaxResident: false,
        }
      }
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5}>
            <FormattedMessage id="financial.longTitle" />
          </Typography.Title>
          <Typography.Paragraph>
            <em>
              <FormattedMessage id="financial.subTitle" />
            </em>
          </Typography.Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          {!companyOrTrust && (
            <>
              <Typography.Title level={5}>
                <FormattedMessage id="financial.employmentHeadings" />
              </Typography.Title>
              <TranslationSelect
                required
                optionName="employmentStatuses"
                name="employmentStatusID"
                label="financial.employmentStatus"
              />
            </>
          )}
          <Form.Item dependencies={["employmentStatusID"]} noStyle>
            {({ getFieldValue }) => {
              const other = getFieldValue("employmentStatusID") === 9;

              if (other) {
                return (
                  <InputText
                    required
                    label="common.other"
                    name="employmentStatusOther"
                  />
                );
              }
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={["employmentStatusID"]}>
            {(formInstance) => {
              const employed = requiredIfEmployed(formInstance);

              return (
                !companyOrTrust && (
                  <>
                    <InputText
                      required={employed}
                      label="financial.occupation"
                      name="occupation"
                    />
                    <InputText
                      required={employed}
                      label="financial.employer"
                      name="employer"
                    />
                  </>
                )
              );
            }}
          </Form.Item>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5}>
            <FormattedMessage id="financial.sic" />
          </Typography.Title>
          <Row gutter={ROW_GUTTER} justify="space-between">
            <Form.Item noStyle dependencies={["employmentStatusID"]}>
              {(formInstance) => {
                const employed = requiredIfEmployed(formInstance);
                const required = companyOrTrust || employed;

                return (
                  <>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <TranslationSelect
                        required={required}
                        label="financial.employmentClassification"
                        name="sicDivisionID"
                        optionName="sicDivisions"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item dependencies={["sicDivisionID"]} noStyle>
                        {({ getFieldValue }) => {
                          const sicDivisionID = getFieldValue("sicDivisionID");

                          return (
                            <AsyncSelect<TranslatedRecord>
                              label="financial.employmentIndustry"
                              name="sicGroupID"
                              apiName="system"
                              path={`/sic-divisions/${sicDivisionID}/groups`}
                              enabled={!!sicDivisionID}
                              queryKey={getQueryKey("sicGroups", sicDivisionID)}
                              required={required}
                              getOptionProps={(option) => ({
                                value: option.id,
                                children: option.label,
                              })}
                            />
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
          {!companyOrTrust && (
            <>
              <InputGroup
                label="financial.usCitizen"
                name="usCitizen"
                options={INPUT_GROUP_OPTIONS}
                required
              />
              <InputGroup
                label="financial.usTaxResident"
                name="usTaxResident"
                options={INPUT_GROUP_OPTIONS}
                required
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col {...selectors.getSliderColProps(xxl)}>
          <InputSlider
            requiredAccordingTo={requiredIfEmployed}
            dependencies={["annualIncome", "employmentStatusID"]}
            label="financial.annualIncome"
            name="annualIncome"
            min={0}
            max={500000}
            step={10000}
            marks={{
              0: "0",
              50000: "50k",
              100000: "100k",
              200000: "200k",
              300000: "300k",
              400000: "400k",
              500000: "500k+",
            }}
          />
          <InputSlider
            required
            label="financial.netWorth"
            name="netWorth"
            min={0}
            max={3000000}
            step={10000}
            marks={{
              0: "0",
              100000: "100k",
              250000: "2500k",
              500000: "500k",
              1000000: "1M",
              1500000: "1.5M",
              2000000: "2M",
              2500000: "2.5M",
              3000000: "3M+",
            }}
          />
          {accountTypeID !== 1 ? null : (
            <InputSlider
              required
              labelValues={{ accountName }}
              label="financial.intendedDeposit"
              name="intendedDeposit"
              min={0}
              max={3000000}
              step={10000}
              marks={{
                0: "0",
                250000: "250K",
                500000: "500K",
                1000000: "1M",
                1500000: "1.5M",
                2000000: "2M",
                2500000: "2.5M",
                3000000: "3M+",
              }}
            />
          )}
        </Col>
      </Row>
      <StepsButtons {...btnProps} loading={loading || savingFinancial} />
    </Form>
  );
};

export default FinancialComponent;
