import { FC, ChangeEvent } from "react";
import { Form, Input, FormItemProps, InputRef } from "antd";
import { FormattedMessage } from "react-intl";
import { useIntl } from "hooks";
import { XOR } from "types";

interface InputTextProps
  extends Pick<FormItemProps, "rules" | "name" | "noStyle"> {
  placeholder?: string;
  tooltip?: string;
  label?: string;
  className?: string;
  required?: boolean;
  autoFocus?: boolean;
  isDuplicate?: boolean;
  preserve?: boolean;
  innerRef?: React.MutableRefObject<InputRef | null>;
}

interface NotFormItemProps extends InputTextProps {
  isFormItem: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputText: FC<XOR<InputTextProps, NotFormItemProps>> = ({
  name,
  placeholder = "",
  rules = [],
  required,
  label,
  innerRef,
  autoFocus,
  isDuplicate,
  className,
  noStyle,
  tooltip,
  isFormItem = true,
  preserve,
  ...props
}) => {
  const intl = useIntl();

  let maxLength;

  if (name === "unitNumber" || name === "streetNumber") {
    maxLength = 16;
  }

  const input = (
    <Input
      {...props}
      autoFocus={autoFocus}
      placeholder={
        placeholder ? intl.formatMessage({ id: placeholder }) : undefined
      }
      autoComplete="new-password"
      maxLength={maxLength}
      ref={innerRef}
    />
  );

  if (!isFormItem) {
    return input;
  }

  return (
    <Form.Item
      label={
        label ? intl.formatMessage({ id: label, defaultMessage: label }) : null
      }
      name={name}
      noStyle={noStyle}
      preserve={preserve}
      className={className}
      tooltip={tooltip && <FormattedMessage id={tooltip} />}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("Already exists")
              : Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      {input}
    </Form.Item>
  );
};
export default InputText;
