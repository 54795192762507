import * as React from "react";
import * as C from "antd";
import { FormattedMessage } from "react-intl";

interface SubmitButtonProps {
  loading?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ loading }) => {
  return (
    <C.Row justify="center">
      <C.Form.Item shouldUpdate>
        {({ isFieldsTouched }) => (
          <C.Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={!isFieldsTouched}
          >
            <FormattedMessage id="button.submit" />
          </C.Button>
        )}
      </C.Form.Item>
    </C.Row>
  );
};

export default SubmitButton;
