// Create the middleware instance and methods
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { authActions, authAsyncActions } from "../slices";
import { authEffect } from "./auth";
import loginEffect from "./login";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: authActions.authenticateStart,
  effect: (action, listenerAPI) => {
    authEffect(listenerAPI);
  },
});

listenerMiddleware.startListening({
  actionCreator: authAsyncActions.loginStart,
  effect: (action, listenerAPI) => {
    loginEffect(action, listenerAPI);
  },
});

export default listenerMiddleware.middleware;
