import { API } from "utils";

const sendInteractionEmail = async (body: {
  account?: string | null;
  interaction: string;
  data: Record<string, any>;
}) => {
  try {
    // console.log(body, API)
    await API.post({
      apiName: "email",
      path: "/interaction-open",
      options: {
        body: {
          ...body,
          account: body.account || "-",
          api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
        },
      },
    });
  } catch (e) {
    // silent
  }
};

export default sendInteractionEmail;
