import { FC, useEffect } from "react";
import { CreateAppButtons, ApplicationsTable, Clipboard } from "components";
import { ChildProps } from "hocs";
import { useSelector } from "hooks";
import { Account } from "types";

const Dashboard: FC<ChildProps> = ({ getTopics }) => {
  const { accountName, accountPhone, accountEmail } = useSelector(
    ({ auth }) => {
      const account = auth.account as Account;

      return {
        accountName: account.account_name,
        accountEmail: account.customer_service_email,
        accountPhone: account.telephone,
      };
    }
  );

  useEffect(() => {
    getTopics({
      extra: <CreateAppButtons />,
      descriptionValues: {
        email: <Clipboard>{accountEmail}</Clipboard>,
        phone: <Clipboard>{accountPhone}</Clipboard>,
        account: accountName,
      },
    });
  }, [getTopics, accountEmail, accountPhone, accountName]);

  return <ApplicationsTable />;
};

export default Dashboard;
