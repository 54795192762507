import { FC } from "react";
import ExtendedTable from "components/extended-table";
import { Space, Image, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useSelector } from "hooks";
import { getQueryKey } from "utils";
import { ProofOfAddress } from "types";
import { ActionsColumn } from "./components";
import { CommonProps } from "../../types";

interface TableProps extends CommonProps {
  dataSource: ProofOfAddress[];
  loading: boolean;
}

const Table: FC<TableProps> = ({
  dataSource,
  parentID,
  loading,
  parentRoute,
}) => {
  const languageSwitching = useSelector(
    (state) => state.appState.languageSwitching
  );

  return (
    <ExtendedTable<ProofOfAddress>
      size="small"
      loading={languageSwitching || loading}
      dataSource={dataSource}
      columns={[
        {
          title: "common.country",
          dataIndex: "country",
        },
        {
          title: "proofOfAddress.categoryHeader",
          dataIndex: "poaCategory",
        },
        {
          title: "proofOfAddress.fileHeader",
          dataIndex: "url",
          render: ({ value: src, record }) => {
            if (src) {
              const imgFormat = ["jpg", "jpeg", "png"];
              const isIMG = imgFormat.find((format) =>
                record.contentType?.includes(format)
              );

              return (
                <Space>
                  {isIMG ? (
                    <Image src={src} width={50} height={50} />
                  ) : (
                    <Button
                      type="link"
                      href={src}
                      target="_blank"
                      icon={<EyeOutlined />}
                    />
                  )}
                </Space>
              );
            }
          },
        },
        {
          title: "common.actions",
          dataIndex: "url",
          render: ({ record }) => {
            const queryKey = getQueryKey("poa", {
              parentRoute,
              parentID,
            }) as (string | Record<string, any>)[];

            return (
              <ActionsColumn
                childID={record.id}
                parentID={parentID}
                parentRoute={parentRoute}
                queryKey={queryKey}
              />
            );
          },
        },
      ]}
    />
  );
};

export default Table;
