import { ListenerEffectAPI, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "utils";
import { notification } from "antd";
import { authActions, authAsyncActions } from "../slices";
import { operations } from "./duck";

export default async (
  action: PayloadAction<{ email: string; password: string }>,
  listenerApi: ListenerEffectAPI<any, any>
) => {
  // Can cancel other running instances
  listenerApi.cancelActiveListeners();

  try {
    const { payload } = action as any;

    const { nextStep } = await Auth.signIn({
      username: payload.email,
      password: payload.password,
    });

    if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
      listenerApi.dispatch(
        authAsyncActions.loginError({
          confirmSignup: true,
        })
      );

      return;
    }

    const { userId: cognitoID } = await Auth.getCurrentUser();

    const { accountSlugs } = listenerApi.getState().appState;

    const data = await operations.getInitialData({
      cognitoID,
      accountSlugs,
    });

    listenerApi.dispatch(authActions.loginSuccess(data));
  } catch (e: any) {
    listenerApi.dispatch(authAsyncActions.loginError());

    notification.error({
      message: e.message,
    });
  }
};
