import { FC, useEffect, useState } from "react";
import { Storage, isImage } from "utils";
import { Button, Image, Spin } from "antd";
import { createUseStyles } from "react-jss";
import { FileOutlined } from "@ant-design/icons";
import { XOR, Bucket } from "types";

interface FileIDProps {
  awsFileID?: string;
  bucket: Bucket;
}

interface FileProps {
  file: File;
  bucket: Bucket;
}

type FileRendererProps = XOR<FileIDProps, FileProps>;

const useStyles = createUseStyles({
  button: {
    padding: 0,
  },
});

const FileRenderer: FC<FileRendererProps> = ({
  awsFileID,
  bucket,
  ...props
}) => {
  const [state, setState] = useState<{
    file: File | null;
    loading: boolean;
  }>({
    file: null,
    loading: false,
  });
  const classes = useStyles();

  useEffect(() => {
    if (!awsFileID || props.file) {
      return;
    }

    const fn = async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
        const { url } = await Storage.getURL({
          bucket,
          key: awsFileID,
        });

        const response = await fetch(url.toString());
        const blob = await response.blob();

        const file = new File([blob], awsFileID, {
          type: blob.type,
        });

        setState((prevState) => ({
          ...prevState,
          loading: false,
          file,
        }));
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    fn();
  }, [awsFileID, bucket, props.file]);

  if (state.loading) {
    return <Spin size="small" />;
  }

  const file = props.file || state.file;

  if (!file) {
    return null;
  }

  if (isImage(file)) {
    return <Image height={100} width={70} src={URL.createObjectURL(file)} />;
  }

  return (
    <Button
      className={classes.button}
      href={URL.createObjectURL(file)}
      icon={<FileOutlined />}
      type="link"
      target="_blank"
    >
      {file.name}
    </Button>
  );
};

export default FileRenderer;
