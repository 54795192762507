import { REACT_APP_APPLICATIONS_SECRET_KEY } from "consts";

const transformAppID = (id: number | string | undefined) => {
  if (!id) {
    return null;
  }

  try {
    if (typeof id === "string") {
      return parseInt(atob(id));
    }

    return btoa(id.toString() + REACT_APP_APPLICATIONS_SECRET_KEY);
  } catch {
    return null;
  }
};

export default transformAppID;
