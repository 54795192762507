import { useState, useMemo, FC } from "react";
import * as LC from "./components";
import {
  Form,
  Row,
  Col,
  ExtendedDescriptions,
  Typography,
  StepsButtons,
  FormattedMessage,
} from "components";
import { useSelector, useAgreements, useQueryData, useMutation } from "hooks";
import { DATE_FORMAT, COMMON_COL_PROPS } from "consts";
import dayjs from "dayjs";
import { sendInteractionEmail } from "requests";
import * as Types from "types";
import { constants } from "./duck";
import { getQueryKey } from "utils";

interface ConfirmProps extends Types.StepButtonsProps {
  account: Types.Account;
  app: Types.Application;
  appQueryKey: Types.QueryKey;
  currentClientRecord: Types.User;
  columns?: {
    label: string;
    value: string;
  }[];
}

const Confirm: FC<ConfirmProps> = ({
  account,
  appQueryKey,
  currentClientRecord,
  app,
  onNextClick,
  columns = [],
  ...btnProps
}) => {
  const [state, setState] = useState<{
    modalVisible: boolean;
    submitted: boolean;
  }>({
    modalVisible: false,
    submitted: false,
  });
  const accounts = useSelector((rootState) => rootState.appState.accounts);

  const phone = useQueryData<Types.Phone>(
    getQueryKey("phone", {
      parentRoute: "applications",
      parentID: app.id,
    })
  );

  const { mutate: submit, isPending } = useMutation<
    Types.Application,
    Record<string, boolean>
  >({
    method: "patch",
    apiName: "applications",
    path: `/${app.id}/submit`,
    invalidateQueries: [appQueryKey],
    onSuccess: () => {
      setState((prevState) => ({
        ...prevState,
        submitted: true,
      }));
    },
    onError: () => {
      // we want to show success in any case
      setState((prevState) => ({
        ...prevState,
        submitted: true,
      }));
    },
  });

  const { items, validateCheckboxes, getCheckboxValues } = useAgreements(
    app.accountID
  );
  const optionalCheckboxes = useAgreements(
    app.optionalID && account.optionalAccountID
  );

  const individualApp = app.typeId === 1;

  const submitApp = async () => {
    submit(getCheckboxValues());
  };

  const submissionDate = useMemo(
    () => ({
      local: dayjs().format(DATE_FORMAT),
      utc: dayjs.utc().format(DATE_FORMAT),
    }),
    []
  );

  const onFormFinish = () => {
    const error = validateCheckboxes();

    if (individualApp) {
      const optionalValid = app.optionalID
        ? !optionalCheckboxes.validateCheckboxes()
        : true;

      if (!error && optionalValid) {
        setState((prev) => ({ ...prev, modalVisible: true }));
      }
    } else if (!error) {
      submitApp();
    }
  };

  const optionalAccount = app.optionalID
    ? accounts.find(
        (accountItem) => accountItem.id === account.optionalAccountID
      )
    : undefined;

  const alertProps = {
    account,
    optionalAccount,
    accountType: (
      <FormattedMessage
        id={constants.ACCOUNT_TYPE_TRANSLATION_KEYS[account.typeId]}
      />
    ),
    optionalAccountType: optionalAccount && (
      <FormattedMessage
        id={constants.ACCOUNT_TYPE_TRANSLATION_KEYS[optionalAccount.typeId]}
      />
    ),
  };

  if (state.submitted) {
    return <LC.RegistrationSuccess {...alertProps} />;
  }

  return (
    <>
      <LC.WarningMessage {...alertProps} />
      <Form onFinish={onFormFinish}>
        <Row gutter={[24, 14]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <ExtendedDescriptions
              column={2}
              items={[
                ...columns,
                {
                  label: "common.first_name",
                  value: currentClientRecord.first_name,
                },
                {
                  label: "common.last_name",
                  value: currentClientRecord.last_name,
                },
                {
                  label: "common.emailAddress",
                  value: currentClientRecord.email,
                },
                {
                  label: "phone.mobile",
                  value: phone?.mobile,
                },
              ]}
            />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <Typography.Title level={5}>
              {account.account_name}
            </Typography.Title>
            {items}
            {app.optionalID && !!optionalCheckboxes.items.length && (
              <>
                <Typography.Title level={5}>
                  {
                    accounts.find(
                      (item) => item.id === account.optionalAccountID
                    )?.account_name
                  }
                </Typography.Title>
                {optionalCheckboxes.items}
              </>
            )}
          </Col>
        </Row>
        <StepsButtons
          {...btnProps}
          nextDisabled={
            !items.length ||
            (optionalAccount && !optionalCheckboxes.items.length)
          }
        />
      </Form>
      {individualApp && (
        <LC.Modal
          loading={isPending}
          submissionDate={submissionDate}
          open={state.modalVisible}
          signerFullName={`${currentClientRecord.last_name}, ${currentClientRecord.first_name}`}
          onCancel={() =>
            setState((prev) => ({ ...prev, modalVisible: false }))
          }
          onOk={async () => {
            const { first_name, last_name } = currentClientRecord;

            sendInteractionEmail({
              account: account.file_by,
              interaction: "W-8BEN",
              data: {
                first_name,
                last_name,
                utc: submissionDate.utc,
              },
            });

            submitApp();
          }}
        />
      )}
    </>
  );
};

export default Confirm;
