import { FC, useState } from "react";
import { Form, Row, Col } from "antd";
import InputText from "components/input-text";
import { CountriesSelect } from "components/input-select";
import StepsButtons from "components/steps-buttons";
import ManualUpload from "../manual-upload";
import { Country, VerificationDocV } from "types";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { Types } from "../../duck";

interface RawFormProps
  extends Omit<Types.VerificationProps, "showDescription">,
    Types.UpdateMutationProps {
  countries: Country[];
  record: VerificationDocV;
  countriesLoading: boolean;
}

const RawForm: FC<RawFormProps> = ({
  countries,
  countriesLoading,
  parentRoute,
  parentID,
  record,
  onNextClick,
  first_name,
  last_name,
  updatingVerification,
  updateVerification,
  ...props
}) => {
  const [form] = Form.useForm();
  const [state, setState] = useState<{
    startImageCapture: boolean;
  }>({
    startImageCapture: false,
  });

  if (state.startImageCapture) {
    return (
      <ManualUpload
        {...props}
        updatingVerification={updatingVerification}
        updateVerification={updateVerification}
        parentRoute={parentRoute}
        parentID={parentID}
        record={record}
        onNextClick={onNextClick}
      />
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        first_name,
        last_name,
        countryId: record.countryId,
      }}
      onFinish={async ({ countryId }) => {
        if (!form.isFieldsTouched()) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            startImageCapture: true,
          }));
          return;
        }

        await updateVerification(
          { countryId },
          {
            onSuccess: () => {
              setState((prevState) => ({
                ...prevState,
                startImageCapture: true,
              }));
            },
          }
        );
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="first_name" label="common.first_name" />
          <InputText required name="last_name" label="common.last_name" />
          <CountriesSelect
            required
            restricted
            name="countryId"
            label="verifications.country"
            disabledProp="disabledLive"
          />
        </Col>
      </Row>
      <StepsButtons {...props} loading={updatingVerification} />
    </Form>
  );
};

export default RawForm;
