import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { asyncActions, Types } from "./duck";
import { actions as authActions } from "../auth";
import { Language, BreakpointMap } from "types";

const initialState: Types.State = {
  loading: false,
  accountSlugsLoading: false,
  languageSwitching: false,

  notRestrictedCountries: [],
  language: (localStorage.getItem("language") as Language | null) || "en",
  userGeo: null,

  accountSlugs: [],
  accounts: [],
  countries: [],
  streetTypes: [],
  expectedTransactionsCount: [],
  natureTransactions: [],
  poaCategories: [],
  employmentStatuses: [],
  noTinReasons: [],
  sicDivisions: [],
  genders: [],

  appVersion: null,
  sumSubActive: false,

  breakpoint: {
    mobile: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  },
};

const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setBreakpoints: (state, action: PayloadAction<Partial<BreakpointMap>>) => {
      state.breakpoint = {
        ...state.breakpoint,
        ...action.payload,
        mobile:
          (action.payload.sm && !action.payload.md) || !!action.payload.xs,
      };
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncActions.getUserGeo.fulfilled, (state, { payload }) => {
      state.userGeo = payload;
    });

    builder.addCase(asyncActions.getAccountSlugs.pending, (state) => {
      state.accountSlugsLoading = true;
    });
    builder.addCase(
      asyncActions.getAccountSlugs.fulfilled,
      (state, { payload }) => {
        state.accountSlugs = payload;
        state.accountSlugsLoading = false;
      }
    );
    builder.addCase(asyncActions.getAccountSlugs.rejected, (state) => {
      state.accountSlugsLoading = false;
    });

    builder.addCase(
      asyncActions.getCountries.fulfilled,
      (state, { payload }) => {
        state.countries = payload.countries;
        state.notRestrictedCountries = payload.notRestrictedCountries;
        state.languageSwitching = false;
      }
    );

    builder.addCase(
      asyncActions.getTranslatedData.fulfilled,
      (state, { payload }) => {
        state.languageSwitching = false;

        state.appVersion = payload.appVersion;
        state.sumSubActive = payload.sumSubActive;

        payload.translatedData.forEach(({ data, meta }) => {
          // eslint-disable-next-line
          // @ts-ignore
          state[meta] = data;
        });
      }
    );

    builder.addCase(asyncActions.updateLanguage.pending, (state, { meta }) => {
      state.languageSwitching = true;

      state.language = meta.arg.language;
    });

    builder.addCase(
      asyncActions.updateLanguage.fulfilled,
      (state, { payload }) => {
        state.languageSwitching = false;

        state.language = payload;
      }
    );

    const languagePendingMatcher = isAnyOf(
      asyncActions.getTranslatedData.pending,
      asyncActions.getCountries.pending
    );
    const languageRejectedMatcher = isAnyOf(
      asyncActions.updateLanguage.rejected,
      asyncActions.getTranslatedData.rejected,
      asyncActions.getCountries.rejected
    );

    builder.addMatcher(languagePendingMatcher, (state) => {
      state.languageSwitching = true;
    });
    builder.addMatcher(languageRejectedMatcher, (state) => {
      state.languageSwitching = false;
    });

    const matcher = isAnyOf(
      authActions.authenticateSuccess,
      authActions.loginSuccess
    );

    builder.addMatcher(matcher, (state, { payload }) => {
      state.accounts = payload.accounts;
      state.language = payload.client.preferredLanguage;
    });
  },
});

export const { reducer, actions } = appState;
