import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { createUseStyles } from "react-jss";

interface PreLoaderProps {
  size?: "small" | "large";
  className?: string;
}

const useStyles = createUseStyles({
  spin: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const PreLoader: FC<PreLoaderProps> = ({ className, size = "large" }) => {
  const classes = useStyles();

  return (
    <Spin
      indicator={<LoadingOutlined />}
      className={classNames(classes.spin, className)}
      size={size}
      spinning
    />
  );
};

export default PreLoader;
