import { geekblue, green, purple } from "@ant-design/colors";
import { ApplicationTag } from "./types";

export const STATUS: ApplicationTag = {
  1: {
    color: "volcano",
    text: "common.draft",
  },
  2: {
    color: "purple",
    text: "common.submitted",
  },
};

export const MODE: ApplicationTag = {
  1: {
    color: "volcano",
    text: "applications.demo.accountName",
  },
  2: {
    color: "geekblue",
    text: "applications.live",
  },
};

export const TYPE: ApplicationTag = {
  1: {
    color: geekblue[4],
    text: "common.individual",
  },
  2: {
    color: purple[4],
    text: "common.company",
  },
  3: {
    color: green[4],
    text: "common.trust",
  },
};
