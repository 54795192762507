import { useSelector } from "./useReactRedux";
import { getAccountSlug } from "utils";

const useGetAccountSlug = () => {
  const accountSlugs = useSelector((state) => state.appState.accountSlugs);

  return getAccountSlug(accountSlugs);
};

export default useGetAccountSlug;
