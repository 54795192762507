import {
  Button,
  Divider,
  Typography,
  Row,
  Col,
  Skeleton,
  FormattedMessage,
} from "components";
import { createUseStyles } from "utils";
import { useNavigate, useSelector } from "hooks";
import { routes } from "configs";

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  root: {
    padding: 0,
    background: "none",
    minHeight: 360,
  },
  title: {
    "&.ant-typography": {
      fontStyle: "bold",
      textAlign: "center",
      "@media (max-width: 768px)": {
        fontSize: 24,
      },
    },
  },
  selection_buttons: {
    maxWidth: 300,
    fontWeight: 600,
  },
  selection_buttons_text: { fontSize: "1.1em" },
});

const ChoseApp = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const account = useSelector((state) => state.auth.account);

  const onBtnClick = (typeId: 1 | 2 | 3) => () => {
    if (!account) {
      return;
    }

    navigate(
      routes.openApplication({
        accountSlug: account.slug,
        typeId,
      })
    );
  };

  return (
    <Skeleton loading={!account} active>
      <div className={classes.root}>
        <Title className={classes.title}>{account?.account_name}</Title>
        <Divider />
        <Row justify="space-around">
          <Col xs={24} sm={12} md={9}>
            <div style={{ textAlign: "center" }}>
              <Text>
                <FormattedMessage
                  id="applications.choose.title"
                  values={{ accountName: account?.account_name }}
                />
                .
              </Text>
              <Divider />
              {account?.disabledLive ? (
                <FormattedMessage id="applications.choose.disabled" />
              ) : (
                <>
                  <Button
                    className={classes.selection_buttons}
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={onBtnClick(1)}
                  >
                    <span className={classes.selection_buttons_text}>
                      <FormattedMessage id="applications.choose.individual" />
                    </span>
                  </Button>
                  <Divider />
                  <Button
                    className={classes.selection_buttons}
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={onBtnClick(2)}
                  >
                    <span className={classes.selection_buttons_text}>
                      <FormattedMessage id="applications.choose.company" />
                    </span>
                  </Button>
                  <Divider />
                  <Button
                    className={classes.selection_buttons}
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={onBtnClick(3)}
                  >
                    <span className={classes.selection_buttons_text}>
                      <FormattedMessage id="applications.choose.trust" />
                    </span>
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Skeleton>
  );
};

export default ChoseApp;
