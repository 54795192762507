import { FC, useState } from "react";
import {
  ExtendedSteps,
  Alert,
  Verification,
  FormattedMessage,
} from "components";
import * as LC from "./components";
import { viewHOC } from "hocs";
import { useQueryData } from "hooks";
import { getQueryKey } from "utils";
import { routes } from "configs";
import * as Types from "types";

const StepsForm: FC<Types.ViewHOCChildProps<Types.VerifyExternal>> = ({
  record,
  loading,
  queryKey,
}) => {
  const [state, setState] = useState<{
    currentStep: number;
  }>({
    currentStep: 0,
  });

  const personal = useQueryData<Types.Personal>(
    getQueryKey("personal", {
      parentRoute: "verifyExternal",
      parentID: record?.uuid as string,
    })
  );

  const onNextClick = () => {
    setState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep + 1,
    }));
  };
  const onPrevClick = () => {
    setState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep - 1,
    }));
  };

  const steps = ["common.personal", "verifications.title", "common.confirm"];

  const commonProps = {
    steps,
    onNextClick,
    onPrevClick,
    currentStep: state.currentStep,
    record,
  };

  if (record?.submittedAt) {
    return (
      <Alert
        type="success"
        message={<FormattedMessage id="common.submitted" />}
        showIcon
      />
    );
  }

  const stepComponents = [
    <LC.PersonalDetails parentUUID={record?.uuid} {...commonProps} />,
    <Verification
      {...commonProps}
      parentRoute="verifyExternal"
      parentID={record?.uuid}
      first_name={personal?.firstName || "-"}
      last_name={personal?.lastName || "-"}
    />,
    <LC.Confirm {...commonProps} queryKey={queryKey} />,
  ];

  return (
    <>
      <ExtendedSteps
        loading={loading}
        currentStep={state.currentStep}
        steps={steps}
      />
      <div style={{ padding: "0 24px" }}>
        {stepComponents[state.currentStep]}
      </div>
    </>
  );
};

export default viewHOC({
  Component: StepsForm,
  apiName: "verifyExternal",
  pathFn: ({ params }) => `/${params.uuid}`,
  queryKeyFn: ({ params }) => getQueryKey("verifyExternal", params.uuid),
  onError: ({ navigate }) => {
    navigate(routes.verifyExternal.list(), { replace: true });
  },
});
