import { FC } from "react";
import { StepsButtons, Form } from "components";
import { useMutation, useSelector } from "hooks";
import { VerifyExternalChildProps } from "../../types";
import { QueryKey } from "types";
import { getQueryKey } from "utils";

const Confirm: FC<VerifyExternalChildProps & { queryKey: QueryKey }> = ({
  currentStep,
  steps,
  onPrevClick,
  record,
  queryKey,
}) => {
  const clientID = useSelector(
    (state) => state.auth.currentClientRecord?.id as number
  );
  const { mutate, isPending } = useMutation({
    apiName: "verifyExternal",
    path: `/${record?.uuid}/submit`,
    method: "post",
    invalidateQueries: [queryKey, getQueryKey("verifyExternalList", clientID)],
  });

  return (
    <Form layout="vertical" onFinish={() => mutate()}>
      TBD
      <StepsButtons
        currentStep={currentStep}
        onPrevClick={onPrevClick}
        steps={steps}
        loading={isPending}
      />
    </Form>
  );
};

export default Confirm;
