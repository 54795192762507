import { useState } from "react";
import { Auth } from "utils";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { authAsyncActions } from "configs";
import { Row, Form, Card, Button } from "antd";
import InputEmail from "components/input-email";
import InputPassword from "components/input-password";
import VerifyPassword from "components/verifiy-password";
import { useSelector, useDispatch, useNotification } from "hooks";
import { ResetPasswordModal } from "./components";

const useStyles = createUseStyles({
  fullWidth: { width: "100%" },
  space: { justifyContent: "space-between" },
  btnSubmit: { marginBottom: 24 },
  card: { width: 370 },
  form: { marginTop: 20 },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
});

const LoginPageComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [form] = Form.useForm<{ email: string; password: string }>();
  const [state, setState] = useState({
    modalOpen: false,
  });
  const { loading, confirmSignup } = useSelector((rootState) => ({
    loading: rootState.auth.loading,
    confirmSignup: rootState.auth.confirmSignup,
  }));

  const onLogin = async (values: { email: string; password: string }) => {
    dispatch(authAsyncActions.loginStart(values));
  };

  if (confirmSignup) {
    const values = form.getFieldsValue(["email", "password"]);

    return <VerifyPassword {...values} />;
  }

  const onForgotPasswordClick = async () => {
    try {
      form.setFields([
        {
          name: "email",
          errors: [],
          validated: false,
        },
      ]);

      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });

      await form.validateFields(["email"]);

      const { nextStep } = await Auth.resetPassword({
        username: form.getFieldValue("email"),
      });

      if (nextStep.resetPasswordStep) {
        showNotification({
          type: "info",
          message: "login.checkEmail",
          messageValues: {
            email: {
              translate: false,
              value: nextStep.codeDeliveryDetails.deliveryMedium as string,
            },
          },
        });
      }
      setState((prevState) => ({
        ...prevState,
        modalOpen: true,
      }));
    } catch (e) {
      // validation error
      if (e.values) {
        return;
      }

      showNotification({
        type: "error",
        message: "messages.requestFailed",
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <ResetPasswordModal
        loginForm={form}
        open={state.modalOpen}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            modalOpen: false,
          }));
        }}
      />
      <Row justify="center">
        <Card
          title={<FormattedMessage id="login.title" />}
          className={classes.card}
        >
          <Form
            form={form}
            onFinish={onLogin}
            className={classes.form}
            layout="vertical"
          >
            <InputEmail required label="common.emailAddress" />
            <InputPassword
              required
              validate={false}
              name="password"
              label="common.password"
            />
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              className={`${classes.fullWidth} ${classes.btnSubmit}`}
            >
              <FormattedMessage id="button.login" />
            </Button>
            <Button
              htmlType="button"
              onClick={onForgotPasswordClick}
              className={classes.fullWidth}
            >
              <FormattedMessage id="login.forgotPassword" />
            </Button>
          </Form>
        </Card>
      </Row>
    </div>
  );
};

export default LoginPageComponent;
