import * as React from "react";
import * as C from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { useChildAPI, useForm } from "hooks";
import * as Types from "types";

export interface PersonalDetailsProps
  extends Types.StepButtonsProps<Types.IndividualApplication> {
  notRestrictedCountries: Types.NotRestrictedCountry[];
  appQueryKey: string[];
  app: Types.IndividualApplication;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  app,
  notRestrictedCountries,
  onNextClick,
  appQueryKey,
  ...btnProps
}) => {
  const [form] = useForm();

  const {
    data: phone,
    fetching: fetchingPhone,
    saving: savingPhone,
    save: savePhone,
  } = useChildAPI<Types.Phone>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "phone",
  });
  const {
    data: personal,
    fetching: fetchingPersonal,
    saving: savingPersonal,
    save: savePersonal,
  } = useChildAPI<Types.Personal>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "personal",
  });

  if (fetchingPersonal || fetchingPhone) {
    return <C.Skeleton active loading />;
  }

  return (
    <C.Form
      form={form}
      layout="vertical"
      initialValues={{
        ...(personal || {}),
        ...(phone || {}),
      }}
      onFinish={async (values) => {
        if (form.isFieldsTouched()) {
          await Promise.all([savePhone(values), savePersonal(values)]);
        }

        onNextClick?.();
      }}
    >
      <C.Row gutter={ROW_GUTTER}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Phone />
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Personal />
        </C.Col>
      </C.Row>
      <C.StepsButtons {...btnProps} loading={savingPersonal || savingPhone} />
    </C.Form>
  );
};

export default PersonalDetails;
