import { FC } from "react";
import { ApplicationOptional, Knowledge as KnowledgeInterface } from "types";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { useForm, useSelector, useQuery, useMutation } from "hooks";
import { Form, Row, Col, InputSlider, Knowledge, Skeleton } from "components";
import { getQueryKey } from "utils";
import { CommonProps } from "../../types";

interface GatMoneyProps extends CommonProps {
  accountName: string;
}

interface OptionalRecord
  extends ApplicationOptional,
    Omit<KnowledgeInterface, "id" | "applicationID"> {
  optionalID: number;
  knowledgeID: number;
}

const GatTrader: FC<GatMoneyProps> = ({
  accountName,
  application: { id: appID, optionalID },
  addAccount,
  onSubmit,
  children,
}) => {
  const [form] = useForm();
  const xxl = useSelector((state) => state.appState.breakpoint.xxl);

  const queryKey = getQueryKey("applicationOptional", appID, optionalID);

  const { data, isLoading } = useQuery<OptionalRecord>({
    apiName: "applications",
    path: `/${appID}/optional/${optionalID}`,
    enabled: !!optionalID,
    queryKey,
  });

  const { mutate, isPending, queryClient } = useMutation<OptionalRecord>({
    apiName: "applications",
    ...(data
      ? {
          method: "patch",
          path: `/${appID}/optional/${data.optionalID}`,
        }
      : {
          method: "post",
          path: `/${appID}/optional`,
        }),
    onSuccess: (newData) => {
      const queryKeyWithCreatedID = getQueryKey(
        "applicationOptional",
        appID,
        newData.optionalID
      );

      // set data into cache to prevent fetching it from useQuery (check "enabled" above)
      queryClient.setQueryData(queryKeyWithCreatedID, newData);

      onSubmit(newData.optionalID);
    },
  });

  if (isLoading) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      form={form}
      disabled={!addAccount}
      preserve={false}
      layout="vertical"
      initialValues={
        data || {
          intendedDeposit: 0,
          tradedLeveraged: false,
          tradedOptions: false,
          qualification: false,
        }
      }
      onFinish={async (values) => {
        if (!form.isFieldsTouched()) {
          onSubmit();
        } else if (!addAccount) {
          queryClient.setQueryData(queryKey, null);
        } else {
          mutate(values);
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col
          {...{
            xs: 24,
            sm: 24,
            md: 24,
            lg: xxl ? 12 : 24,
            xl: xxl ? 12 : 24,
          }}
        >
          <InputSlider
            labelValues={{ accountName }}
            label="financial.intendedDeposit"
            name="intendedDeposit"
            min={0}
            max={3000000}
            step={10000}
            marks={{
              0: "0",
              250000: "250K",
              500000: "500K",
              1000000: "1M",
              1500000: "1.5M",
              2000000: "2M",
              2500000: "2.5M",
              3000000: "3M+",
            }}
          />
        </Col>

        <Col {...COMMON_COL_PROPS}>
          <Knowledge />
        </Col>
      </Row>
      {children({ loading: isPending })}
    </Form>
  );
};

export default GatTrader;
