import { FC, useEffect } from "react";
import {
  Tabs,
  Form,
  Typography,
  FormattedMessage,
  CreateAppButtons,
} from "components";
import { ChildProps } from "hocs";
import { UserSummary, UserContact } from "./components";
import { User } from "types";
import { authAsyncActions } from "configs";
import { useDispatch, useSelector } from "hooks";

const UserProfile: FC<ChildProps> = ({ getTopics }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => ({
    user: state.auth.currentClientRecord as User,
    loading: state.auth.loading,
  }));

  useEffect(() => {
    getTopics({ extra: <CreateAppButtons /> });
  }, [getTopics]);

  return (
    <Form.Provider
      onFormFinish={async (name, { values }) => {
        if (name === "changePassword") {
          return;
        }
        dispatch(
          authAsyncActions.updateUser({
            id: user.id,
            ...(values as Omit<User, "id">),
          })
        );
      }}
    >
      <Typography.Paragraph>
        <FormattedMessage id="userProfile.subtitle" />
      </Typography.Paragraph>
      <Tabs
        defaultActiveKey="summary"
        items={[
          {
            key: "summary",
            label: <FormattedMessage id="userProfile.summary" />,
            children: <UserSummary submitting={loading} user={user} />,
          },
          {
            key: "contact",
            label: <FormattedMessage id="userProfile.contact.title" />,
            children: <UserContact submitting={loading} user={user} />,
          },
        ]}
      />
    </Form.Provider>
  );
};

export default UserProfile;
