import { FC, useState } from "react";
import { useForm, useNotification, useSelector, useChildAPI } from "hooks";
import { FormattedMessage } from "react-intl";
import ExtendedTable from "../extended-table";
import StepsButtons from "../steps-buttons";
import FileUpload from "../file-upload";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { Storage, createUseStyles } from "utils";
import { GuidelinesList, ActionsColumn } from "./components";
import { EddDocumentsProps } from "./types";
import { EnhancedDueDiligence } from "types";
import { v4 as uuid } from "uuid";
import {
  Typography,
  Input,
  Image,
  Button,
  Alert,
  Form,
  Row,
  Col,
  UploadFile,
} from "antd";

const useStyles = createUseStyles({
  marginBottom: {
    marginBottom: 14,
  },
});

const Edd: FC<EddDocumentsProps> = ({
  parentID,
  parentRoute,
  loading,
  onNextClick,
  ...buttonsProps
}) => {
  const [uploading, toggleUploading] = useState(false);
  const classes = useStyles();
  const [form] = useForm<{
    files: UploadFile[];
    fileDescriptions: Record<string, string>;
  }>();
  const customerServiceEmail = useSelector(
    (state) => state.auth.account?.customer_service_email as string
  );
  const showNotification = useNotification();
  const { data, fetching, saving, save } = useChildAPI<
    EnhancedDueDiligence[],
    { files: Array<{ awsFileID: string; description: string }> }
  >({
    parentID,
    parentRoute,
    childRoute: "edd",
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values) => {
        try {
          const { files, fileDescriptions } = values;

          toggleUploading(true);

          const promises = files.map((file) => {
            return Storage.uploadData({
              key: `${Date.now()}-${uuid()}`,
              data: file.originFileObj as File,
              bucket: "uploads",
            }).result.then((resp) => ({
              awsFileID: resp.key,
              description: fileDescriptions[file.uid],
            }));
          });

          const result = await Promise.all(promises);

          toggleUploading(false);

          await save(
            {
              files: result,
            },
            {
              onSuccess: () => {
                form.resetFields();
              },
            }
          );
        } catch (e) {
          showNotification({
            type: "error",
            message: e.response?.data?.error || e?.message,
          });
        }
      }}
      initialValues={{
        files: [],
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5}>
            <FormattedMessage id="enhancedDueDiligence.longTitle" />
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Paragraph className={classes.marginBottom}>
            <FormattedMessage id="enhancedDueDiligence.guildline" />
          </Typography.Paragraph>
          <GuidelinesList
            className={classes.marginBottom}
            header="enhancedDueDiligence.guildline1"
            dataSource={[
              "enhancedDueDiligence.guildline1a",
              "enhancedDueDiligence.guildline1b",
            ]}
          />
          <GuidelinesList
            className={classes.marginBottom}
            header="enhancedDueDiligence.guildline2"
            dataSource={[
              "enhancedDueDiligence.guildline2a",
              "enhancedDueDiligence.guildline2b",
              "enhancedDueDiligence.guildline2c",
            ]}
          />

          <GuidelinesList
            className={classes.marginBottom}
            header="enhancedDueDiligence.guildline3"
            dataSource={[
              "enhancedDueDiligence.guildline3a",
              "enhancedDueDiligence.guildline3b",
              "enhancedDueDiligence.guildline3c",
              "enhancedDueDiligence.guildline3d",
            ]}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          {!data?.length && !fetching && (
            <Alert
              type="warning"
              className={classes.marginBottom}
              message={<FormattedMessage id="enhancedDueDiligence.warning" />}
              description={
                <FormattedMessage
                  id="enhancedDueDiligence.warningDescription"
                  values={{
                    email: (
                      <a href="mailto:info@gat-money.com">
                        {customerServiceEmail}
                      </a>
                    ),
                  }}
                />
              }
              showIcon
            />
          )}
          <FileUpload
            label=""
            name="files"
            getValueProps={(file) => ({ value: file })}
            onRemove={(removedFile) => {
              const files = form.getFieldValue("files");
              form.setFieldsValue({
                files: files.filter(
                  (file: UploadFile) => file.uid !== removedFile.uid
                ),
              });
            }}
          />
          <Form.Item dependencies={["files"]}>
            {({ getFieldValue }) => {
              const files = getFieldValue("files");

              return files.map((file: any) => {
                return (
                  <Form.Item
                    key={file.uid}
                    name={["fileDescriptions", file.uid]}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="validation.required" />,
                      },
                    ]}
                    label={
                      <span>
                        <FormattedMessage
                          id="enhancedDueDiligence.uploaderDescription"
                          values={{
                            fileName: file.name,
                          }}
                        />
                      </span>
                    }
                  >
                    <Input />
                  </Form.Item>
                );
              });
            }}
          </Form.Item>
          <Form.Item dependencies={["files"]} noStyle>
            {({ getFieldValue }) => {
              return (
                getFieldValue("files").length > 0 && (
                  <Row justify="center">
                    <Button
                      style={{ marginBottom: 10 }}
                      htmlType="button"
                      loading={saving || uploading}
                      onClick={() => form.submit()}
                    >
                      <FormattedMessage id="button.upload" />
                    </Button>
                  </Row>
                )
              );
            }}
          </Form.Item>
          <ExtendedTable<EnhancedDueDiligence>
            loading={fetching}
            dataSource={data}
            columns={[
              {
                title: "common.description",
                dataIndex: "description",
              },
              {
                title: "common.file",
                dataIndex: "url",
                render: ({ value, record }) =>
                  record.contentType.includes("pdf") ? (
                    <Typography.Link href={value} target="_blank">
                      <FormattedMessage id="button.view" />
                    </Typography.Link>
                  ) : (
                    <Image src={value} width={50} height={50} />
                  ),
              },
              {
                title: "common.actions",
                dataIndex: "id",
                render: ({ record }) => (
                  <ActionsColumn
                    parentID={parentID}
                    parentRoute={parentRoute}
                    recordID={record.id}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <StepsButtons
        {...buttonsProps}
        loading={loading}
        onNextClick={async () => {
          onNextClick?.();
        }}
      />
    </Form>
  );
};

export default Edd;
