import { FC } from "react";
import * as C from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { TranslatedRecord, Country, Phone, StreetAddress } from "types";
import { useChildAPI, useForm } from "hooks";
import { Types } from "../../duck";

interface AddressFormProps extends Types.CommonProps {
  streetTypes: TranslatedRecord[];
  countries: Country[];
  accountName: string;
}

const AddressForm: FC<AddressFormProps> = ({
  streetTypes,
  countries,
  accountName,
  onNextClick,
  record,
  loading,
  setLogs,
  ...btnProps
}) => {
  const [form] = useForm();

  const {
    data: phone,
    fetching: fetchingPhone,
    saving: savingPhone,
    save: savePhone,
  } = useChildAPI<Phone>({
    parentID: record.id,
    parentRoute: "informationRequests",
    childRoute: "phone",
  });

  const {
    data: streetAddress,
    fetching: fetchingStreetAddress,
    saving: savingStreetAddress,
    save: saveStreetAddress,
  } = useChildAPI<StreetAddress>({
    parentID: record.id,
    parentRoute: "informationRequests",
    childRoute: "street-address",
  });

  if (fetchingStreetAddress || fetchingPhone) {
    return <C.Skeleton active loading />;
  }

  return (
    <>
      <C.Row gutter={ROW_GUTTER}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Form
            layout="vertical"
            form={form}
            initialValues={{
              ...(phone || {}),
              ...(streetAddress || {}),
            }}
            onFinish={async (values) => {
              await setLogs(values);

              if (form.isFieldsTouched()) {
                await Promise.all([
                  saveStreetAddress(values),
                  savePhone(values),
                ]);
              }

              onNextClick();
            }}
          >
            <C.Phone />
            <C.StreetAddress countries={countries} streetTypes={streetTypes} />
          </C.Form>
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.POA
            parentID={record.id}
            parentRoute="informationRequests"
            accountName={accountName}
          />
        </C.Col>
      </C.Row>

      <C.StepsButtons
        {...btnProps}
        onNextClick={() => form.submit()}
        loading={savingPhone || savingStreetAddress || loading}
      />
    </>
  );
};

export default AddressForm;
