import { FC, useState } from "react";
import { Auth } from "utils";
import { useDispatch } from "hooks";
import { authAsyncActions } from "configs";
import { Divider, Form, FormInstance, notification, Modal } from "antd";
import InputText from "components/input-text";
import InputPassword from "components/input-password";

interface ComponentProps {
  loginForm: FormInstance;
  open: boolean;
  onCancel: () => void;
}

const ResetPasswordModal: FC<ComponentProps> = ({
  loginForm,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    const { confirmationCode, newPassword } = form.getFieldsValue();

    setLoading(true);

    try {
      await Auth.confirmResetPassword({
        username: loginForm.getFieldValue("email"),
        confirmationCode,
        newPassword,
      });

      dispatch(authAsyncActions.logOut());

      notification.info({
        message:
          "Password has been successfully set.  You may now login using the new password.",
      });

      onCancel();
    } catch (e) {
      notification.error({
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title="Password Reset"
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="Submit"
    >
      <p>
        Please check your email (including spam folder) for a confirmation code.
        <br />
        Enter it below, with your chosen new password.
        <br />
        <br />
        <strong>
          New password must be 8 characters including uppercase, lowercase one
          number and one symbol.
        </strong>
      </p>
      <Divider />
      <Form form={form} preserve={false} layout="vertical">
        <InputText
          required
          name="confirmationCode"
          label="login.confirmationCode"
        />
        <InputPassword
          required
          validate
          label="password.new"
          name="newPassword"
        />
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
