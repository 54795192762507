import { Button, Dropdown } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector, useNavigate, useAccountSubscription } from "hooks";
import { routes } from "configs";
import { Account } from "types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  button: {
    width: "100%",
  },
});

const CreateAppButtons = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { subscriptionActive } = useAccountSubscription();
  const account = useSelector((state) => state.auth.account as Account);

  const onCreateLive = (name: "individual" | "company" | "trust") => () => {
    navigate(`/open-${name}-account/${account.slug}`);
  };

  const onCreateDemo = () => {
    navigate(routes.openDemoAccount(account.slug));
  };

  return (
    <Dropdown
      arrow
      disabled={
        !subscriptionActive || (account.disabledLive && account.disabledDemo)
      }
      menu={{
        items: [
          ...(!account.disabledDemo
            ? [
                {
                  key: "demo",
                  label: (
                    <Button
                      onClick={onCreateDemo}
                      className={classes.button}
                      disabled={account.disabledDemo}
                    >
                      <FormattedMessage id="applications.demo.accountName" />
                    </Button>
                  ),
                },
              ]
            : []),
          ...(!account.disabledLive
            ? [
                {
                  key: "individual",
                  label: (
                    <Button
                      onClick={onCreateLive("individual")}
                      className={classes.button}
                      disabled={account.disabledLive}
                    >
                      <FormattedMessage id="applications.individual.accountName" />
                    </Button>
                  ),
                },
                {
                  key: "company",
                  label: (
                    <Button
                      onClick={onCreateLive("company")}
                      className={classes.button}
                      disabled={account.disabledLive}
                    >
                      <FormattedMessage id="applications.company.accountName" />
                    </Button>
                  ),
                },
                {
                  key: "trust",
                  label: (
                    <Button
                      onClick={onCreateLive("trust")}
                      className={classes.button}
                      disabled={account.disabledLive}
                    >
                      <FormattedMessage id="applications.trust.accountName" />
                    </Button>
                  ),
                },
              ]
            : []),
        ],
      }}
    >
      <Button>
        <FormattedMessage id="applications.common.createNewAccount" />
      </Button>
    </Dropdown>
  );
};

export default CreateAppButtons;
