import * as React from "react";
import { Form, Row, Col, Skeleton } from "antd";
import InputGroup from "../input-group";
import StepsButtons from "../steps-buttons";
import { ROW_GUTTER, COMMON_COL_PROPS, INPUT_GROUP_OPTIONS } from "consts";
import { useChildAPI, useForm } from "hooks";
import { ComplianceProps } from "./types";
import * as Types from "types";

const Compliance: React.FC<ComplianceProps> = ({
  parentID,
  parentRoute,
  onNextClick,
  ...btnProps
}) => {
  const [form] = useForm();
  const {
    data: compliance,
    fetching: fetchingCompliance,
    saving: savingCompliance,
    save: saveCompliance,
  } = useChildAPI<Types.Compliance>({
    parentID,
    parentRoute,
    childRoute: "compliance",
  });

  if (fetchingCompliance) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        compliance || {
          amlOrCft: false,
          refusedBanking: false,
          publicOfficeOrPep: false,
          adverseInformation: false,
          familyPublicOfficeOrPep: false,
        }
      }
      onFinish={async (values) => {
        if (form.isFieldsTouched()) {
          await saveCompliance(values);
        }

        onNextClick?.(values);
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputGroup
            name="publicOfficeOrPep"
            label="informationRequests.publicOfficeOrPep"
            options={INPUT_GROUP_OPTIONS}
          />
          <InputGroup
            name="familyPublicOfficeOrPep"
            label="informationRequests.familyPublicOfficeOrPep"
            options={INPUT_GROUP_OPTIONS}
          />
          <InputGroup
            name="amlOrCft"
            label="informationRequests.amlOrCft"
            options={INPUT_GROUP_OPTIONS}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputGroup
            name="adverseInformation"
            label="informationRequests.adverseInformation"
            options={INPUT_GROUP_OPTIONS}
          />
          <InputGroup
            name="refusedBanking"
            label="informationRequests.refusedBanking"
            options={INPUT_GROUP_OPTIONS}
          />
        </Col>
      </Row>
      <StepsButtons {...btnProps} loading={savingCompliance} />
    </Form>
  );
};

export default Compliance;
