import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { Auth, showNotification } from "utils";
import { authActions } from "../slices";
import { operations } from "./duck";

export const authEffect = async (listenerApi: ListenerEffectAPI<any, any>) => {
  try {
    const { userSub } = await Auth.fetchAuthSession();

    if (!userSub) {
      listenerApi.dispatch(authActions.authenticateError());
      return;
    }

    const { accountSlugs } = listenerApi.getState().appState;
    const data = await operations.getInitialData({
      cognitoID: userSub,
      accountSlugs,
    });

    listenerApi.dispatch(authActions.authenticateSuccess(data));
  } catch (e: any) {
    listenerApi.dispatch(authActions.authenticateError());

    if (e.$metadata?.httpStatusCode === 403) {
      const state = listenerApi.getState() as any;
      showNotification({
        type: "error",
        language: state.appState.language,
        message: "password.expired",
      });
    }
  }
};
