import { useState, useEffect } from "react";
import { useSelector } from "./useReactRedux";
import { Skeleton, Checkbox } from "antd";
import { CheckboxState, AccountAgreement } from "types";
import { classNames, getQueryKey } from "utils";
import { createUseStyles } from "react-jss";
import { useQuery } from "./tanstack";

const useStyles = createUseStyles({
  checkboxRow: {
    padding: "10px 0",
  },
  redLink: {
    color: "red",
  },
});

const validateCheckboxes = (checkboxState: Partial<CheckboxState>) => {
  const touched: Partial<CheckboxState> = {};

  const error = Object.entries(checkboxState).reduce(
    (prev, [name, { checked } = { checked: false }]) => {
      touched[name as keyof CheckboxState] = {
        checked,
        touched: true,
      };

      return !checked || prev;
    },
    false
  );

  return { error, touched };
};

const getCheckboxValues = (checkboxState: Partial<CheckboxState>) => {
  const keys = Object.keys(checkboxState) as Array<keyof CheckboxState>;

  return keys.reduce(
    (result, key) => ({
      ...result,
      [key]: !!checkboxState?.[key]?.checked,
    }),
    {}
  ) as Record<string, boolean>;
};

const useAgreements = (accountID?: number | null, showDemo?: 1) => {
  const classes = useStyles();
  const language = useSelector((state) => state.appState.language);
  const { data = [], isLoading } = useQuery<AccountAgreement[]>({
    apiName: "accounts",
    path: `/${accountID}/agreements`,
    options: showDemo ? { queryParams: { showDemo } } : undefined,
    enabled: !!accountID,
    queryKey: getQueryKey("accountAgreements", language, accountID, showDemo),
  });

  const [checkboxState, setCheckboxState] = useState<Partial<CheckboxState>>(
    {}
  );

  useEffect(() => {
    if (!data.length) {
      return;
    }

    setCheckboxState(
      data.reduce<Partial<CheckboxState>>((prev, curr) => {
        const exists = prev[curr.name];
        prev[curr.name] = {
          touched: !!exists?.touched,
          checked: !!exists?.checked,
        };
        return prev;
      }, {})
    );
  }, [data]);

  return {
    loading: isLoading,
    checkboxState,
    getCheckboxValues: () => getCheckboxValues(checkboxState),
    validateCheckboxes: () => {
      const { touched, error } = validateCheckboxes(checkboxState);

      setCheckboxState((prev) => ({
        ...prev,
        ...touched,
      }));

      return error;
    },

    items: isLoading
      ? [<Skeleton key="skeleton" active loading />]
      : data.map((item) => {
          const { checked, touched } = checkboxState[item.name] || {
            checked: false,
            touched: false,
          };

          return (
            <div className={classes.checkboxRow} key={item.name}>
              <Checkbox
                checked={checked}
                onChange={({ target }) => {
                  setCheckboxState((prevState) => ({
                    ...prevState,
                    [item.name]: {
                      touched: true,
                      checked: target.checked,
                    },
                  }));
                }}
              >
                <a
                  className={classNames(touched && !checked && classes.redLink)}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              </Checkbox>
            </div>
          );
        }),
  };
};

export default useAgreements;
