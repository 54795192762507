import { FC, useState } from "react";
import * as C from "components";
import { useNotification } from "hooks";
import { Types } from "./duck";
import { API, createUseStyles } from "utils";
import { FormattedMessage } from "react-intl";
import { Account } from "types";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const useStyles = createUseStyles({
  checkboxRow: {
    padding: "10px 0",
  },
  checkboxText: {
    color: "red",
  },
});

const FormComponent: FC<{ account: Account }> = ({ account }) => {
  const classes = useStyles();
  const showNotification = useNotification();

  const [state, setState] = useState<Types.State>({
    confirm: {
      touched: false,
      checked: false,
    },
    loading: false,
    submitted: false,
  });

  if (state.submitted) {
    return (
      <C.Result
        status="success"
        title={<C.FormattedMessage id="messages.done" />}
      />
    );
  }

  const onSubmit = async (values: Types.FormValues) => {
    showNotification({
      type: "info",
      message: "messages.closure.closureInProgress",
    });

    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    try {
      await API.post({
        apiName: "clients",
        path: "/close-account",
        options: {
          body: {
            firstName: values.first_name,
            email: values.email.toLowerCase(),
            account,
          },
        },
      });

      setState((prev) => ({
        ...prev,
        loading: false,
        submitted: true,
      }));
    } catch (e) {
      showNotification({
        type: "error",
        message: "messages.requestFailed",
      });

      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const touchConfirmed = () => {
    setState((prev) => ({
      ...prev,
      confirm: {
        ...prev.confirm,
        touched: true,
      },
    }));
  };

  const onFormFinish = (values: any) => {
    touchConfirmed();

    if (state.confirm.checked) {
      onSubmit(values);
    }
  };

  return (
    <C.Card title={<C.FormattedMessage id="applications.closure.form.title" />}>
      <p>
        <C.FormattedMessage
          id="applications.closure.form.description"
          values={{
            product_name: <strong>{account.product_name}</strong>,
          }}
        />
      </p>
      <C.Divider />
      <C.Form
        {...formItemLayout}
        onFinish={onFormFinish}
        onFinishFailed={touchConfirmed}
      >
        <C.InputText label="common.first_name" name="first_name" required />
        <C.InputText label="common.last_name" name="last_name" required />
        <C.InputEmail label="common.emailAddress" name="email" required />

        <C.Divider />

        <div className={classes.checkboxRow}>
          <C.Checkbox
            checked={state.confirm.checked}
            onChange={({ target: { checked } }) => {
              setState((prev) => ({
                ...prev,
                confirm: {
                  touched: true,
                  checked,
                },
              }));
            }}
          >
            <span
              className={
                !state.confirm.checked && state.confirm.touched
                  ? classes.checkboxText
                  : undefined
              }
            >
              <FormattedMessage
                id="applications.closure.form.checkbox"
                values={{ product_name: account.product_name }}
              />
            </span>
          </C.Checkbox>
        </div>

        <C.Button htmlType="submit" type="primary" loading={state.loading}>
          <C.FormattedMessage id="button.submit" />
        </C.Button>
      </C.Form>
    </C.Card>
  );
};

export default FormComponent;
