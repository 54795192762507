import * as C from "components";
import * as Types from "types";
import { useChildAPI, useForm, useMutation, useGetAccountSlug } from "hooks";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { useState } from "react";
import { sendInteractionEmail } from "requests";
import { QuizModal } from "./components";
import { Knowledge } from "types";

interface KnowledgeProps<TApplication> extends Types.StepButtonsProps {
  app: TApplication;
  appQueryKey: string[];
}

function KnowledgeComponent<TApplication extends Types.Application>({
  app,
  onNextClick,

  appQueryKey,
  ...btnProps
}: KnowledgeProps<TApplication>) {
  const [form] = useForm();
  const [quizModalState, setQuizModalState] = useState({
    // eslint-disable-next-line
    // @ts-ignore
    passed: app.quizPassed,
    open: false,
  });
  const accountSlug = useGetAccountSlug();
  const { mutateAsync, isPending } = useMutation<
    TApplication,
    {
      quizPassed: boolean;
    }
  >({
    method: "patch",
    apiName: "applications",
    path: `/${app.id}`,
    invalidateQueries: [appQueryKey],
    options: {
      queryParams: {
        accountSlug,
      },
    },
    onSuccess: () => {
      setQuizModalState({
        open: false,
        passed: true,
      });
    },
  });

  const {
    data: knowledge,
    fetching: fetchingKnowledge,
    saving: savingKnowledge,
    save: saveKnowledge,
  } = useChildAPI<
    Knowledge,
    {
      tradedLeveraged: boolean;
      tradedOptions: boolean;
      qualification: boolean;
    }
  >({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "knowledge",
  });

  if (fetchingKnowledge) {
    return <C.Skeleton active loading />;
  }

  return (
    <>
      <C.Form
        layout="vertical"
        form={form}
        initialValues={{
          ...app,
          ...(knowledge || {
            tradedLeveraged: false,
            tradedOptions: false,
            qualification: false,
          }),
        }}
        onFinish={async ({
          tradedLeveraged,
          tradedOptions,
          qualification,
          ...values
        }) => {
          if (!form.isFieldsTouched()) {
            onNextClick?.();
            return;
          }

          await Promise.all([
            saveKnowledge({
              tradedLeveraged,
              tradedOptions,
              qualification,
            }),
            mutateAsync(values),
          ]);

          onNextClick?.();
        }}
      >
        <C.Row gutter={ROW_GUTTER}>
          <C.Col {...COMMON_COL_PROPS}>
            <C.InputGroup
              name="tradedLeveraged"
              label="knowledge.tradedLeveraged"
            />
            <C.InputGroup
              name="tradedOptions"
              label="knowledge.tradedOptions"
            />
            <C.InputGroup
              name="qualification"
              label="knowledge.qualification"
            />
          </C.Col>
          {app.typeId === 1 && (
            <C.Col {...COMMON_COL_PROPS}>
              <C.Row gutter={ROW_GUTTER}>
                <C.Col>
                  <C.InputGroup
                    name="cfd"
                    label="applications.individual.cfd"
                  />
                </C.Col>
                <C.Col>
                  <C.InputGroup
                    name="forex"
                    label="applications.individual.forex"
                  />
                </C.Col>
                <C.Col>
                  <C.InputGroup
                    name="stocks"
                    label="applications.individual.stocks"
                  />
                </C.Col>
              </C.Row>
              <C.Row gutter={ROW_GUTTER}>
                <C.Col>
                  <C.InputGroup
                    name="futures"
                    label="applications.individual.futures"
                  />
                </C.Col>
                <C.Col>
                  <C.InputGroup
                    name="options"
                    label="applications.individual.options"
                  />
                </C.Col>
              </C.Row>
            </C.Col>
          )}
        </C.Row>
        <C.Form.Item dependencies={["cfd", "forex"]} noStyle>
          {({ getFieldsValue }) => {
            const { cfd, forex } = getFieldsValue(["cfd", "forex"]);

            if ((cfd || forex) && !quizModalState.passed) {
              return (
                <C.Row justify="end">
                  <C.Button
                    type="primary"
                    onClick={() => {
                      setQuizModalState((prevState) => ({
                        ...prevState,
                        open: true,
                      }));
                    }}
                  >
                    Start Quiz
                  </C.Button>
                </C.Row>
              );
            }

            return (
              <C.StepsButtons
                {...btnProps}
                loading={btnProps.loading || savingKnowledge || isPending}
              />
            );
          }}
        </C.Form.Item>
      </C.Form>
      <QuizModal
        accountName={app.account}
        loading={isPending}
        open={quizModalState.open}
        onSubmit={async (quizData) => {
          await mutateAsync(
            { quizPassed: true },
            {
              onSuccess: () => {
                sendInteractionEmail({
                  account: app.account,
                  interaction: "QUIZ",
                  data: quizData,
                });
              },
            }
          );
        }}
        onCancel={() =>
          setQuizModalState({
            ...quizModalState,
            open: false,
          })
        }
      />
    </>
  );
}

export default KnowledgeComponent;
