import { AccountSlug } from "types";

const getAccountSlug = (slugs: AccountSlug[]) => {
  const { href } = window.location;

  const str = slugs.map((item) => item.slug).join("|");

  const regExp = new RegExp(`(${str})`, "g");

  return href.match(regExp)?.[0];
};

export default getAccountSlug;
