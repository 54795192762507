import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { constants, operations, Types } from "./duck";
import { FormattedMessage } from "react-intl";
import { useNotification } from "hooks";

const GoogleAutocomplete: React.FC<Types.GoogleAutocompleteProps> = ({
  countries,
  streetTypes,
  onChange,
  disabledProp,
}) => {
  const showNotification = useNotification();
  const [googleAddress, setGoogleAddress] = React.useState<any>("");

  const handleSelectGoogleAddress = async (value: any | null) => {
    if (!value) {
      onChange(constants.INITIAL_VALUES);

      setGoogleAddress(value);
      return;
    }

    const addresses = await operations.getAddresses(
      value,
      countries,
      streetTypes
    );

    if (disabledProp && addresses.countryId) {
      const country = countries.find((c) => c.id === addresses.countryId);

      if (country?.[disabledProp]) {
        showNotification({
          type: "error",
          message: "googleAutocomplete.disabledCountry",
        });
        return;
      }
    }

    onChange(addresses);

    setGoogleAddress(value);
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={constants.GOOGLE_API_KEY}
      selectProps={{
        value: googleAddress,
        isClearable: true,
        backspaceRemovesValue: true,
        onChange: handleSelectGoogleAddress,
        placeholder: <FormattedMessage id="googleAutocomplete.placeholder" />,
        noOptionsMessage: ({ inputValue }: any) => {
          if (!inputValue) {
            return <FormattedMessage id="googleAutocomplete.placeholder" />;
          }

          return "Not found";
        },
      }}
      autocompletionRequest={{
        componentRestrictions: { country: countries.map((c) => c.iso_alpha_2) },
      }}
    />
  );
};

export default GoogleAutocomplete;
