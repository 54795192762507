import { useState, useEffect } from "react";
import * as C from "components";
import { useLocation } from "hooks";
import { routes } from "configs";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  menu: {
    overflow: "auto",
    height: "100%",
  },
  itemGroup: {
    textAlign: "center",
  },
});

const Menu = () => {
  const classes = useStyles();
  const [selectedKeys, setSelectedKeys] = useState(["/dashboard"]);
  const { pathname } = useLocation();

  useEffect(() => {
    const selected = pathname.match(/\/\w+(\w|-)+/);

    setSelectedKeys(selected ? [selected[0]] : []);
  }, [pathname]);

  return (
    <C.Menu
      theme="light"
      mode="inline"
      className={classes.menu}
      selectedKeys={selectedKeys}
      items={[
        {
          key: routes.dashboard,
          icon: <C.DashboardOutlined />,
          label: (
            <C.NavLink to={routes.dashboard}>
              <C.FormattedMessage id="dashboard.title" />
            </C.NavLink>
          ),
        },
        {
          key: routes.applications,
          icon: <C.FolderOutlined />,
          label: (
            <C.NavLink to={routes.applications}>
              <C.FormattedMessage id="applications.title" />
            </C.NavLink>
          ),
        },
        {
          key: routes.userProfile,
          icon: <C.UserOutlined />,
          label: (
            <C.NavLink to={routes.userProfile}>
              <C.FormattedMessage id="userProfile.title" />
            </C.NavLink>
          ),
        },
        {
          key: routes.verifyExternal.main,
          icon: <C.ScheduleOutlined />,
          label: (
            <C.NavLink to={routes.verifyExternal.list()}>
              <C.FormattedMessage id="verifyExternal.title" />
            </C.NavLink>
          ),
        },
      ]}
    />
  );
};

export default Menu;
