import { CommonProps } from "components/proof-of-address/types";
import { FC } from "react";
import { useDeleteMutation } from "hooks";
import { Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import PreLoader from "components/pre-loader";
import { DeleteOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";

interface ActionsColumnProps extends CommonProps {
  queryKey: (string | Record<string, any>)[];
  childID: number;
}

const useStyles = createUseStyles({
  color: {
    color: "red",
  },
  popConfirm: {
    display: "flex",
    justifyContent: "center",
  },
});

const ActionsColumn: FC<ActionsColumnProps> = ({
  parentID,
  parentRoute,
  childID,
  queryKey,
}) => {
  const classes = useStyles();
  const { mutate: deleteItem, isPending } = useDeleteMutation({
    apiName: parentRoute,
    path: `/${parentID}/poa/${childID}`,
    invalidateQueries: [queryKey],
  });

  return (
    <Popconfirm
      title={<FormattedMessage id="confirmations.areYouSure" />}
      okText={<FormattedMessage id="button.ok" />}
      cancelText={<FormattedMessage id="button.cancel" />}
      onConfirm={() => deleteItem()}
      className={classes.popConfirm}
    >
      {isPending ? (
        <PreLoader size="small" className={classes.color} />
      ) : (
        <DeleteOutlined className={classes.color} />
      )}
    </Popconfirm>
  );
};

export default ActionsColumn;
