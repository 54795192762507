import {
  InformationRequestsRecord,
  OnNextClickArgs,
  UpdateLanguage,
  SetLogs,
} from "./types";
import { API, showItem } from "utils";
import { sendInteractionEmail } from "requests";
import { AccountSettings, InformationRequest } from "types";

export const getSteps = (
  record: InformationRequest,
  accountSettings: Omit<AccountSettings, "knowledge">
) => {
  const financial = showItem({
    item: ["financial.title"],
    flag: accountSettings.financial,
  });
  const compliance = showItem({
    item: ["common.compliance"],
    flag: accountSettings.compliance,
  });
  const edd = showItem({
    item: ["enhancedDueDiligence.title"],
    flag: accountSettings.edd,
  });
  const taxation = showItem({
    item: ["taxation.title"],
    flag: accountSettings.taxation,
  });

  return record.submitted
    ? []
    : [
        "informationRequests.title",
        "informationRequests.identity",
        "informationRequests.contactAddress",
        ...financial,
        ...taxation,
        "accountTransactions.title",
        ...compliance,
        ...edd,
        "informationRequests.confirmSubmit",
      ];
};

export const updateLanguage = ({
  language,
  uuid,
  setState,
  showNotification,
}: UpdateLanguage) => {
  API.patch({
    apiName: "informationRequests",
    path: `/${uuid}`,
    options: {
      body: { language },
    },
  })
    .then((record) => {
      setState((prev) => ({
        ...prev,
        record,
      }));
    })
    .catch(() => {
      showNotification({
        type: "error",
        message: "messages.requestFailed",
      });
    });
};

export const setLogs: SetLogs =
  ({ currentStep, uuid, showNotification, setState }) =>
  async (values) => {
    try {
      setState((prev) => ({
        ...prev,
        saving: true,
      }));

      await API.post({
        apiName: "informationRequests",
        path: `/${uuid}/change-step`,
        options: {
          body: {
            step: currentStep,
            values,
          },
        },
      });

      setState((prev) => ({
        ...prev,
        saving: false,
      }));
    } catch (e) {
      showNotification({
        type: "error",
        message: e.response?.data?.error || e.message,
      });

      setState((prev) => ({
        ...prev,
        saving: false,
      }));
    }
  };
export const onNextClick =
  ({ record, setState, currentStep, showNotification }: OnNextClickArgs) =>
  async (values?: Partial<InformationRequestsRecord>) => {
    setState((prev) => ({
      ...prev,
      saving: true,
    }));

    try {
      let updatedRecord: any;

      if (values) {
        updatedRecord = await API.patch({
          apiName: "informationRequests",
          path: `/${record.uuid}`,
          options: {
            body: values,
          },
        });
      }

      if (currentStep === 0) {
        sendInteractionEmail({
          account: record.account as string,
          interaction: "Information requests updated",
          data: {
            ...values,
            id: record.id,
            email: record.email,
            step: currentStep,
          },
        });
      }

      setState((prev) => {
        if (prev.record) {
          return {
            ...prev,
            record: {
              ...prev.record,
              ...(updatedRecord || {}),
            },
            currentStep: prev.currentStep + 1,
          };
        }
        return prev;
      });
    } catch (e) {
      showNotification({
        type: "error",
        message: e.response?.data?.error || e.message,
      });
    }

    setState((prev) => ({
      ...prev,
      saving: false,
    }));
  };
