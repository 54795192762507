import { SliderMark } from "./types";

export const getMarks = (marks: SliderMark, mobile?: boolean) => {
  if (!mobile) {
    return marks;
  }

  const markArray = Object.keys(marks);

  return markArray.reduce<SliderMark>((prev, key, i) => {
    // eslint-disable-next-line
    // @ts-ignore
    let label: string | null = marks[key];

    if (mobile) {
      if (i !== 0 && i !== markArray.length - 1) {
        label = null;
      }
    }

    return {
      ...prev,
      [key]: label,
    };
  }, {});
};

export const getPrecisionLetter = (num: number) => {
  if (num === 0) {
    return num;
  }

  if (num > 0 && num < 1e6) {
    return `${num}k`;
  }

  if (num >= 1e6) {
    return `${num}M`;
  }
};
