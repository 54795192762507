import * as React from "react";
import {
  Alert,
  FormattedMessage,
  Row,
  Col,
  ExtendedDescriptions,
  StepsButtons,
  Typography,
  Form,
  notification,
} from "components";
import { useAgreements } from "hooks";
import moment from "moment";
import { COMMON_COL_PROPS, DATE_TIME_FORMAT } from "consts";
import { API } from "utils";
import { Types } from "../../duck";

interface ConfirmStepProps
  extends Omit<Types.CommonProps, "onNextClick" | "loading"> {
  submitRecord: (submittedRecord: Types.InformationRequestsRecord) => void;
}

const Confirm: React.FC<ConfirmStepProps> = ({
  record,
  setLogs,
  submitRecord,
  ...props
}) => {
  const [submitting, toggleSubmitting] = React.useState(false);
  const { items, validateCheckboxes, checkboxState, loading } = useAgreements(
    record.accountId
  );

  const alertProps: {
    type: "success" | "warning";
    description?: React.ReactNode;
  } = record.submitted
    ? {
        type: "success",
        description: <FormattedMessage id="informationRequests.submitted" />,
      }
    : {
        type: "warning",
        description: <FormattedMessage id="informationRequests.confirm" />,
      };

  const descriptions = [
    {
      label: "common.first_name",
      value: record.firstName,
    },
    {
      label: "common.last_name",
      value: record.lastName,
    },
    {
      label: "common.emailAddress",
      value: record.email,
    },
  ];

  if (record.submitted) {
    descriptions.push({
      label: "informationRequests.submissionDetails",
      value: moment(record.submitted).format(DATE_TIME_FORMAT),
    });
  }

  return (
    <Form
      layout="vertical"
      onFinish={async () => {
        const error = validateCheckboxes();

        if (!error) {
          toggleSubmitting(true);

          await setLogs(checkboxState);

          try {
            const submittedRecord = await API.post({
              apiName: "informationRequests",
              path: `/${record.uuid}/submit`,
            });

            submitRecord(submittedRecord);
          } catch (e: any) {
            notification.error({
              message: e?.response?.data?.error || e.message,
            });
          }

          toggleSubmitting(false);
        }
      }}
    >
      <Alert
        {...alertProps}
        message={<FormattedMessage id="informationRequests.title" />}
        showIcon
        style={{ marginBottom: 20 }}
      />
      <Row gutter={[14, 14]}>
        <Col {...(record.submitted ? { span: 24 } : COMMON_COL_PROPS)}>
          <ExtendedDescriptions column={1} items={descriptions} />
        </Col>
        {!record.submitted && (
          <Col {...COMMON_COL_PROPS}>
            <Typography.Title level={5}>
              <FormattedMessage id="common.confirmationTitle" />
            </Typography.Title>
            {items}
          </Col>
        )}
      </Row>
      {!record.submitted && (
        <StepsButtons {...props} loading={submitting || loading} />
      )}
    </Form>
  );
};

export default Confirm;
