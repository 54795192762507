import { FC } from "react";
import { Tag } from "antd";
import ExtendedTable from "components/extended-table";
import ExtendedTag from "components/extended-tag";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { NavLink } from "react-router-dom";
import { useSelector, useQuery } from "hooks";
import { ActionsColumn, TableRow } from "./components";
import dayjs from "dayjs";
import _ from "lodash";
import { getQueryKey } from "utils";
import { DATE_TIME_FORMAT } from "consts";
import { routes } from "configs";
import { constants } from "./duck";
import { ApplicationsList, ColumnFilterInputTypes } from "types";

const useStyles = createUseStyles({
  tag: {
    textAlign: "center",
    width: 100,
    marginInlineEnd: 0,
    padding: 0,
  },
  searchTag: {
    textAlign: "center",
    width: "100%",
    marginInlineEnd: 0,
    padding: 0,
  },
});

const ApplicationsTable: FC = () => {
  const classes = useStyles();
  const clientID = useSelector((state) => state.auth.currentClientRecord?.id);
  const queryKey = getQueryKey("applicationsList", clientID);
  const { data: applications, isLoading } = useQuery<ApplicationsList[]>({
    apiName: "applications",
    queryKey,
  });

  return (
    <ExtendedTable<ApplicationsList>
      dataSource={applications}
      loading={isLoading}
      scroll={{ x: true }}
      style={{ marginTop: 24 }}
      components={{
        body: {
          row: TableRow,
        },
      }}
      // eslint-disable-next-line
      // @ts-ignore
      onRow={(record) => ({
        subscriptionActive: record.subscriptionActive,
      })}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: "descend",
          render: ({ record, highlighter }) => {
            if (!record.subscriptionActive) {
              return highlighter;
            }

            const showLink =
              (record.statusId === 1 || record.statusId === 2) &&
              record.modeId === 2;

            if (showLink) {
              const url = routes.openApplication({
                accountSlug: record.accountSlug,
                typeId: record.typeId,
                id: record.id,
              });

              return <NavLink to={url}>{highlighter}</NavLink>;
            }

            return highlighter;
          },
        },
        {
          title: "common.createdDate",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "common.account",
          dataIndex: "accountID",
          onFilter: (value, record) => record.accountID.toString() === value,
          filters: _.uniqBy(applications, "account").map((item) => ({
            value: item.accountID.toString(),
            text: (
              <ExtendedTag backgroundColor={item.accountColor}>
                {item.account}
              </ExtendedTag>
            ),
          })),
          render: ({ record }) => (
            <ExtendedTag backgroundColor={record.accountColor}>
              {record.account}
            </ExtendedTag>
          ),
        },
        {
          title: "common.mode",
          dataIndex: "modeId",
          onFilter: (value, record) => record.modeId.toString() === value,
          filters: [
            {
              text: <FormattedMessage id="accountMode.demo" />,
              value: "1",
            },
            {
              text: <FormattedMessage id="accountMode.live" />,
              value: "2",
            },
          ],
          render: ({ record }) => {
            const { color, text } = constants.MODE[record.modeId];

            return (
              <Tag color={color} className={classes.tag}>
                <FormattedMessage id={text} />
              </Tag>
            );
          },
        },
        {
          title: "common.type",
          dataIndex: "typeId",
          onFilter: (value, record) => record.typeId.toString() === value,
          filters: [
            {
              text: <FormattedMessage id="common.individual" />,
              value: "1",
            },
            {
              text: <FormattedMessage id="common.company" />,
              value: "2",
            },
            {
              text: <FormattedMessage id="common.trust" />,
              value: "3",
            },
          ],
          render: ({ record }) => {
            const { color, text } = constants.TYPE[record.typeId];

            return (
              <Tag color={color} className={classes.tag}>
                <FormattedMessage id={text} />
              </Tag>
            );
          },
        },
        {
          title: "common.status",
          dataIndex: "statusId",
          onFilter: (value, record) => record.statusId.toString() === value,
          filters: [
            {
              text: <FormattedMessage id="common.draft" />,
              value: "1",
            },
            {
              text: <FormattedMessage id="common.submitted" />,
              value: "2",
            },
          ],
          render: ({ record }) => {
            const { color, text } = constants.STATUS[record.statusId];

            return (
              <Tag color={color} className={classes.tag}>
                <FormattedMessage id={text} />
              </Tag>
            );
          },
        },
        {
          title: "common.actions",
          dataIndex: "id",
          render: ({ record }) => {
            if (record.statusId === 1) {
              return (
                <ActionsColumn
                  queryKey={queryKey}
                  appID={record.id}
                  disabled={!record.subscriptionActive}
                />
              );
            }

            return "-";
          },
        },
      ]}
    />
  );
};

export default ApplicationsTable;
