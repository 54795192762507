import { FC } from "react";
import * as C from "components";
import { useSelector } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { CommonProps } from "../../types";

const UserContact: FC<CommonProps> = ({ user, submitting }) => {
  const countries = useSelector((state) => state.appState.countries);

  return (
    <C.Form name="contact" layout="vertical" initialValues={user}>
      <C.Row gutter={ROW_GUTTER}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id="streetAddress.title" />
          </C.Typography.Title>
          <C.InputText
            label="userProfile.contact.street1"
            name="street_address_line_1"
          />
          <C.InputText
            label="userProfile.contact.street2"
            name="street_address_line_2"
          />

          <C.Row gutter={[8, 0]}>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.suburb"
                name="street_address_suburb"
              />
            </C.Col>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.state"
                name="street_address_state"
              />
            </C.Col>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.postcode"
                name="street_address_postcode"
              />
            </C.Col>
          </C.Row>
          <C.CountriesSelect
            restricted
            label="common.country"
            name="street_address_country"
          />
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id="postalAddress.title" />
          </C.Typography.Title>
          <C.InputText
            label="userProfile.contact.postal1"
            name="postal_address_line_1"
          />
          <C.InputText
            label="userProfile.contact.postal2"
            name="postal_address_line_2"
          />

          <C.Row gutter={[8, 0]}>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.suburb"
                name="postal_address_suburb"
              />
            </C.Col>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.state"
                name="postal_address_state"
              />
            </C.Col>
            <C.Col span={8}>
              <C.InputText
                label="streetAddress.postcode"
                name="postal_address_postcode"
              />
            </C.Col>
          </C.Row>
          <C.InputSelect
            showSearch
            label="common.country"
            name="postal_address_country"
            options={countries}
            getOptionProps={(option) => ({
              value: option.id,
              children: option.label,
            })}
          />
        </C.Col>
      </C.Row>
      <C.Row>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id="contactDetails.title" />
          </C.Typography.Title>
          <C.PhoneInput label="phone.home" name="tel_home" />
          <C.PhoneInput label="phone.work" name="tel_work" />
          <C.PhoneInput label="phone.mobile" name="tel_mobile" />
          <C.InputEmail label="common.emailSecondary" name="email_secondary" />
        </C.Col>
      </C.Row>
      <C.SubmitButton loading={submitting} />
    </C.Form>
  );
};

export default UserContact;
