import { FC, useState, useEffect } from "react";
import { Form, Row, Col, Button, Space, notification, Typography } from "antd";
import InputText from "components/input-text";
import { FormattedMessage } from "react-intl";
import { authAsyncActions } from "configs";
import { COMMON_COL_PROPS } from "consts";
import { useSelector, useDispatch } from "hooks";
import { Auth } from "utils";

interface VerifyPasswordCodeProps {
  email: string;
  password: string;
}

const VerifyPasswordCode: FC<VerifyPasswordCodeProps> = ({
  email,
  password,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    loading: boolean;
    resending: boolean;
  }>({
    loading: false,
    resending: false,
  });
  const { authLoading, confirmSignup } = useSelector((rootState) => ({
    authLoading: rootState.auth.loading,
    confirmSignup: rootState.auth.confirmSignup,
  }));

  const onResendClick = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        resending: true,
      }));

      await Auth.resendSignUpCode({
        username: email,
      });

      setState((prevState) => ({
        ...prevState,
        resending: false,
      }));
    } catch (e) {
      notification.error({
        message: e.message,
      });

      setState((prevState) => ({
        ...prevState,
        resending: false,
      }));
    }
  };

  useEffect(() => {
    if (confirmSignup) {
      onResendClick();
    }
    // eslint-disable-next-line
  }, [confirmSignup]);

  return (
    <Form
      name="verification"
      layout="vertical"
      onFinish={async ({ confirmationCode }) => {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));

        try {
          await Auth.confirmSignUp({
            username: email,
            confirmationCode,
          });

          dispatch(
            authAsyncActions.loginStart({
              email,
              password,
            })
          );
        } catch (e) {
          let { message } = e;

          if (e.code === "CodeMismatchException") {
            message =
              "Verification code error.  Please re-enter or press 'resend code' to get new code sent to your email.";
          }

          notification.error({
            message,
          });
        }

        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }}
    >
      <Row justify="center">
        <Typography.Paragraph type="secondary">
          A verification code has been sent to your email:{" "}
          <strong>{email}</strong>
        </Typography.Paragraph>
      </Row>
      <Row justify="center">
        <Col {...COMMON_COL_PROPS}>
          <InputText
            label="verifications.code"
            name="confirmationCode"
            preserve={false}
            required
          />
        </Col>
      </Row>
      <Space
        align="center"
        style={{
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={state.loading || authLoading}
        >
          <FormattedMessage id="button.verify" />
        </Button>
        <Button loading={state.resending} onClick={onResendClick}>
          <FormattedMessage id="button.resend" />
        </Button>
      </Space>
    </Form>
  );
};

export default VerifyPasswordCode;
