import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useSelector, useMatch } from "hooks";
import { routes } from "configs";
import { NavLink, Skeleton } from "components";
import logoDefault from "assets/img/eApplicationForm.png";
import favIconDefault from "assets/img/eApplicationFormFavIcon.png";
import { getAccountSlug } from "utils";

const useStyles = createUseStyles({
  skeleton: {
    "&.ant-skeleton.ant-skeleton-element .ant-skeleton-image": {
      width: "200px;",
    },
  },
  logo: {
    width: 200,
    display: "inherit",
    "@media (max-width: 768px)": {
      width: 150,
    },
    "@media (max-width: 500px)": {
      width: 125,
    },
  },
  link: {
    display: "inline-block",
  },
});

const Logo = () => {
  const classes = useStyles();
  const verifyExternalMatch = useMatch(routes.verifyExternal.view());
  const informationRequestMatch = useMatch(routes.informationRequest.view());
  const { account, accountLoading, accountSlugs } = useSelector((state) => ({
    account: state.auth.account,
    accountLoading: state.auth.accountLoading,
    accountSlugs: state.appState.accountSlugs,
  }));
  const slug = getAccountSlug(accountSlugs);

  useEffect(() => {
    const el = document.getElementById("favicon") as HTMLLinkElement | null;

    if (!el) {
      return;
    }

    if (account) {
      el.href = account.favicon;
    } else {
      el.href = favIconDefault;
    }
  }, [account]);

  if (accountLoading) {
    return <Skeleton.Image active className={classes.skeleton} />;
  }

  const showLogo = slug || verifyExternalMatch || informationRequestMatch;

  return (
    <NavLink to={routes.login} className={classes.link}>
      <img
        alt="Page Header Logo"
        src={
          !account || !showLogo
            ? logoDefault
            : account?.page_header_logo || logoDefault
        }
        className={classes.logo}
      />
    </NavLink>
  );
};

export default Logo;
