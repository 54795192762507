import { FC } from "react";
import { Popover, SelectProps } from "antd";
import { FormattedMessage } from "react-intl";
import { Country } from "types";
import InputSelect from "../basic";
import { getQueryKey } from "utils";
import { useQuery, useSelector } from "hooks";

interface CountriesSelectProps extends SelectProps {
  required?: boolean;
  name: string | number | (string | number)[];
  disabledProp?: "disabledLive" | "disabledDemo";
  label?: string | null;
  restricted?: boolean;
}

interface Option {
  children:
    | string
    | {
        props: {
          children: string;
        };
      };
}

const filterOption = (input: string, option?: Option) => {
  if (typeof option?.children === "string") {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }

  return (
    option?.children?.props?.children
      ?.toLowerCase()
      .indexOf(input.toLowerCase()) !== -1
  );
};

const CountriesSelect: FC<CountriesSelectProps> = ({
  restricted,
  label = "common.country",
  name,
  disabledProp,
  ...props
}) => {
  const { language, accountID } = useSelector((state) => ({
    language: state.appState.language,
    accountID: state.auth.account?.id as number,
  }));
  const { data = [], isLoading } = useQuery<Country[]>({
    queryKey: getQueryKey("countries", {
      accountID,
      restricted,
      language,
    }),
    apiName: "countries",
    options: {
      queryParams: {
        restrictedCountry: !!restricted,
        accountId: accountID,
      },
    },
  });

  return (
    <InputSelect
      {...props}
      label={label}
      placeholder="placeholders.select"
      name={name}
      options={data}
      loading={isLoading}
      filterOption={filterOption}
      getOptionProps={(option: Country) => ({
        value: option.id,
        disabled: disabledProp && !!option[disabledProp],
        children:
          disabledProp && option[disabledProp] ? (
            <Popover
              title={null}
              placement="left"
              content={
                <FormattedMessage id="googleAutocomplete.disabledCountry" />
              }
              zIndex={1100}
            >
              {option.label}
            </Popover>
          ) : (
            option.label
          ),
      })}
    />
  );
};

export default CountriesSelect;
