import { SyntheticEvent, FC } from "react";
import { Button, Space } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { blue } from "@ant-design/colors";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

interface ActionsProps {
  className?: string;
  onCancel: (event: SyntheticEvent<HTMLElement>) => void;
  onEdit: (event: SyntheticEvent<HTMLElement>) => void;
  onDelete?: (event: SyntheticEvent<HTMLElement>) => void;
  deleting?: boolean;
  submitting?: boolean;
  editMode: boolean;
}

const useStyles = createUseStyles({
  button: {
    color: blue.primary,
  },
});

const Actions: FC<ActionsProps> = ({
  className,
  onEdit,
  onCancel,
  onDelete,
  deleting,
  submitting,
  editMode,
}) => {
  const classes = useStyles();

  return (
    <Space>
      {editMode && (
        <Button
          type="text"
          htmlType="submit"
          loading={submitting}
          className={classNames(classes.button, className)}
        >
          <FormattedMessage id="button.save" />
        </Button>
      )}
      {!editMode && (
        <Button
          type="text"
          htmlType="button"
          className={classNames(classes.button, className)}
          onClick={onEdit}
        >
          <EditOutlined />
        </Button>
      )}
      {onDelete && !editMode && (
        <Button
          danger
          type="text"
          htmlType="button"
          className={classNames(classes.button, className)}
          onClick={onDelete}
          loading={deleting}
          icon={<DeleteOutlined />}
        />
      )}
      {editMode && (
        <Button
          type="text"
          htmlType="button"
          className={classNames(classes.button, className)}
          onClick={onCancel}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
      )}
    </Space>
  );
};

export default Actions;
