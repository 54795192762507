import { ShowNotification, VerificationDocV } from "types";
import { Storage } from "utils";
import { v4 as uuid } from "uuid";

export const checkRemovedFields = ({
  record,
  frontImgFile,
  backImgFile,
}: {
  record: VerificationDocV;
  frontImgFile?: string;
  backImgFile?: string;
}) => {
  const promises = [];

  if (record.frontImgID && record.frontImgID !== frontImgFile) {
    promises.push(
      Storage.del({
        key: record.frontImgID,
        bucket: "identificationDocuments",
      })
    );
  }

  if (record.backImgID && record.backImgID !== backImgFile) {
    promises.push(
      Storage.del({
        key: record.backImgID,
        bucket: "identificationDocuments",
      })
    );
  }

  return Promise.all(promises);
};

export const uploadFile = async ({
  file,
  name,
  showNotification,
}: {
  file: File | null;
  name: "frontImg" | "backImg";
  showNotification: ShowNotification;
}) => {
  if (!file) {
    return null;
  }

  try {
    // Upload via storage instead of lambda
    // When I tried to upload files via lambda + FormData, requests were being hanged

    const { key: awsFileID } = await Storage.uploadData({
      key: `${Date.now()}-${uuid()}`,
      bucket: "identificationDocuments",
      data: file,
    }).result;

    return awsFileID;
  } catch (e) {
    showNotification({
      type: "error",
      message: "verifications.errors.uploadError",
      messageValues: {
        kind: {
          translate: true,
          value: `verifications.${name}`,
        },
      },
    });

    return Promise.reject("uploadError");
  }
};
