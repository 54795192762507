import { FC } from "react";
import { Tag } from "antd";
import { createUseStyles } from "react-jss";
import { isColorDark } from "utils";

interface ExtendedTagProps {
  fullWidth?: boolean;
  children: string;
  backgroundColor: string;
}

const useStyles = createUseStyles<
  any,
  {
    fullWidth?: boolean;
    bgDark: boolean;
  }
>({
  tag: (props) => {
    if (props.fullWidth) {
      return {
        width: "100%",
        textAlign: "center",
      };
    }
  },
  tagText: (props) =>
    props.bgDark
      ? {
          color: "#fff",
        }
      : {
          color: "#000",
        },
});

const ExtendedTag: FC<ExtendedTagProps> = ({
  children,
  backgroundColor,
  fullWidth,
}) => {
  const classes = useStyles({
    bgDark: isColorDark(backgroundColor),
    fullWidth,
  });

  return (
    <Tag className={classes.tag} color={backgroundColor}>
      <span className={classes.tagText}>{children}</span>
    </Tag>
  );
};

export default ExtendedTag;
