import { FC } from "react";
import ExtendedTable from "components/extended-table";
import { useDeleteMutation } from "hooks";
import { TaxationProps } from "../../duck/types";
import { QueryKey } from "@tanstack/react-query";

interface ActionsColumnProps
  extends Pick<TaxationProps, "parentRoute" | "parentID"> {
  submitting: boolean;
  editMode: boolean;
  onCancel: () => void;
  onEdit: () => void;
  id: number;
  queryKey: QueryKey;
}

const ActionsColumn: FC<ActionsColumnProps> = ({
  editMode,
  onEdit,
  submitting,
  onCancel,
  parentID,
  parentRoute,
  id,
  queryKey,
}) => {
  const { isPending, mutate } = useDeleteMutation<number>({
    apiName: parentRoute,
    path: `/${parentID}/taxation/${id}`,
    invalidateQueries: [queryKey],
  });

  return (
    <ExtendedTable.Actions
      submitting={submitting}
      editMode={editMode}
      deleting={isPending}
      onCancel={onCancel}
      onDelete={() => mutate()}
      onEdit={onEdit}
    />
  );
};

export default ActionsColumn;
