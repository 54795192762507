import { FC } from "react";
import {
  Verification,
  Taxation,
  AccountTransactions,
  Edd,
  Compliance,
  Financial,
} from "components";
import { useSelector } from "hooks";
import { Types, hocs } from "../../duck";
import * as LC from "./components";
import * as SC from "../shared";
import { Account, IndividualApplication, User } from "types";
import { showItem } from "utils";

const IndividualApp: FC<Types.ChildAppWrapperProps<IndividualApplication>> = ({
  app,
  appQueryKey,
  steps,
  nextStep,
  currentStep,
  onNextClick,
  onPrevClick,
}) => {
  const {
    streetTypes,
    countries,
    notRestrictedCountries,
    account,
    currentClientRecord,
  } = useSelector((state) => ({
    account: state.auth.account as Account,
    currentClientRecord: state.auth.currentClientRecord as User,
    streetTypes: state.appState.streetTypes,
    countries: state.appState.countries,
    notRestrictedCountries: state.appState.notRestrictedCountries,
  }));

  const COMMON_PROPS = {
    app,
    onPrevClick,
    onNextClick,
    currentStep,
    steps,
    appQueryKey,
  };

  const stepComponents =
    app.statusId === 2
      ? [<SC.Summary />]
      : [
          <Verification
            {...COMMON_PROPS}
            parentRoute="applications"
            parentID={app.id}
            first_name={currentClientRecord.first_name}
            last_name={currentClientRecord.last_name}
          />,
          <LC.PersonalDetails
            notRestrictedCountries={notRestrictedCountries}
            {...COMMON_PROPS}
          />,
          <LC.Address
            {...COMMON_PROPS}
            app={app}
            accountName={account?.file_by || ""}
            countries={countries}
            streetTypes={streetTypes}
          />,
          ...showItem({
            flag: account.applicationSettings.financial,
            item: [
              <Financial
                {...COMMON_PROPS}
                accountName={account.account_name}
                accountTypeID={account.typeId}
                parentID={app.id}
                parentRoute="applications"
                onNextClick={onNextClick}
              />,
            ],
          }),
          ...showItem({
            flag: account.applicationSettings.taxation,
            item: [
              <Taxation
                parentRoute="applications"
                parentID={app.id}
                {...COMMON_PROPS}
              />,
            ],
          }),
          ...showItem({
            item: [<SC.Knowledge {...COMMON_PROPS} app={app} />],
            flag: account.applicationSettings.knowledge,
            defaultValue: [
              <AccountTransactions
                {...COMMON_PROPS}
                accountID={account.id}
                parentID={app.id}
                parentRoute="applications"
              />,
            ],
          }),
          ...showItem({
            flag: account.applicationSettings.compliance,
            item: [
              <Compliance
                {...COMMON_PROPS}
                parentID={app.id}
                parentRoute="applications"
                onNextClick={() => onNextClick()}
              />,
            ],
          }),
          ...showItem({
            flag: account.applicationSettings.edd,
            item: [
              <Edd
                {...COMMON_PROPS}
                parentID={app.id}
                parentRoute="applications"
              />,
            ],
          }),
          ...showItem({
            flag: !!account.optionalAccountID,
            item: [
              <SC.Optional
                {...COMMON_PROPS}
                account={account}
                application={app}
                nextStep={nextStep}
              />,
            ],
          }),
          <SC.Confirm
            {...COMMON_PROPS}
            account={account}
            app={app}
            currentClientRecord={currentClientRecord}
          />,
        ];

  return stepComponents[currentStep];
};

export default hocs.wrapper<IndividualApplication>(IndividualApp, 1);
