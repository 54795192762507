import { FormattedMessage } from "components";

// TODO Move into DB
export const OFFICEHOLDER_OPTIONS = [
  {
    label: "appHolders.director",
    id: 1,
  },
  {
    label: "appHolders.secretary",
    id: 2,
  },
  {
    label: "appHolders.director&secretary",
    id: 3,
  },
].map((item) => ({
  ...item,
  label: <FormattedMessage id={item.label} />,
}));

// TODO Move into DB
export const SB_OPTIONS = [
  {
    label: "common.individual",
    id: 1,
  },
  {
    label: "common.company",
    id: 2,
  },
].map((item) => ({
  ...item,
  label: <FormattedMessage id={item.label} />,
}));
