import { useState, useEffect, FC } from "react";
import {
  Tabs,
  FormattedMessage,
  Empty,
  Login,
  NewAccount,
  PreLoader,
} from "components";
import { routes } from "configs";
import { useSelector, useNavigate } from "hooks";
import { getAccountSlug } from "utils";

const CreateApp: FC<{ subscriptionActive: boolean }> = ({
  subscriptionActive,
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("create");
  const { accountSlugs, loading, account } = useSelector((state) => ({
    account: state.auth.account,
    accountSlugs: state.appState.accountSlugs,
    loading: state.appState.loading,
  }));

  useEffect(() => {
    if (!subscriptionActive && account) {
      navigate(routes.dashboard);
    }
  }, [subscriptionActive, navigate, account]);

  if (loading) {
    return <PreLoader size="large" />;
  }

  const slug = getAccountSlug(accountSlugs);

  if (!slug) {
    return (
      <Empty
        description={<FormattedMessage id="messages.account.wrongURL" />}
      />
    );
  }

  return (
    <Tabs
      activeKey={activeTab}
      centered
      onTabClick={(tab) => {
        setActiveTab(tab);
      }}
      items={[
        {
          key: "create",
          label: <FormattedMessage id="button.create" />,
          children: (
            <NewAccount
              onAccountExists={() => {
                setActiveTab("login");
              }}
            />
          ),
        },
        {
          key: "login",
          label: <FormattedMessage id="button.login" />,
          children: <Login />,
        },
      ]}
    />
  );
};

export default CreateApp;
