import { FC, ReactNode } from "react";
import { Descriptions, DescriptionsProps, Empty, Spin, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { XOR } from "types";

interface ExtendedDescriptionsItem {
  value: ReactNode;
  copyable?: boolean;
}

interface ExtendedDescriptionsProps
  extends Omit<DescriptionsProps, "title" | "items"> {
  title?: string;
  translateTitle?: boolean;
  loading?: boolean;
  items?: XOR<
    ExtendedDescriptionsItem & { label: string; translate: boolean },
    ExtendedDescriptionsItem & { label: ReactNode }
  >[];
}

const ExtendedDescriptions: FC<ExtendedDescriptionsProps> = ({
  items = [],
  size = "small",
  layout = "vertical",
  bordered = true,
  title,
  translateTitle = true,
  loading = false,
  ...props
}) => {
  return (
    <Spin spinning={loading}>
      <Descriptions
        title={
          title && translateTitle ? <FormattedMessage id={title} /> : title
        }
        bordered={bordered}
        layout={layout}
        size={size}
        contentStyle={{ fontWeight: "bold" }}
        {...props}
      >
        {items.length ? (
          items.map(({ label, value, copyable, translate = true }, i) => (
            <Descriptions.Item
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              label={
                translate && typeof label === "string" ? (
                  <FormattedMessage id={label} />
                ) : (
                  label
                )
              }
            >
              <Typography.Text
                copyable={typeof copyable !== "undefined" ? copyable : !!value}
              >
                {value || "-"}
              </Typography.Text>
            </Descriptions.Item>
          ))
        ) : (
          <Descriptions.Item>
            <Empty />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Spin>
  );
};

export default ExtendedDescriptions;
