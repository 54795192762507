import { useEffect, useRef, useState } from "react";
import * as H from "hooks";
import { routes } from "configs";
import {
  getAccountSlug,
  qs,
  createUseStyles,
  transformAppID,
  getQueryKey,
} from "utils";
import { sendInteractionEmail } from "requests";
import {
  Empty,
  FormattedMessage,
  ExtendedSteps,
  VerificationSkeleton,
  Card,
  Tabs,
} from "components";
import * as LC from "./components";
import { operations, constants } from "./duck";
import { Application, Account } from "types";

export const useStyles = createUseStyles({
  root: {
    marginTop: 14,
  },
});

const applicationWrapperHOC =
  <TApp extends Application>(Component: any, appTypeID: 1 | 2 | 3) =>
  (props: any) => {
    const classes = useStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const stepRequests = useRef<Record<number, boolean>>({});

    const [params] = H.useQueryParams();
    const location = H.useLocation();
    const navigate = H.useNavigate();
    const showNotification = H.useNotification();
    const [companyTrustee, setCompanyTrustee] = useState(false);

    const { isAuthenticated, currentClientRecord, account, accountSlugs } =
      H.useSelector((rootState) => {
        const client = rootState.auth.currentClientRecord;

        return {
          account: rootState.auth.account as Account,
          currentClientRecord: client,
          isAuthenticated: !!client,
          accountSlugs: rootState.appState.accountSlugs,
        };
      });

    const search = qs.parse(location.search) as {
      appId: string;
    } | null;
    const appId = search && "appId" in search ? search.appId : null;

    const appQueryKey = getQueryKey(
      "application",
      appId && transformAppID(appId)
    );
    const accountSlug = getAccountSlug(accountSlugs);
    const { data, isLoading } = H.useQuery<TApp>({
      enabled: !account?.disabledLive && !!appId,
      queryKey: appQueryKey,
      apiName: "applications",
      path: `/${appId}`,
      options: {
        queryParams: {
          accountSlug,
        },
      },
    });

    const { mutate: createApplication, isPending: appCreating } = H.useMutation<
      TApp,
      {
        typeId: number;
        clientId: number;
        accountSlug: string;
        appURL: string;
      }
    >({
      method: "post",
      apiName: "applications",
      invalidateQueries: [
        getQueryKey("applicationsList", currentClientRecord?.id),
      ],
      options: {
        queryParams: {
          accountSlug,
        },
      },
      onSuccess: (newApp) => {
        const url = routes.openApplication(newApp);

        sendInteractionEmail({
          account: account?.account_name,
          interaction: "New Live Application - User Loaded",
          data: {
            clientID: currentClientRecord?.id,
            fullName: `${currentClientRecord?.first_name} ${currentClientRecord?.last_name}`,
          },
        });

        navigate(url, { replace: true });
      },
      onError: (e) => {
        const submitted = e.message.includes("submitted");

        showNotification({
          type: "error",
          message: submitted
            ? "messages.account.submittedError"
            : "messages.notFound",
        });

        navigate(routes.applications, { replace: true });
      },
    });

    useEffect(() => {
      if (account?.disabledLive) {
        showNotification({
          type: "warning",
          message: "messages.featureDisabled",
        });

        navigate(routes.login, { replace: true });

        return;
      }

      if (!isAuthenticated && currentStep !== 0) {
        setCurrentStep(0);
        return;
      }

      if (currentClientRecord && !appId) {
        createApplication({
          typeId: appTypeID,
          clientId: currentClientRecord.id,
          accountSlug: accountSlug as string,
          appURL: window.location.href,
        });
      }
      // eslint-disable-next-line
    }, [isAuthenticated, account]);

    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };

    // an application won't be fetched without account
    if (!data && !isLoading && !appCreating) {
      return (
        <Empty description={<FormattedMessage id="messages.notFound" />} />
      );
    }

    if (data?.statusId === 2) {
      return (
        <Card title={<FormattedMessage id="applications.details" />}>
          <Tabs
            defaultActiveKey={params.tab}
            items={[
              {
                key: "summary",
                label: <FormattedMessage id="common.summary" />,
                children: <LC.Summary app={data} />,
              },
              {
                key: "messaging",
                label: <FormattedMessage id="common.messaging" />,
                children: <LC.Messaging appID={data.id} />,
              },
            ]}
          />
        </Card>
      );
    }

    const onNextClick = async () => {
      if (!stepRequests.current[currentStep]) {
        sendInteractionEmail({
          account: account?.account_name,
          interaction: `New Live Account - Next Step Trigger from ${currentStep}`,
          data: {
            applicationId: data?.id,
          },
        });

        stepRequests.current[currentStep] = true;
      }

      nextStep();
    };

    const steps = operations.getSteps({
      account,
      appTypeID,
      statusId: data?.statusId,
      companyTrustee,
    });

    return (
      <Card
        className={classes.root}
        title={
          <FormattedMessage
            id="applications.create"
            values={{
              type: <FormattedMessage id={constants.APP_TITLES[appTypeID]} />,
            }}
          />
        }
      >
        <ExtendedSteps
          loading={!data}
          currentStep={currentStep}
          steps={steps}
        />
        {!data || appCreating ? (
          <VerificationSkeleton />
        ) : (
          <Component
            {...props}
            app={data}
            appQueryKey={appQueryKey}
            currentStep={currentStep}
            steps={steps}
            onNextClick={onNextClick}
            nextStep={nextStep}
            companyTrustee={companyTrustee}
            setCompanyTrustee={(trusteeTypeID: number) =>
              setCompanyTrustee(trusteeTypeID === 2)
            }
            onPrevClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          />
        )}
      </Card>
    );
  };

export default applicationWrapperHOC;
