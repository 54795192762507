import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import * as C from "components";
import { LoginPopup, Logo } from "./components";
import Menu from "../menu";
import { appStateAsyncActions, authAsyncActions } from "configs";
import { useSelector, useDispatch, useLocation } from "hooks";

const useStyles = createUseStyles({
  header: {
    padding: "0 24px",
    background: "#fff",
    height: "auto",
    borderBottom: "1px solid #f0f0f0",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
});

const options = [
  {
    value: "en",
    countryCode: "GB",
    ariaLabel: "English Language",
    text: " English",
  },
  {
    value: "zh",
    countryCode: "CN",
    ariaLabel: "Chinese Simplified Language",
    text: " 简体中文",
  },
];

const Header = () => {
  const classes = useStyles();
  const {
    language,
    loggingOut,
    currentClientRecord,
    languageSwitching,
    mobileLayout,
  } = useSelector((state) => ({
    language: state.appState.language,
    languageSwitching: state.appState.languageSwitching,
    currentClientRecord: state.auth.currentClientRecord,
    mobileLayout: state.appState.breakpoint.mobile,
    loggingOut: state.auth.loggingOut,
  }));
  const authenticated = !!currentClientRecord;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [open, toggleOpen] = useState(false);

  useEffect(() => {
    toggleOpen(false);
  }, [authenticated, pathname]);

  const onLanguageSelect = (value: "en" | "zh") => {
    dispatch(
      appStateAsyncActions.updateLanguage({
        language: value,
        clientID: currentClientRecord?.id,
      })
    );

    localStorage.setItem("language", value);
  };

  return (
    <C.Layout.Header className={classes.header}>
      <C.Row justify="space-between">
        <C.Col className={classes.logoWrapper}>
          <Logo />
        </C.Col>
        <C.Col>
          <C.Space>
            <C.InputSelect
              isFormItem={false}
              value={language}
              style={{ width: "100%" }}
              options={options}
              onChange={onLanguageSelect}
              loading={languageSwitching}
              getOptionProps={(option: any) => ({
                value: option.value,
                children: (
                  <>
                    <C.ReactCountryFlag
                      countryCode={option.countryCode}
                      style={{
                        fontSize: "1.5em",
                        lineHeight: "1.5em",
                      }}
                      aria-label={option.ariaLabel}
                    />
                    {option.text}
                  </>
                ),
              })}
            />
            {authenticated && mobileLayout && (
              <C.Button
                type="primary"
                icon={<C.MenuOutlined />}
                onClick={() => toggleOpen(true)}
              />
            )}
            {authenticated && !mobileLayout && (
              <C.Button
                type="primary"
                loading={loggingOut}
                icon={<C.LogoutOutlined />}
                onClick={() => dispatch(authAsyncActions.logOut())}
              />
            )}
            {!authenticated && <LoginPopup />}
          </C.Space>
        </C.Col>
      </C.Row>
      {authenticated && (
        <C.Drawer
          open={open}
          onClose={() => toggleOpen(false)}
          placement="right"
          bodyStyle={{ padding: 0 }}
          title={
            <C.Row justify="end">
              <C.Button
                type="primary"
                loading={loggingOut}
                icon={<C.LogoutOutlined />}
                onClick={() => dispatch(authAsyncActions.logOut())}
              />
            </C.Row>
          }
        >
          <Menu />
        </C.Drawer>
      )}
    </C.Layout.Header>
  );
};

export default Header;
