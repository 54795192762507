import { useState, FC } from "react";
import { Row, Col, Form, Space, Button, Typography } from "antd";
import InputText from "components/input-text";
import InputEmail from "components/input-email";
import InputPassword from "components/input-password";
import VerifyPassword from "components/verifiy-password";
import { FormattedMessage } from "react-intl";
import { API, createUseStyles } from "utils";
import { useSelector, useNotification, useLocation } from "hooks";
import { sendInteractionEmail } from "requests";
import { routes } from "configs";

const useStyles = createUseStyles({
  newAccount: {
    paddingBottom: 24,
    textAlign: "center",
  },
  inputWrapper: {
    "@media (min-width: 768px)": {
      maxWidth: 250,
    },
  },
  title: {
    display: "block",
    textAlign: "center",
  },
  textWrapper: {
    textAlign: "center",
    marginBottom: 24,
  },
});

interface State {
  confirmCodeSent: boolean;
  submitting: boolean;
  existingAccount: boolean;
}

interface NewAccountProps {
  email?: string;
  onAccountExists: () => void;
}

const NewAccount: FC<NewAccountProps> = ({ email, onAccountExists }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState<State>({
    submitting: false,
    confirmCodeSent: false,
    existingAccount: false,
  });
  const location = useLocation();
  const classes = useStyles();
  const showNotification = useNotification();
  const account = useSelector((rootState) => rootState.auth.account);
  const isVerifyExternal = location.pathname.includes(
    routes.verifyExternal.main
  );

  if (state.confirmCodeSent) {
    const values = form.getFieldsValue(["password", "email"]);
    return <VerifyPassword {...values} />;
  }

  const onFinish = async (values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }) => {
    if (!account) {
      showNotification({
        type: "error",
        message: "messages.account.notFound",
      });
      return;
    }

    try {
      setState((prevState) => ({
        ...prevState,
        submitting: true,
      }));

      const newUser = await API.post({
        apiName: "clients",
        path: "/register",
        options: {
          body: {
            ...values,
            account_id: account.id,
          },
        },
      });

      sendInteractionEmail({
        account: account.account_name,
        interaction: "Create Database User",
        data: {
          ...values,
          id: newUser.id,
          aws_cognito_id: newUser.aws_cognito_id,
          account_id: account.id,
        },
      });

      setState((prevState) => ({
        ...prevState,
        submitting: false,
        confirmCodeSent: true,
      }));
    } catch (e) {
      if (e.message.toLowerCase().indexOf("exists") !== -1) {
        showNotification({
          type: "info",
          message: "messages.accountExists",
        });

        onAccountExists();
      } else {
        let message = "messages.requestFailed";

        if (e.message.includes("pattern")) {
          message = "validation.password";
        }

        showNotification({
          type: "error",
          message,
        });
      }

      setState((prevState) => ({
        ...prevState,
        submitting: false,
      }));
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ email }}
      className={classes.newAccount}
    >
      <div className={classes.textWrapper}>
        <FormattedMessage
          id={
            isVerifyExternal
              ? "verifyExternal.createPageTitle"
              : "applications.createNew"
          }
          values={{
            br: <br />,
            b: (chunks) => <strong>{chunks}</strong>,
            title: (chunks) => (
              <Typography.Text className={classes.title}>
                {chunks}
              </Typography.Text>
            ),
            p: (chunks) => (
              <Typography.Paragraph type="secondary">
                {chunks}
              </Typography.Paragraph>
            ),
          }}
        />
      </div>

      <Row justify="center">
        <Col className={classes.inputWrapper}>
          <InputText required label="common.first_name" name="first_name" />
          <InputText required label="common.last_name" name="last_name" />
          <InputEmail
            required
            disabled={!!email}
            label="common.emailAddress"
            name="email"
          />
          <InputPassword required label="common.password" name="password" />
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit" loading={state.submitting}>
              <FormattedMessage id="applications.newAccount.sendCode" />
            </Button>
            <Typography.Text type="secondary">
              <FormattedMessage id="applications.newAccount.clickSendCode" />
            </Typography.Text>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default NewAccount;
