import * as React from "react";
import { useForm, useNotification, useQuery } from "hooks";
import { COMMON_COL_PROPS, DATE_FORMAT } from "consts";
import { constants, Types } from "./duck";
import { getQueryKey } from "utils";
import * as C from "components";
import * as LC from "./components";
import dayjs from "dayjs";
import { Officeholder } from "types";

const AppHolders: React.FC<Types.AppHoldersProps> = ({
  streetTypes,
  countries,
  accountName,
  appId,
  kind,
  onNextClick,
  ...btnProps
}) => {
  const [form] = useForm();
  const showNotification = useNotification();
  const [modalVisible, toggleModal] = React.useState(false);
  const queryKey = getQueryKey(kind, appId) as string[];

  const { data, isLoading } = useQuery<Officeholder[]>({
    apiName: "applications",
    path: `/${appId}/${kind}`,
    queryKey,
  });

  const required = !data?.length;

  return (
    <C.Form
      form={form}
      layout="vertical"
      preserve={false}
      initialValues={{
        typeId: 1,
        beneficiallyHeld: true,
      }}
      onFinish={() => {
        toggleModal(true);
      }}
    >
      <C.Row gutter={[14, 14]} style={{ marginBottom: 20 }}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id={`appHolders.${kind}`} />
          </C.Typography.Title>
          <C.InputSelect
            required
            name="typeId"
            label={`appHolders.${kind}Type`}
            options={
              kind === "officeholders"
                ? constants.OFFICEHOLDER_OPTIONS
                : constants.SB_OPTIONS
            }
            getOptionProps={(option: any) => ({
              children: option.label,
              value: option.id,
            })}
          />
          {kind !== "officeholders" ? (
            <>
              <C.Form.Item dependencies={["typeId"]}>
                {({ getFieldValue }) => {
                  const typeId = getFieldValue("typeId");

                  if (typeId === 1) {
                    return <LC.IndividualFields />;
                  }

                  if (typeId === 2) {
                    return <LC.CompanyFields />;
                  }
                }}
              </C.Form.Item>
              <C.InputGroup
                label="applications.common.beneficiallyHeld"
                name="beneficiallyHeld"
              />
            </>
          ) : (
            <LC.IndividualFields />
          )}
          <C.InputEmail label="common.emailAddress" name="email" required />
          <C.PhoneInput label="phone.title" name="telephone" />
          <C.InputDatePicker
            label="common.dateOfBirth"
            name="dateOfBirth"
            isAfterToday
          />
          <C.Button type="primary" htmlType="submit">
            <C.FormattedMessage id="button.create" />
          </C.Button>
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.StreetAddress
            required={false}
            countries={countries}
            streetTypes={streetTypes}
          />
        </C.Col>
      </C.Row>
      <C.ExtendedTable<Officeholder>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.office",
            dataIndex: "type",
            render: ({ highlighter }) => {
              return <C.Tag color="green">{highlighter}</C.Tag>;
            },
          },
          {
            title: "common.first_name",
            dataIndex: "firstName",
            render: ({ value, record }) => {
              if (record.typeId !== 1) {
                // TODO
                // eslint-disable-next-line
                // @ts-ignore
                return record.companyContactFirstName;
              }

              return value;
            },
          },
          {
            title: "common.last_name",
            dataIndex: "lastName",
            render: ({ value, record }) => {
              if (record.typeId !== 1) {
                // TODO
                // eslint-disable-next-line
                // @ts-ignore
                return record.companyContactLastName;
              }

              return value;
            },
          },
          {
            title: "common.dateOfBirth",
            dataIndex: "dateOfBirth",
            render: ({ value }) => value && dayjs(value).format(DATE_FORMAT),
          },
          {
            title: "common.actions",
            dataIndex: "id",
            key: "action",
            align: "right",
            render: ({ value }) => (
              <LC.RemoveButton
                appId={appId}
                kind={kind}
                recordID={value}
                queryKey={queryKey}
              />
            ),
          },
        ]}
      />
      <C.StepsButtons
        {...btnProps}
        onNextClick={() => {
          if (required) {
            const LABELS = {
              officeholders: "officeholder",
              shareholders: "shareholder",
              beneficiaries: "beneficiary",
            };

            showNotification({
              type: "warning",
              message: "appHolders.noRecords",
              messageValues: {
                type: {
                  value: `appHolders.${LABELS[kind]}`,
                  translate: true,
                },
              },
            });

            return;
          }

          onNextClick?.();
        }}
      />
      <LC.ConfirmModal
        form={form}
        appId={appId}
        kind={kind}
        queryKey={queryKey}
        visible={modalVisible}
        accountName={accountName}
        onCancel={() => toggleModal(false)}
      />
    </C.Form>
  );
};

export default AppHolders;
