import { FC, useState } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { useSelector, useDeleteMutation } from "hooks";
import { GatTrader, GatMoney } from "./components";
import {
  FormattedMessage,
  Typography,
  StepsButtons,
  InputGroup,
  Divider,
  Row,
  Col,
} from "components";
import { StepButtonsProps, Application, Account } from "types";
import { Types } from "../../../duck";

interface OptionalProps
  extends Pick<
      StepButtonsProps<Application & { optionalID: number }>,
      "steps" | "currentStep" | "onPrevClick"
    >,
    Pick<Types.ChildAppWrapperProps<any>, "nextStep" | "appQueryKey"> {
  application: Application;
  account: Account;
}

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
  },
  row: {
    marginTop: 18,
  },
  inputGroup: {
    marginTop: 14,
  },
});

const Optional: FC<OptionalProps> = ({
  application,
  account,
  steps,
  currentStep,
  nextStep,
  onPrevClick,
  appQueryKey,
}) => {
  const classes = useStyles();
  const [optionalAdded, addOptional] = useState(!!application.optionalID);
  const { accounts, mobile } = useSelector(({ appState }) => ({
    accounts: appState.accounts,
    mobile: appState.breakpoint.mobile,
  }));

  const {
    mutateAsync: deleteOptional,
    queryClient,
    isPending: deleting,
  } = useDeleteMutation({
    apiName: "applications",
    path: `/${application.id}/optional/${application.optionalID}`,
    onSuccess: () => {
      queryClient.setQueryData<Application>(appQueryKey, (oldData) => ({
        ...(oldData as Application),
        optionalID: null,
      }));
    },
  });

  const onSubmit = async (optionalID?: number) => {
    if (!optionalAdded && application.optionalID) {
      await deleteOptional();
    } else if (optionalID) {
      queryClient.setQueryData<Application>(appQueryKey, (oldData) => ({
        ...(oldData as Application),
        optionalID,
      }));
    }

    nextStep();
  };

  const btnProps = {
    steps,
    currentStep,
    onPrevClick,
    nextDisabled: false,
    prevDisabled: false,
  };

  return (
    <>
      <Row gutter={ROW_GUTTER} className={classes.row}>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5} className={classes.title}>
            <FormattedMessage
              id="applications.optionalTitle"
              values={{
                accountName: accounts.find(
                  (item) => item.id === account.optionalAccountID
                )?.account_name,
              }}
            />
          </Typography.Title>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputGroup
            withoutWrapper
            value={optionalAdded}
            className={classNames({ [classes.inputGroup]: mobile })}
            onChange={() => {
              addOptional(!optionalAdded);
            }}
          />
        </Col>
      </Row>
      <Divider />
      {account.optionalAccountID === 18 ? (
        <GatMoney
          optionalAccountID={account.optionalAccountID}
          application={application}
          addAccount={optionalAdded}
          onSubmit={onSubmit}
        >
          {({ loading }) => (
            <StepsButtons {...btnProps} loading={loading || deleting} />
          )}
        </GatMoney>
      ) : (
        <GatTrader
          addAccount={optionalAdded}
          accountName={account.account_name}
          application={application}
          onSubmit={onSubmit}
        >
          {({ loading }) => (
            <StepsButtons {...btnProps} loading={loading || deleting} />
          )}
        </GatTrader>
      )}
    </>
  );
};

export default Optional;
