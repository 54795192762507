import { ReconnectingWebSocket } from "utils";
import { ApplicationMessage } from "types";
import { OnOpen, OnMessage, Emit } from "./types";
import { STAGE, SOCKET_URL } from "consts";
import { sendInteractionEmail } from "requests";

export const arrByID = (messages: ApplicationMessage[]) =>
  messages.reduce<[number, ApplicationMessage][]>(
    (result, message) => [...result, [message.id, message]],
    []
  );
export const arrByQueueID = (messages: ApplicationMessage[]) =>
  messages.reduce<[string, ApplicationMessage][]>(
    (result, message) => [...result, [message.queueID, message]],
    []
  );

export const readMessages = ({
  messages,
  cb,
}: {
  messages: ApplicationMessage[];
  cb: (ids: number[]) => void;
}) => {
  try {
    const messageIDs = messages
      .filter((message) => !message.readAt && message.createdBy === "EAFA")
      .map((message) => message.id);

    if (messageIDs.length) {
      cb(messageIDs);
    }
  } catch (e) {
    sendInteractionEmail({
      interaction: "EAF Admin. Read messages",
      data: {
        message: `Failed to read message ${JSON.stringify(e)}`,
      },
    });
  }
};

export const connectSocket = ({
  onMessage,
  onError,
  onOpen,
}: {
  onMessage: OnMessage;
  onError?: (e: Event) => void;
  onOpen: OnOpen;
}) => {
  // eslint-disable-next-line
  // @ts-ignore
  const ws = new ReconnectingWebSocket(SOCKET_URL, null, {
    debug: STAGE !== "prod",
    reconnectInterval: 2000,
    reconnectAttempts: 10,
  });

  ws.onopen = () => {
    const data = onOpen();

    // console.log('WS HAS Opened!  ', data)

    ws.send(
      JSON.stringify({
        action: "open",
        data,
      })
    );
  };

  // ws.onclose = (event) => {
  //   console.log('socket closed: ', event)
  // };

  ws.onerror = (e) => {
    onError?.(e);

    // showNotification({
    //   type: "error",
    //   language,
    //   message: "messages.error.failSocketConnection",
    // });
  };

  ws.onmessage = ({ data }) => {
    const messageObj = JSON.parse(data);
    onMessage(messageObj);
  };

  // let countReconnect = 0;
  // let timer: number;
  const emit: Emit = (data, action) => {
    try {
      ws.send(
        JSON.stringify({
          action,
          data,
        })
      );
    } catch (e) {
      // console.log(e)
      // ws.refresh();
      // showNotification({
      //   type: "error",
      //   language,
      //   message: "messages.error.pageReload",
      // });
    }
  };

  return {
    emit,
    ws,
  };
};

export default connectSocket;
