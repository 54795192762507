import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { List } from "antd";

interface GuidelinesListProps {
  className: string;
  dataSource: string[];
  header: string;
}

const GuidelinesList: FC<GuidelinesListProps> = ({
  className,
  dataSource,
  header,
}) => {
  return (
    <List
      bordered
      className={className}
      dataSource={dataSource}
      header={
        <strong>
          <FormattedMessage id={header} />
        </strong>
      }
      renderItem={(str, index) => (
        <List.Item key={str}>
          {index + 1}: <FormattedMessage id={str} />
        </List.Item>
      )}
    />
  );
};

export default GuidelinesList;
