import * as React from "react";
import { Skeleton, Divider, Row, Col } from "antd";
import { COMMON_COL_PROPS } from "consts";

const SkeletonFinancial: React.FC = () => {
  return (
    <>
      <Skeleton title active paragraph={{ rows: 2 }} />
      <Divider />
      <Row gutter={[24, 24]}>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 10, width: 400 }}
          />

          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 10, width: 400 }}
          />

          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 10, width: 400 }}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            style={{ height: 25, width: 400, marginBottom: 24 }}
          />

          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Button active style={{ width: 55 }} />
          <Skeleton.Button
            active
            style={{ width: 55, marginLeft: 5, marginBottom: 24 }}
          />

          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Button active style={{ width: 55 }} />
          <Skeleton.Button active style={{ width: 55, marginLeft: 5 }} />
        </Col>
      </Row>
    </>
  );
};

export default SkeletonFinancial;
