import { routes } from "configs";
import * as Pages from "pages";
import { dashboardHOC } from "hocs";
import { AccountSlug } from "types";

export const getSecuredRoutes = ({
  accountSlugs,
}: {
  accountSlugs: AccountSlug[];
}) => [
  {
    path: routes.dashboard,
    Component: dashboardHOC({
      title: "dashboard.title",
      description: "dashboard.contactInfo",
      Component: Pages.Dashboard,
    }),
  },
  {
    path: routes.userProfile,
    Component: dashboardHOC({
      title: "userProfile.title",
      Component: Pages.UserProfile,
    }),
  },
  {
    path: routes.applications,
    Component: dashboardHOC({
      title: "applications.title",
      description: "dashboard.applications",
      Component: Pages.ApplicationsList,
    }),
  },
  {
    Component: Pages.IndividualApp,
    path: accountSlugs.map((item) =>
      routes.openApplication({
        typeId: 1,
        accountSlug: item.slug,
      })
    ),
  },
  {
    Component: Pages.CompanyApp,
    path: accountSlugs.map((item) =>
      routes.openApplication({
        typeId: 2,
        accountSlug: item.slug,
      })
    ),
  },
  {
    Component: Pages.TrustApp,
    path: accountSlugs.map((item) =>
      routes.openApplication({
        typeId: 3,
        accountSlug: item.slug,
      })
    ),
  },
  {
    path: routes.verifyExternal.list(),
    Component: dashboardHOC({
      title: "verifyExternal.title",
      Component: Pages.verifyExternal.List,
    }),
  },
];

export const getOpenRoutes = ({
  authenticated,
  accountSlugs,
}: {
  authenticated: boolean;
  accountSlugs: AccountSlug[];
}) => [
  {
    path: routes.informationRequest.view(),
    Component: Pages.InformationRequest,
  },
  {
    Component: Pages.AccountDemo,
    path: accountSlugs.map((item) => routes.openDemoAccount(item.slug)),
  },
  {
    Component: Pages.AccountClosure,
    path: accountSlugs.map((item) => routes.openAccountClosure(item.slug)),
  },
  {
    Component: Pages.ChoseApp,
    path: accountSlugs.map((item) => routes.chooseApplication(item.slug)),
  },
  {
    path: routes.verifyExternal.view(),
    Component: dashboardHOC({
      title: `verifyExternal.${authenticated ? "details" : "title"}`,
      Component: Pages.verifyExternal.View,
    }),
  },
];
