import * as React from "react";
import * as C from "components";
import * as Types from "types";
import { COMMON_COL_PROPS } from "consts";
import { useForm, useChildAPI, useMutation, useGetAccountSlug } from "hooks";

interface TrustProps extends Types.StepButtonsProps<Types.TrustApplication> {
  countries: Types.Country[];
  app: Types.TrustApplication;
  streetTypes: Types.TranslatedRecord[];
  setCompanyTrustee: (trusteeTypeID: number) => void;
  appQueryKey: string[];
}

const Trust: React.FC<TrustProps> = ({
  countries,
  streetTypes,
  setCompanyTrustee,
  app,
  onNextClick,
  appQueryKey,
  ...btnProps
}) => {
  const [form] = useForm();
  const {
    data: streetAddress,
    fetching: fetchingStreetAddress,
    saving: savingStreetAddress,
    save: saveStreetAddress,
  } = useChildAPI<Types.StreetAddress>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "street-address",
  });
  const {
    data: phone,
    fetching: fetchingPhone,
    saving: savingPhone,
    save: savePhone,
  } = useChildAPI<Types.Phone>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "phone",
  });
  const accountSlug = useGetAccountSlug();
  const { mutateAsync: updateApp, isPending: updatingApp } = useMutation<
    Types.TrustApplication,
    Partial<Types.TrustApplication>
  >({
    apiName: "applications",
    path: `/${app.id}`,
    options: {
      queryParams: {
        accountSlug,
      },
    },
    invalidateQueries: [appQueryKey],
    method: "patch",
  });

  if (fetchingStreetAddress || fetchingPhone) {
    return <C.Skeleton active loading />;
  }

  return (
    <C.Form
      form={form}
      layout="vertical"
      initialValues={{
        ...(streetAddress || {}),
        ...(phone || {}),
        ...app,
      }}
      onValuesChange={(values: any) => {
        const [key, value] = Object.entries<number>(values)[0];

        if (key === "trusteeTypeId") {
          setCompanyTrustee(value);
        }
      }}
      onFinish={async (values) => {
        if (!form.isFieldsTouched()) {
          onNextClick?.();
          return;
        }

        const dynamicValues =
          values.trusteeTypeId === 1
            ? {
                trusteeFirstName: values.trusteeFirstName,
                trusteeLastName: values.trusteeLastName,
              }
            : {
                trusteeCompanyName: values.trusteeCompanyName,
                trusteeCompanyNumber: values.trusteeCompanyNumber,
              };

        await Promise.all([
          saveStreetAddress(values),
          savePhone(values),
          updateApp({
            trustName: values.trustName,
            trusteeTypeId: values.trusteeTypeId,
            ...dynamicValues,
          }),
        ]);

        onNextClick?.({
          trustName: values.trustName,
          trusteeTypeId: values.trusteeTypeId,
          ...dynamicValues,
        });
      }}
    >
      <C.Row gutter={[14, 14]}>
        <C.Col {...COMMON_COL_PROPS}>
          <C.Typography.Title level={4}>
            <C.FormattedMessage id="common.trust" />
          </C.Typography.Title>
          <C.InputText
            label="applications.trust.name"
            name="trustName"
            required
          />
          <C.InputGroup
            label="applications.trust.type"
            name="trusteeTypeId"
            optionType="default"
            options={[
              {
                label: "common.individual",
                value: 1,
              },
              {
                label: "common.company",
                value: 2,
              },
            ]}
          />
          <C.Form.Item dependencies={["trusteeTypeId"]}>
            {({ getFieldValue }) => {
              const trusteeTypeId = getFieldValue("trusteeTypeId");

              let firstNameLabel: string;
              let firstNameName: string;
              let lastNameLabel: string;
              let lastNameName: string;

              if (trusteeTypeId === 1) {
                firstNameLabel = "applications.trust.trusteeFirstName";
                firstNameName = "trusteeFirstName";

                lastNameLabel = "applications.trust.trusteeLastName";
                lastNameName = "trusteeLastName";
              } else {
                firstNameLabel = "applications.trust.trusteeCompanyName";
                firstNameName = "trusteeCompanyName";

                lastNameLabel = "applications.trust.trusteeCompanyNumber";
                lastNameName = "trusteeCompanyNumber";
              }

              return (
                <>
                  <C.InputText
                    required
                    name={firstNameName}
                    label={firstNameLabel}
                  />
                  <C.InputText
                    required
                    name={lastNameName}
                    label={lastNameLabel}
                  />
                </>
              );
            }}
          </C.Form.Item>
          <C.Phone />
        </C.Col>
        <C.Col {...COMMON_COL_PROPS}>
          <C.StreetAddress countries={countries} streetTypes={streetTypes} />
          <C.StepsButtons
            {...btnProps}
            loading={savingStreetAddress || savingPhone || updatingApp}
          />
        </C.Col>
      </C.Row>
    </C.Form>
  );
};

export default Trust;
