const question1 = [
  {
    label:
      "a. Speculate on the change in the value of an asset by owning the underlying asset",
    value: "a",
  },
  {
    label:
      "b. Speculate on the change in the value of an asset without owning it",
    value: "b",
  },
  {
    label:
      "c. Speculate on the difference between the CFD price and the underlying asset price",
    value: "c",
  },
  {
    label: "d. Speculate on all of the above",
    value: "d",
  },
];
const question2 = [
  {
    label: "a. When margin is equal to the account balance",
    value: "a",
  },
  {
    label:
      "b. When equity is less that the margin and the trade is still going against the trader",
    value: "b",
  },
  {
    label:
      "c. When losing trades are automatically closed due to lack of capital in the trading account",
    value: "c",
  },
  {
    label: "d When margin is equal to 100$",
    value: "d",
  },
];
const question3 = [
  {
    label: "a. An order that needs to be filled by the end of the day",
    value: "a",
  },
  {
    label: "b. An order buy/sell a limited number of lots",
    value: "b",
  },
  {
    label: "c. An order buy/sell at a set price",
    value: "c",
  },
  {
    label: "d. None of the above",
    value: "d",
  },
];
const question4 = [
  {
    label:
      "a. Selling securities that the investor has borrowed and prepared to buy back at a later date",
    value: "a",
  },
  {
    label: "b. A trading strategy used to profit from a decline",
    value: "b",
  },
  {
    label: "c. Not selling enough securities from a sell order placed",
    value: "c",
  },
  {
    label: "d. Both A & B",
    value: "d",
  },
  {
    label: "e. Both A & C",
    value: "e",
  },
];

export const QUESTIONS = [
  {
    options: question1,
    name: "knowledgeQuizQ1",
    label:
      "A Contract For Difference (CFD) is a contract that allows clients to:",
    answer: "b",
  },
  {
    options: question2,
    name: "knowledgeQuizQ2",
    label: "When does a trader receive a margin call from the broker?",
    answer: "b",
  },
  {
    options: question3,
    name: "knowledgeQuizQ3",
    label: "What is a limit order?",
    answer: "c",
  },
  {
    options: question4,
    name: "knowledgeQuizQ4",
    label: "What does short selling mean?",
    answer: "d",
  },
];
