import { FC } from "react";
import { useChildAPI } from "hooks";
import {
  Personal,
  Skeleton,
  Form,
  StepsButtons,
  InputText,
  Row,
  Col,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { VerifyExternalChildProps } from "../../types";
import { Personal as PersonalInterface } from "types";

const PersonalDetails: FC<VerifyExternalChildProps> = ({
  onNextClick,
  onPrevClick,
  currentStep,
  steps,
  parentUUID,
}) => {
  const [form] = Form.useForm();

  const { data, fetching, saving, save } = useChildAPI<PersonalInterface>({
    parentRoute: "verifyExternal",
    childRoute: "personal",
    parentID: parentUUID as string,
    enabled: !!parentUUID,
  });

  if (fetching) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
      onFinish={async (values) => {
        if (form.isFieldsTouched()) {
          await save(values);
        }

        onNextClick();
      }}
    >
      <Personal showTitle={false} />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="firstName" label="common.first_name" />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="lastName" label="common.last_name" />
        </Col>
      </Row>
      <StepsButtons
        loading={saving}
        currentStep={currentStep}
        onPrevClick={onPrevClick}
        steps={steps}
      />
    </Form>
  );
};

export default PersonalDetails;
