import { useRef, useEffect } from "react";
import { appStateAsyncActions } from "configs";
import { useDispatch, useSelector } from "./useReactRedux";
import { Language } from "types";

const useWatchCountries = () => {
  const { accountId, language, countriesEmpty } = useSelector((state) => ({
    accountId: state.auth.account?.id,
    language: state.appState.language,
    countriesEmpty: !state.appState.countries.length,
  }));

  const prevLanguage = useRef<Language>(language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountId) {
      return;
    }

    if (prevLanguage.current !== language || countriesEmpty) {
      dispatch(
        appStateAsyncActions.getCountries({
          accountId,
        })
      ).then(() => {
        prevLanguage.current = language;
      });
    }
  }, [accountId, dispatch, language, countriesEmpty]);
};

export default useWatchCountries;
