import * as React from "react";
import InputText from "../input-text";
import { CountriesSelect, TranslationSelect } from "../input-select";
import GoogleAutocomplete from "../google-autocomplete";
import { FormattedMessage } from "react-intl";
import { Form, Row, Col, Typography } from "antd";
import { useSelector } from "hooks";
import * as Types from "types";

export interface StreetAddressProps {
  countries: Types.Country[];
  streetTypes: Types.TranslatedRecord[];
  required?: boolean;
}

const TRIPLE_COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 8,
};

const StreetAddress: React.FC<StreetAddressProps> = ({
  countries,
  streetTypes,
  required = true,
}) => {
  const language = useSelector((state) => state.appState.language);

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="streetAddress.title" />
      </Typography.Title>
      {language === "en" && (
        <Form.Item
          label={<FormattedMessage id="streetAddress.location" />}
          shouldUpdate
        >
          {({ setFieldsValue }) => (
            <GoogleAutocomplete
              disabledProp="disabledLive"
              countries={countries}
              streetTypes={streetTypes}
              onChange={(addresses) => {
                setFieldsValue(addresses);
              }}
            />
          )}
        </Form.Item>
      )}
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <InputText label="streetAddress.unitNumber" name="unitNumber" />
        </Col>
        <Col span={12}>
          <InputText label="streetAddress.streetNumber" name="streetNumber" />
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText
            label="streetAddress.streetName"
            name="streetName"
            required={required}
          />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <TranslationSelect
            required={required}
            label="streetAddress.streetType"
            name="streetTypeId"
            optionName="streetTypes"
          />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.suburb" name="suburb" />
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.state" name="state" />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.postcode" name="postcode" />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <CountriesSelect
            restricted
            required={required}
            name="countryId"
            label="common.country"
          />
        </Col>
      </Row>
    </>
  );
};

export default StreetAddress;
