import { InputText } from "components";

const Individual = () => {
  return (
    <>
      <InputText required name="firstName" label="common.first_name" />
      <InputText required name="lastName" label="common.last_name" />
    </>
  );
};

export default Individual;
