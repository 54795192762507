import InputGroup from "components/input-group";

const Knowledge = () => {
  return (
    <>
      <InputGroup name="tradedLeveraged" label="knowledge.tradedLeveraged" />
      <InputGroup name="tradedOptions" label="knowledge.tradedOptions" />
      <InputGroup name="qualification" label="knowledge.qualification" />
    </>
  );
};

export default Knowledge;
