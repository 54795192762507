import { FormattedMessage } from "react-intl";

export const ROOT_ID = "page-content";

export const ACCEPTED_FILES = ".pdf, .doc, .docx, .jpg, .jpeg, .png, .msword";

export const BREAKPOINTS = {
  MD: "768px",
};

export const MEDIA_QUERY = {
  mobile: `@media (max-width: ${BREAKPOINTS.MD})`,
};

export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const INPUT_GROUP_OPTIONS = [
  {
    label: "common.yes",
    value: true,
  },
  {
    label: "common.no",
    value: false,
  },
];

export const STREET_ADDRESS_KEYS = [
  "countryId",
  "streetTypeId",
  "unitNumber",
  "streetNumber",
  "streetName",
  "suburb",
  "state",
  "postcode",
];

export const PERSONAL_KEYS = [
  "email",
  "firstName",
  "lastName",
  "middleName",
  "dateOfBirth",
  "placeOfBirthCity",
  "placeOfBirthCountryId",
  "applicationId",
];

export const SHARE_HOLDER_BENEFICIARY_OPTIONS = [
  {
    label: <FormattedMessage id="common.individual" />,
    id: 1,
  },
  {
    label: <FormattedMessage id="common.company" />,
    id: 2,
  },
];
