import * as React from "react";
import { createUseStyles } from "react-jss";
import {
  Modal,
  Divider,
  Button,
  Form,
  Space,
  Radio,
  FormattedMessage,
} from "components";
import { constants } from "./duck";
import classNames from "classnames";

const useStyles = createUseStyles({
  form: {
    "& .ant-form-item-has-error .ant-radio-wrapper-checked > span:last-child": {
      color: "red",
      borderBottom: "transparent",
    },
    "& .ant-form-item-has-success .ant-radio-wrapper-checked > span:last-child":
      {
        color: "green",
      },
  },
  radioGroup: { "& .ant-radio-wrapper": { display: "flex" } },
});

interface QuizModalProps {
  loading: boolean;
  open: boolean;
  accountName: string;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const QuizModal: React.FC<QuizModalProps> = ({
  loading,
  open,
  accountName,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const classes = useStyles();

  return (
    <Modal
      centered
      footer={null}
      open={open}
      onCancel={onCancel}
      className={classes.form}
      title={
        <FormattedMessage
          id="applications.quizModalTitle"
          values={{ accountName }}
        />
      }
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        {constants.QUESTIONS.map(({ name, label, answer, options }) => (
          <>
            <Form.Item
              name={name}
              label={label}
              rules={[
                {
                  validator: (_: any, value: string) => {
                    if (value !== answer) {
                      return Promise.reject(answer);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Radio.Group
                options={options}
                className={classNames(classes.radioGroup)}
                size="large"
              />
            </Form.Item>
            <Divider />
          </>
        ))}
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Ok
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default QuizModal;
