import { API, getAccountSlug } from "utils";
import * as Types from "../../slices/auth/duck/types";
import { InitialData, AccountSlug } from "types";

export const getInitialData = async ({
  cognitoID,
  accountSlugs,
}: {
  cognitoID: string;
  accountSlugs: AccountSlug[];
}): Promise<InitialData> => {
  const client: Types.User = await API.get({
    apiName: "clients",
    path: `/${cognitoID}`,
  });

  const slug = getAccountSlug(accountSlugs);

  const [account, accounts] = await Promise.all([
    API.get({
      apiName: "accounts",
      path: slug ? `/slug/${slug}` : `/${client.account_id}`,
    }),
    API.get({ apiName: "accounts" }),
  ]);

  return {
    client,
    account,
    accounts,
  };
};
