import _ from "lodash";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import * as Types from "./types";
import { INITIAL_VALUES } from "./constants";

const getStreetId = (
  street: google.maps.GeocoderAddressComponent | undefined,
  streets: Types.TranslatedRecord[]
): { streetTypeId: number; routeType: string | undefined } | null => {
  if (!street || _.isEmpty(streets)) {
    return null;
  }

  const routeType = _.last<string>(street.long_name.split(" "));
  const found =
    routeType && streets.find((str) => str.label.includes(routeType));

  return found
    ? {
        streetTypeId: found.id,
        routeType,
      }
    : null;
};

export const getAddresses: Types.GetAddresses = async (
  value,
  countries,
  streets
) => {
  const place_id = _.get(value, "value.place_id");
  const [{ address_components }] = await geocodeByPlaceId(place_id);

  if (!address_components) {
    return INITIAL_VALUES;
  }

  const streetNumber =
    address_components.find((component) =>
      component.types.includes("street_number")
    )?.long_name || "";

  const street = address_components.find((component) =>
    component.types.includes("route")
  );

  const postcode =
    address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name || "";

  const state =
    address_components.find((component) =>
      component.types.includes("administrative_area_level_1")
    )?.long_name || "";

  // const sublocality_level_1 = address_components.find((component) =>
  //   component.types.includes("sublocality_level_1")
  // );

  const country: google.maps.GeocoderAddressComponent | undefined =
    address_components.find((component) => component.types.includes("country"));

  const suburb =
    address_components.find((component) => component.types.includes("locality"))
      ?.long_name || "";

  const countryId =
    countries.find((c) => country?.short_name === c.iso_alpha_2)?.id || null;

  const result = getStreetId(street, streets);

  let streetName = "";

  if (result?.routeType) {
    streetName = result.routeType
      ? street?.long_name.replace(result.routeType, "") || ""
      : "";
  }

  return {
    ...INITIAL_VALUES,
    streetNumber,
    streetTypeId: result?.streetTypeId || null,
    postcode,
    state,
    suburb,
    countryId,
    streetName,
  };
};
