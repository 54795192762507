import { QueryKeys } from "types";
import _ from "lodash";

export const getQueryKey = (
  key: keyof QueryKeys,
  ...ids: Array<string | number | undefined | null | Record<string, any>>
) => {
  return [
    key,
    ...ids.map((item) =>
      _.isObject(item) || _.isArray(item) ? item : item?.toString()
    ),
  ];
};
