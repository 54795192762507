import { ReactNode } from "react";
import { Empty, Skeleton as AntSkeleton } from "components";
import {
  useParams,
  useQueryParams,
  useSelector,
  useQuery,
  useNavigate,
} from "hooks";
import { NavigateFunction } from "react-router-dom";
import { ApiNames, Params } from "types";

const viewHOC =
  <TRecord = any,>({
    Component,
    pathFn,
    queryKeyFn,
    apiName,
    preLoader = <AntSkeleton active loading />,
    onError,
  }: {
    Component: any;
    queryKeyFn: (args: {
      params: Params<"id" | "uuid">;
      queryParams: Record<string, string>;
    }) => Array<string | number | undefined | null | Record<string, any>>;
    apiName: ApiNames;
    pathFn?: (args: {
      params: Params<"id" | "uuid">;
      queryParams: Record<string, string>;
      accountID: number;
    }) => string;
    preLoader?: ReactNode;
    onError?: (args: { navigate: NavigateFunction }) => void;
  }) =>
  (props: any) => {
    const navigate = useNavigate();
    const params = useParams<"id" | "uuid">();
    const accountID = useSelector((root) => root.auth.account?.id as number);
    const [queryParams] = useQueryParams();
    const queryKey = queryKeyFn({
      params,
      queryParams,
    });
    const { data, isLoading } = useQuery<TRecord>({
      apiName,
      queryKey,
      path: pathFn?.({
        params,
        accountID,
        queryParams,
      }),
      options: {
        queryParams,
      },
      onError: () => {
        onError?.({ navigate });
      },
    });

    if (isLoading) {
      return preLoader as any;
    }

    if (!data && !isLoading) {
      return <Empty />;
    }

    return <Component {...props} queryKey={queryKey} record={data} />;
  };

export default viewHOC;
