import { Account } from "types";
import { showItem } from "utils";

export const getSteps = ({
  account,
  appTypeID,
  statusId,
  companyTrustee,
}: {
  account: Account;
  appTypeID: 1 | 2 | 3;
  statusId?: number;
  companyTrustee: boolean;
}) => {
  if (statusId === 2) {
    return ["common.summary"];
  }

  const knowledge = showItem({
    item: ["knowledge.title"],
    defaultValue: ["accountTransactions.title"],
    flag: account.typeId === 1 && account.applicationSettings.knowledge,
  });
  const compliance = showItem({
    item: ["common.compliance"],
    flag: account.applicationSettings.compliance,
  });
  const edd = showItem({
    item: ["enhancedDueDiligence.title"],
    flag: account.applicationSettings.edd,
  });
  const taxation = showItem({
    item: ["taxation.title"],
    flag: account.applicationSettings.taxation,
  });
  const financial = showItem({
    item: ["financial.title"],
    flag: account.applicationSettings.financial,
  });

  let steps = [
    "applications.individual.identification",
    "applications.individual.personalDetails",
    "common.address",
    ...financial,
    ...taxation,
    ...knowledge,
    ...compliance,
    ...edd,
    ...showItem({
      item: ["common.optional"],
      flag: !!account.optionalAccountID,
    }),
    "common.confirm",
  ];

  if (appTypeID === 2) {
    steps = [
      "applications.individual.identification",
      "common.company",
      "appHolders.officeholders",
      "appHolders.shareholders",
      ...financial,
      ...taxation,
      ...knowledge,
      ...compliance,
      ...edd,
      "common.confirm",
    ];
  }

  if (appTypeID === 3) {
    steps = [
      "applications.individual.identification",
      "common.trust",
      ...(companyTrustee
        ? ["appHolders.officeholders", "appHolders.shareholders"]
        : []),
      "appHolders.beneficiaries",
      ...financial,
      ...taxation,
      ...knowledge,
      ...compliance,
      ...edd,
      "common.confirm",
    ];
  }

  return steps;
};
