import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "configs";
import App from "./App";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";

import "./index.css";
import "react-phone-input-2/lib/style.css";

dayjs.extend(utc);
dayjs.extend(isBetween);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
