import { useEffect, useState, useMemo } from "react";
import { createUseStyles, getAccountSlug } from "utils";
import { Layout, Route, Routes, Navigate, Login } from "components";
import {
  useSelector,
  useDispatch,
  useLocation,
  useAccountSubscription,
  useWatchCountries,
} from "hooks";
import { authAsyncActions, routes } from "configs";
import { ROOT_ID } from "consts";
import * as LC from "./components";
import { hooks, selectors, operations } from "./duck";

const useStyles = createUseStyles({
  root: {
    background: "#fff",
    height: "100%",
    overflow: "hidden",
    "& .ant-layout .ant-layout-sider": {
      background: "#fff",
    },
  },
  contentWrapper: {
    minHeight: 360,
    background: "#fff",
    position: "relative",
    overflowX: "hidden",
  },
  sider: {
    "& .ant-layout-sider-trigger": {
      position: "static",
      borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
    },
  },
  content: {
    background: "#fff",
    overflowY: "auto",
  },
});

const localStorageKey = "collapsedMenuEAFP";

const AppLayout = () => {
  useWatchCountries();

  const classes = useStyles();
  const {
    authenticated,
    account,
    appVersion,
    mobileLayout,
    accountSlugs,
    clientAccountID,
  } = useSelector((state) => ({
    authenticated: !!state.auth.currentClientRecord,
    account: state.auth.account,
    clientAccountID: state.auth.currentClientRecord?.account_id,
    appVersion: state.appState.appVersion,
    mobileLayout: state.appState.breakpoint.mobile,
    accountSlugs: state.appState.accountSlugs,
  }));
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const updateAccount = [
      routes.dashboard,
      routes.applications,
      routes.userProfile,
      routes.verifyExternal.list(),
    ].includes(pathname);

    if (updateAccount && clientAccountID && account?.id !== clientAccountID) {
      dispatch(authAsyncActions.getAccountById(clientAccountID));
    }
  }, [pathname, clientAccountID, account?.id, dispatch]);

  hooks.useSubscribeApplicationMessages();

  const slug = getAccountSlug(accountSlugs);
  const accountSlug = account?.slug;
  const [siderOpen, toggleSiderOpen] = useState(
    !!localStorage.getItem(localStorageKey)
  );

  useEffect(() => {
    if (slug && slug !== accountSlug) {
      dispatch(authAsyncActions.getAccountBySlug(slug));
    }
  }, [dispatch, slug, accountSlug, pathname]);

  const redirect = operations.queryString("redirect");

  const { subscriptionActive, subscriptionLoading, subscription } =
    useAccountSubscription();

  const { openRoutes, securedRoutes } = useMemo(
    () => ({
      openRoutes: selectors.getOpenRoutes({
        authenticated,
        accountSlugs,
      }),
      securedRoutes: selectors.getSecuredRoutes({
        accountSlugs,
      }),
    }),
    [authenticated, accountSlugs]
  );

  return (
    <Layout className={classes.root}>
      {account && (
        <LC.AccountInfoMessage
          subscriptionActive={subscriptionActive}
          subscriptionLoading={subscriptionLoading}
          subscription={subscription}
          accountStatusID={account?.statusID}
        />
      )}
      <LC.Header />
      <Layout.Content className={classes.contentWrapper} id={ROOT_ID}>
        <Layout className={classes.root}>
          {authenticated && !mobileLayout && (
            <Layout.Sider
              collapsible
              collapsed={siderOpen}
              className={classes.sider}
              theme="light"
              onCollapse={(collapsed) => {
                if (collapsed) {
                  localStorage.setItem(localStorageKey, "true");
                } else {
                  localStorage.removeItem(localStorageKey);
                }

                toggleSiderOpen(collapsed);
              }}
            >
              <LC.Menu />
            </Layout.Sider>
          )}
          <Layout.Content className={classes.content}>
            <Routes>
              <Route
                path={routes.login}
                element={
                  !authenticated ? (
                    <Login />
                  ) : (
                    <Navigate to={redirect || routes.dashboard} replace />
                  )
                }
              />
              <Route
                path={routes.createApplication}
                element={
                  !authenticated ? (
                    <LC.CreateApp subscriptionActive={subscriptionActive} />
                  ) : (
                    <Navigate to={redirect || routes.dashboard} replace />
                  )
                }
              />
              {openRoutes.map(({ path, Component }) => {
                if (Array.isArray(path)) {
                  return (
                    <Route>
                      {path.map((item) => (
                        <Route key={item} path={item} element={<Component />} />
                      ))}
                    </Route>
                  );
                }

                return <Route path={path} element={<Component />} key={path} />;
              })}
              {securedRoutes.map(({ path, Component }) => {
                const route = slug ? routes.createApplication : routes.login;

                const element = authenticated ? (
                  <Component />
                ) : (
                  <Navigate
                    to={`${route}?redirect=${pathname}${search}`}
                    replace
                  />
                );

                if (Array.isArray(path)) {
                  return (
                    <Route>
                      {path.map((item) => (
                        <Route key={item} path={item} element={element} />
                      ))}
                    </Route>
                  );
                }

                return <Route key={path} path={path} element={element} />;
              })}
              <Route
                path="/"
                element={
                  <Navigate
                    to={authenticated ? routes.dashboard : routes.login}
                  />
                }
              />
              <Route path="*" element={<LC.CatchAll />} />
            </Routes>
          </Layout.Content>
        </Layout>
      </Layout.Content>
      <LC.Footer appVersion={appVersion} />
    </Layout>
  );
};

export default AppLayout;
