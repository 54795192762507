import { useEffect } from "react";
import {
  ReconnectingWebSocket,
  getIntl,
  transformAppID,
  getQueryKey,
} from "utils";
import { SOCKET_URL, STAGE } from "consts";
import { useSelector, useQuery } from "hooks";
import { routes } from "configs";
import { ApplicationsList } from "types";
import { notification, Button, ArrowRightOutlined } from "components";
import qs from "query-string";

export const useSubscribeApplicationMessages = () => {
  const { language, accountID, authenticated } = useSelector((state) => ({
    accountID: state.auth.account?.id,
    authenticated: !!state.auth.currentClientRecord,
    language: state.appState.language,
  }));
  const { data = [] } = useQuery<ApplicationsList[]>({
    apiName: "applications",
    enabled: !!accountID && authenticated,
    queryKey: getQueryKey("applicationsList", accountID),
  });

  useEffect(() => {
    if (!data.length) {
      return;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const ws = new ReconnectingWebSocket(SOCKET_URL, null, {
      debug: STAGE !== "prod",
      reconnectInterval: 2000,
      reconnectAttempts: 10,
    });

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          action: "subscribe",
        })
      );
    };
    ws.onmessage = (event) => {
      // {applicationID, createdBy: EAFP | EAFA
      const message = JSON.parse(event.data);
      const application = data.find((app) => app.id === message.applicationID);
      const appID = qs.parse(window.location.search)?.appId as
        | string
        | undefined;

      if (
        message.createdBy === "EAFA" &&
        application &&
        application.statusId === 2 &&
        application.id !== transformAppID(appID)
      ) {
        const intl = getIntl(language);

        notification.info({
          message: (
            <Button
              type="link"
              target="_blank"
              href={`${window.location.origin}${routes.openApplication({
                ...application,
                tab: "messaging",
              })}`}
            >
              {intl.formatMessage({ id: "applications.newMessage" })}
              <ArrowRightOutlined />
            </Button>
          ),
        });
      }
    };

    return () => ws.close();
  }, [data, language]);
};
