import { SelectProps } from "antd";
import { IntlShape } from "react-intl";
import { SelectOption } from "types";
import _ from "lodash";

export const getPlaceholder = (
  placeholder: SelectProps["placeholder"],
  intl: IntlShape
) => {
  return placeholder && typeof placeholder === "string"
    ? intl.formatMessage({ id: placeholder, defaultMessage: "" })
    : placeholder;
};

export const filterOption = (input: string, option?: SelectOption) => {
  if (_.isString(option?.children)) {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }

  return option?.children === input;
};
