import * as React from "react";
import {
  FormattedMessage,
  Form,
  InputText,
  InputEmail,
  StepsButtons,
  Row,
  Col,
  Typography,
  CountriesSelect,
  InputDatePicker,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { useForm } from "hooks";
import { Types } from "../../duck";
import { Language } from "types";

const UserDetailsForm: React.FC<Types.CommonProps & { language: Language }> = ({
  record,
  onNextClick,
  steps,
  currentStep,
  loading,
  language,
  setLogs,
}) => {
  const [form] = useForm();

  return (
    <>
      <Typography.Text
        style={{
          marginBottom: 24,
          display: "inline-block",
        }}
      >
        <FormattedMessage
          id="informationRequests.description"
          values={{
            fullName: `${record.firstName} ${record.lastName}`,
            account: record.account,
            email: record.customer_service_email,
          }}
        />
      </Typography.Text>
      <Form
        form={form}
        layout="vertical"
        name="userDetailsForm"
        onFinish={async (values) => {
          await setLogs(values);

          if (!form.isFieldsTouched()) {
            onNextClick();
          } else {
            onNextClick(values);
          }
        }}
        initialValues={record}
      >
        {language === "zh" ? (
          <Row gutter={ROW_GUTTER}>
            <Col {...COMMON_COL_PROPS}>
              <InputText required label="common.first_name" name="firstName" />
              <InputText required label="common.last_name" name="lastName" />
            </Col>
            <Col {...COMMON_COL_PROPS}>
              <InputText
                required
                label="common.first_name_en"
                name="firstNameEN"
              />
              <InputText
                required
                label="common.last_name_en"
                name="lastNameEN"
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={ROW_GUTTER}>
            <Col {...COMMON_COL_PROPS}>
              <InputText required label="common.first_name" name="firstName" />
            </Col>
            <Col {...COMMON_COL_PROPS}>
              <InputText required label="common.last_name" name="lastName" />
            </Col>
          </Row>
        )}
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <InputEmail required label="common.emailAddress" name="email" />
          </Col>
          <Col span={12}>
            <InputDatePicker
              required
              isAfterToday
              label="common.dateOfBirth"
              name="dateOfBirth"
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <InputText
              required
              label="common.placeOfBirthCity"
              name="placeOfBirthCity"
            />
          </Col>
          <Col span={12}>
            <CountriesSelect
              required
              restricted
              label="common.placeOfBirthCountry"
              name="placeOfBirthCountryId"
            />
          </Col>
        </Row>
        <StepsButtons
          loading={loading}
          currentStep={currentStep}
          steps={steps}
        />
      </Form>
    </>
  );
};
export default UserDetailsForm;
