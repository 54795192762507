import { useLocation, useDispatch, useSelector } from "hooks";
import { authAsyncActions } from "configs";
import {
  LoginOutlined,
  Button,
  Popover,
  Form,
  FormattedMessage,
  InputEmail,
  InputPassword,
} from "components";

const LoginPopup = () => {
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const visible = pathname.slice(1).split("/").includes("open-demo-account");

  if (!visible) {
    return null;
  }

  return (
    <Popover
      content={
        <Form
          layout="vertical"
          onFinish={({
            email,
            password,
          }: {
            email: string;
            password: string;
          }) => {
            dispatch(
              authAsyncActions.loginStart({
                email,
                password,
              })
            );
          }}
        >
          <InputEmail required label="common.emailAddress" autoComplete="off" />
          <InputPassword required label="common.password" name="password" />
          <Button htmlType="submit" loading={loading} type="primary">
            <FormattedMessage id="button.login" />
          </Button>
        </Form>
      }
    >
      <Button icon={<LoginOutlined />} />
    </Popover>
  );
};

export default LoginPopup;
