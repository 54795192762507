import { FC, useEffect } from "react";
import { useMutation, useQuery } from "hooks";
import { Skeleton, Form, AccountTransactionsMarkup } from "components";
import { CommonProps } from "../../types";
import { getQueryKey } from "utils";
import { AccountTransaction } from "types";

interface GatMoneyProps extends CommonProps {
  optionalAccountID: number;
}

const GatMoney: FC<GatMoneyProps> = ({
  addAccount,
  onSubmit,
  application: { id: appID, optionalID },
  optionalAccountID,
  children,
}) => {
  const [form] = Form.useForm();
  const queryKey = getQueryKey("applicationOptional", appID, optionalID);

  const { data, isLoading } = useQuery<AccountTransaction>({
    apiName: "applications",
    path: `/${appID}/optional/${optionalID}/account-transactions`,
    enabled: !!optionalID,
    queryKey,
  });

  const { mutate, isPending, queryClient } = useMutation<AccountTransaction>({
    apiName: "applications",
    ...(data
      ? {
          method: "patch",
          path: `/${appID}/optional/${optionalID}/account-transactions`,
        }
      : {
          method: "post",
          path: `/${appID}/optional/account-transactions`,
        }),
    onSuccess: (newData) => {
      const queryKeyWithCreatedID = getQueryKey(
        "applicationOptional",
        appID,
        newData.optionalID
      );

      // set data into cache to prevent fetching it from useQuery (check "enabled" above)
      queryClient.setQueryData(queryKeyWithCreatedID, newData);

      onSubmit(newData.optionalID);
    },
  });

  useEffect(() => {
    form.validateFields();
  }, [addAccount, form]);

  if (isLoading) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      form={form}
      disabled={!addAccount}
      preserve={false}
      layout="vertical"
      onFinish={async (values) => {
        if (!form.isFieldsTouched()) {
          onSubmit();
        } else if (!addAccount) {
          queryClient.setQueryData(queryKey, null);
        } else {
          mutate(values);
        }
      }}
      initialValues={
        data || {
          sourceOfFunds: [],
          sourceOfWealth: [],
          purposeOfAccount: [],
        }
      }
    >
      <AccountTransactionsMarkup
        accountID={optionalAccountID}
        required={addAccount}
      />
      {children({ loading: isPending })}
    </Form>
  );
};

export default GatMoney;
