import { FC } from "react";
import { Card, CardProps } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "utils";

interface ExtendedCardProps extends Omit<CardProps, "title"> {
  title: string;
}

const useStyles = createUseStyles({
  padding: {
    "& .ant-card-head, & .ant-card-body": {
      padding: "0 24px",
      // antd md
      "@media (max-width: 768px)": {
        padding: "0 12px",
      },
      // antd sm
      "@media (max-width: 575px)": {
        padding: 0,
      },
    },
  },
});

const ExtendedCard: FC<ExtendedCardProps> = ({ title, ...props }) => {
  const classes = useStyles();

  return (
    <Card
      {...props}
      className={classes.padding}
      title={<FormattedMessage id={title} />}
    />
  );
};

export default ExtendedCard;
