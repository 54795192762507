import { FC, useEffect } from "react";
import {
  Verification,
  Taxation,
  AccountTransactions,
  Edd,
  Compliance,
  Financial,
} from "components";
import * as LC from "./components";
import * as SC from "../shared";
import { useSelector } from "hooks";
import { hocs, Types } from "../../duck";
import { Account, TrustApplication, User } from "types";
import { showItem } from "utils";

const TrustApp: FC<Types.ChildAppWrapperProps<TrustApplication>> = ({
  app,
  appQueryKey,
  steps,
  currentStep,
  onNextClick,
  onPrevClick,
  companyTrustee,
  setCompanyTrustee,
}) => {
  const { streetTypes, countries, account, currentClientRecord } = useSelector(
    (state) => ({
      streetTypes: state.appState.streetTypes,
      countries: state.appState.countries,
      account: state.auth.account as Account,
      currentClientRecord: state.auth.currentClientRecord as User,
    })
  );

  useEffect(() => {
    setCompanyTrustee(app.trusteeTypeId);
    // eslint-disable-next-line
  }, []);

  const COMMON_PROPS = {
    onPrevClick,
    onNextClick,
    steps,
    currentStep,
    appQueryKey,
  };

  const holdersProps = {
    appId: app.id,
    accountName: account.file_by,
    countries,
    streetTypes,
    ...COMMON_PROPS,
  };

  const stepComponents = [
    <Verification
      {...COMMON_PROPS}
      parentRoute="applications"
      parentID={app.id}
      first_name={currentClientRecord.first_name}
      last_name={currentClientRecord.last_name}
      showDescription="trust"
    />,
    <LC.Trust
      {...COMMON_PROPS}
      app={app}
      streetTypes={streetTypes}
      countries={countries}
      setCompanyTrustee={setCompanyTrustee}
    />,
    ...(companyTrustee
      ? [
          <SC.AppHolders kind="officeholders" {...holdersProps} />,
          <SC.AppHolders kind="shareholders" {...holdersProps} />,
        ]
      : []),
    <SC.AppHolders kind="beneficiaries" {...holdersProps} />,
    ...showItem({
      flag: account.applicationSettings.financial,
      item: [
        <Financial
          {...COMMON_PROPS}
          accountName={account.account_name}
          accountTypeID={account.typeId}
          parentID={app.id}
          parentRoute="applications"
          onNextClick={onNextClick}
          companyOrTrust
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.taxation,
      item: [
        <Taxation
          parentRoute="applications"
          parentID={app.id}
          {...COMMON_PROPS}
        />,
      ],
    }),
    ...showItem({
      item: [<SC.Knowledge {...COMMON_PROPS} app={app} />],
      flag: account.applicationSettings.knowledge,
      defaultValue: [
        <AccountTransactions
          {...COMMON_PROPS}
          accountID={account.id}
          parentID={app.id}
          parentRoute="applications"
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.compliance,
      item: [
        <Compliance
          {...COMMON_PROPS}
          parentID={app.id}
          parentRoute="applications"
          onNextClick={() => onNextClick()}
        />,
      ],
    }),
    ...showItem({
      flag: account.applicationSettings.edd,
      item: [
        <Edd {...COMMON_PROPS} parentID={app.id} parentRoute="applications" />,
      ],
    }),
    <SC.Confirm
      {...COMMON_PROPS}
      account={account}
      currentClientRecord={currentClientRecord}
      app={app}
      columns={[
        {
          label: "applications.trust.name",
          value: app.trustName,
        },
        ...(app.trusteeTypeId === 1
          ? [
              {
                label: "applications.trust.trusteeFirstName",
                value: app.trusteeFirstName,
              },
              {
                label: "applications.trust.trusteeLastName",
                value: app.trusteeLastName,
              },
            ]
          : [
              {
                label: "applications.trust.trusteeCompanyName",
                value: app.trusteeCompanyName,
              },
              {
                label: "applications.trust.trusteeCompanyNumber",
                value: app.trusteeCompanyNumber,
              },
            ]),
      ]}
    />,
  ];

  return stepComponents[currentStep];
};

export default hocs.wrapper<TrustApplication>(TrustApp, 3);
