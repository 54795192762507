import { useQuery } from "@tanstack/react-query";
import * as API from "aws-amplify/api";
import { operations, Types } from "./duck";
import { STAGE } from "../../consts";

const useQueryRequest = <TData>({
  apiName,
  path = "",
  queryKey,
  enabled,
  options,
  onError,
}: Types.QueryArgs) => {
  return useQuery({
    queryKey,
    enabled,
    retry: STAGE !== "uat" && STAGE !== "prod" ? 2 : 0,
    queryFn: async ({ signal }) => {
      const operation = API.get({
        apiName,
        path,
        options,
      });

      signal.addEventListener("abort", () => {
        operation.cancel();
      });

      return operations.queryFnWrapper<TData>(operation).catch(() => {
        onError?.();

        return Promise.reject();
      });
    },
  });
};

export default useQueryRequest;
