import { Form } from "antd";

export const { useForm } = Form;

export * from "./useReactRedux";
export * from "./tanstack";
export { default as useChildAPI } from "./use-child-api";
export { default as useGetAccountSlug } from "./useGetAccountSlug";

export { default as useQueryParams } from "./use-query-params";

export { default as usePrevious } from "./usePrevious";
export { default as useAccountSubscription } from "./useAccountSubscription";

export {
  useLocation,
  useParams,
  useNavigate,
  useMatch,
  useMatches,
} from "react-router-dom";

export { useDropzone } from "react-dropzone";

export { useIntl } from "react-intl";

export { default as useAgreements } from "./useAgreements";
export { default as useWatchCountries } from "./useWatchCountries";
export { default as useNotification } from "./useNotification";
