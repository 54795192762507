import { FC } from "react";
import dayjs from "dayjs";
import { Layout, Typography } from "components";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  footer: {
    textAlign: "center",
    fontSize: 14,
    "@media (max-width: 768px)": {
      padding: 24,
    },
    "@media (max-width: 425px)": {
      padding: 8,
      fontSize: 12,
    },
  },
});

const Footer: FC<{ appVersion: string | null }> = ({ appVersion }) => {
  const classes = useStyles();

  return (
    <Layout.Footer className={classes.footer}>
      <strong>eApplication Form</strong>
      &nbsp;
      <span>©{dayjs().format("YYYY")}</span>
      {appVersion && (
        <strong>
          &nbsp;<small>- Version {appVersion}</small>
        </strong>
      )}
      <br />
      <small>Created by</small>
      &nbsp;
      <Typography.Link href="https://deliostech.com" target="_blank">
        Delios Technology Inc
      </Typography.Link>
      &nbsp; | &nbsp;
      <Typography.Link
        href="https://eapplicationform-legal.s3.amazonaws.com/PrivacyPolicy.pdf"
        target="_blank"
      >
        Privacy Policy
      </Typography.Link>
      &nbsp; | &nbsp;
      <Typography.Link
        href="https://eapplicationform-legal.s3.amazonaws.com/TermsAndConditions.pdf"
        target="_blank"
      >
        Terms &amp; Conditions
      </Typography.Link>
    </Layout.Footer>
  );
};

export default Footer;
