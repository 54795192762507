import { FC } from "react";
import {
  Form,
  Row,
  Col,
  Divider,
  POA,
  Skeleton,
  StreetAddress,
  StepsButtons,
} from "components";
import { useChildAPI, useForm } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import * as Types from "types";

export interface AddressProps
  extends Types.StepButtonsProps<Types.IndividualApplication> {
  countries: Types.Country[];
  streetTypes: Types.TranslatedRecord[];
  accountName: string;
  app: Types.IndividualApplication;
}

const Address: FC<AddressProps> = ({
  countries,
  accountName,
  streetTypes,
  app,
  onNextClick,
  loading,
  ...btnProps
}) => {
  const [form] = useForm();

  const {
    data: streetAddress,
    fetching: fetchingStreetAddress,
    saving: savingStreetAddress,
    save: saveStreetAddress,
  } = useChildAPI<Types.StreetAddress>({
    parentID: app.id,
    parentRoute: "applications",
    childRoute: "street-address",
  });

  if (fetchingStreetAddress) {
    return <Skeleton active loading />;
  }

  return (
    <>
      <Divider />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Form
            form={form}
            layout="vertical"
            initialValues={streetAddress || {}}
            onFinish={async (values) => {
              if (form.isFieldsTouched()) {
                await saveStreetAddress(values);
              }

              onNextClick?.();
            }}
          >
            <StreetAddress countries={countries} streetTypes={streetTypes} />
          </Form>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <POA
            parentID={app.id}
            parentRoute="applications"
            accountName={accountName}
          />
        </Col>
      </Row>
      <StepsButtons
        {...btnProps}
        loading={savingStreetAddress || loading}
        onNextClick={() => form.submit()}
      />
    </>
  );
};

export default Address;
