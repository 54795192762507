import { Types } from "../../../duck";

export const getTranslations = (kind: Types.Kind) => {
  let text = "appHolders.officeholder";

  if (kind === "shareholders") {
    text = "appHolders.shareholder";
  }

  if (kind === "beneficiaries") {
    text = "appHolders.beneficiary";
  }

  return text;
};
