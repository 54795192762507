import { FC } from "react";
import { useDeleteMutation } from "hooks";
import { Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import { QueryKey } from "types";
import { createUseStyles } from "react-jss";

interface ActionsColumnProps {
  appID: number;
  disabled: boolean;
  queryKey: QueryKey;
}

const useStyles = createUseStyles<any, { disabled: boolean }>({
  iconColor: (props) => ({
    color: props.disabled ? "grey" : "red",
  }),
});

const ActionsColumn: FC<ActionsColumnProps> = ({
  appID,
  queryKey,
  disabled,
}) => {
  const classes = useStyles({ disabled });
  const { mutateAsync: deleteApp, isPending } = useDeleteMutation({
    apiName: "applications",
    path: `/${appID}`,
    invalidateQueries: [queryKey],
  });

  return (
    <Popconfirm
      disabled={disabled}
      title={<FormattedMessage id="confirmations.areYouSure" />}
      okText={<FormattedMessage id="button.ok" />}
      cancelText={<FormattedMessage id="button.cancel" />}
      okButtonProps={{ loading: isPending }}
      onConfirm={async () => {
        // wait until promise resolves so the popup won't be closed immediately
        await deleteApp();
      }}
    >
      <DeleteOutlined className={classes.iconColor} />
    </Popconfirm>
  );
};

export default ActionsColumn;
