import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Types from "types";
import { showNotification, API } from "utils";
import { notification } from "antd";
import {
  Country,
  NotRestrictedCountry,
  TranslatedRecords,
  AccountSlug,
} from "types";

export const getCountries = createAsyncThunk<
  {
    countries: Country[];
    notRestrictedCountries: NotRestrictedCountry[];
  },
  { accountId: number }
>("GET_COUNTRIES", ({ accountId }, { getState }) => {
  return Promise.all([
    API.get({
      apiName: "countries",
      options: {
        queryParams: { restrictedCountry: true, accountId },
      },
    }),
    API.get({
      apiName: "countries",
      options: {
        queryParams: { restrictedCountry: false, accountId },
      },
    }),
  ])
    .then(
      ([countries, notRestrictedCountries]: [
        Country[],
        NotRestrictedCountry[]
      ]) => {
        return {
          countries,
          notRestrictedCountries,
        };
      }
    )
    .catch(() => {
      const state = getState() as any;

      showNotification({
        language: state.appState.language,
        type: "error",
        message: "messages.failedFetchCountries",
      });

      return Promise.reject();
    });
});

export const getAccountSlugs = createAsyncThunk<AccountSlug[]>(
  "appState/accountSlugs",
  () => {
    return API.get({
      apiName: "accounts",
      path: "/slugs",
    }).catch((e) => {
      notification.error({
        message: e.message,
        description: "Please reload the page",
      });
      return Promise.reject();
    });
  }
);

export const getTranslatedData = createAsyncThunk<
  { translatedData: Types.TranslatedAction[] } & Omit<Types.System, "id">,
  { language: Types.Language }
>("GET_TRANSLATED_DATA", ({ language }) => {
  return API.get({
    apiName: "system",
    options: {
      headers: {
        "app-language": language,
      },
    },
  })
    .then((resp) => {
      const keys: Array<keyof TranslatedRecords> = [
        "genders",
        "sicDivisions",
        "streetTypes",
        "expectedTransactionsCount",
        "natureTransactions",
        "poaCategories",
        "employmentStatuses",
        "noTinReasons",
      ];

      return {
        appVersion: resp.appVersion,
        sumSubActive: resp.sumSubActive,
        translatedData: keys.map((str) => ({
          meta: str,
          data: resp[str],
        })),
      };
    })
    .catch(() => {
      showNotification({
        language,
        type: "error",
        message: "messages.systemDataFetchFailed",
      });

      return Promise.reject();
    });
});

export const updateLanguage = createAsyncThunk<
  Types.Language,
  { language: Types.Language; clientID?: number }
>("UPDATE_LANGUAGE", ({ language, clientID }) => {
  if (!clientID) {
    return Promise.resolve(language);
  }

  return API.patch({
    apiName: "clients",
    path: `/${clientID}`,
    options: {
      body: {
        preferredLanguage: language,
      },
    },
  })
    .then((client) => client.preferredLanguage)
    .catch(() => {
      showNotification({
        language,
        type: "error",
        message: "messages.requestFailed",
      });
    });
});

export const getUserGeo = createAsyncThunk<Types.UserGeo>(
  "GET_USER_GEO",
  () => {
    const apiKey = "ef6c41a0-9d3c-11eb-8f3b-e1f5536499e7";

    return fetch(`https://geolocation-db.com/json/${apiKey}`, { mode: "cors" })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((res) => {
        if (res.country_code) {
          return res;
        }
      })
      .catch(() => {
        // do nothing
      });
  }
);
