import * as React from "react";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";
import PhoneInput from "../phone-input";

const PhoneWidget: React.FC = () => {
  // TODO check
  // polymorph connections

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="phone.title" />
      </Typography.Title>
      <PhoneInput label="phone.home" name="home" />
      <PhoneInput label="phone.work" name="work" />
      <PhoneInput label="phone.mobile" name="mobile" required />
    </>
  );
};

export default PhoneWidget;
