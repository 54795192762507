import { Alert, FormattedMessage } from "components";

const AlertInProgress = () => {
  return (
    <Alert
      type="success"
      showIcon
      message="Registration In Progress"
      description={
        <FormattedMessage
          id="messages.account.demo.inProgress"
          values={{
            br: <br />,
          }}
        />
      }
    />
  );
};

export default AlertInProgress;
